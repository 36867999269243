import { HelpersFactoryService } from './../../services/helpers-factory.service';
import { GeoLocationService } from './../../services/geo-location.service';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Messages } from 'src/app/constants/messages';
import { UserTypeEnum } from 'src/app/enums/user/userTypeEnum';
import { GeoLocation } from 'src/app/models/entities/geoLocation';
import { UserLoginLog } from 'src/app/models/entities/userLoginLog';
import { UserRegisterDto } from 'src/app/models/userRegisterDto';
import { AuthService } from 'src/app/services/auth.service';
import { UserLoginLogService } from 'src/app/services/user-login-log.service';
import { PageTitle } from 'src/app/constants/pageTitle';

enum TabEnums{
  LoginStart = "login-start",
  RegisterStart = "register-start",
  Login = "login",
  Register = "register"
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  tabEnums = TabEnums;
  currentTab: TabEnums = TabEnums.LoginStart;
  mainTab: TabEnums = TabEnums.Register;

  loginForm: FormGroup;
  registerForm : FormGroup;

  passwordVisibilityType: string = "password";
  
  loginButtonAction: boolean;
  registerButtonAction: boolean;

  constructor(public formBuilder: FormBuilder,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private helpersFactoryService: HelpersFactoryService,
    private geoLocationService: GeoLocationService,
    private userLoginLogService: UserLoginLogService) {}
    

  ngOnInit(): void {
    this.helpersFactoryService.pageTitleService.setTitleWithCanonical(PageTitle.layouts.emptyLayout.login);
    this.checIfUserIsAlreadyAuthenticated();
  }

  login(){
    if(this.loginForm.valid){
      this.loginButtonAction = true;
      let model = Object.assign({}, this.loginForm.value);
      this.authService.userLogin(model).subscribe({
        next: (response => {
          this.loginButtonAction = false;
          this.helpersFactoryService.toastService.success(response.message);
          this.saveClientGeoLocation(response.data.userBasicInformation.id);
          this.helpersFactoryService.localStorageService.setAuthenticatedUserInformations(response.data);
          this.helpersFactoryService.navigateToLastRoute();
        }),
        error: (error => {
          this.loginButtonAction = false;
          this.helpersFactoryService.toastService.error(error.error.message);
        })
      });
    }
  }

  googleAuth(socialUser: SocialUser){
    if(socialUser != null){
      this.helpersFactoryService.spinnerService.show();
      this.authService.loginWithGoogle(socialUser).subscribe({
        next: (response => {
          this.helpersFactoryService.spinnerService.hide();
          this.helpersFactoryService.toastService.success(response.message);
          this.saveClientGeoLocation(response.data.userBasicInformation.id);
          this.helpersFactoryService.localStorageService.setAuthenticatedUserInformations(response.data);
          this.helpersFactoryService.navigateToLastRoute();
        }),
        error: (error => {
          this.helpersFactoryService.spinnerService.hide();
          this.helpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
        })
      });
    }
  }

  googleAuthObserver(){
    this.socialAuthService.authState.subscribe((socialUser: SocialUser) => {
      this.googleAuth(socialUser);
    });
  }

  register(){
    if(this.registerForm.valid){
      let registerModel = new UserRegisterDto();
      let model = Object.assign(registerModel, this.registerForm.value);
      if(model["password"] !== model["passwordConfirm"]){
        this.helpersFactoryService.toastService.error("Parolalar uyuşmuyor");
      }
      else{
        this.registerButtonAction = true;
        this.authService.register(registerModel).subscribe({
          next: (response => {
            this.registerButtonAction = false;
            this.helpersFactoryService.toastService.success("Başarılı bir şekilde üye oldunuz");
            this.registerForm.reset();
            this.currentTab = TabEnums.Login;
            this.mainTab = TabEnums.Login;
          }),
          error: (error => {
            this.registerButtonAction = false;
            this.helpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
          })
        });
      }
    }
  }

  checIfUserIsAlreadyAuthenticated(){
    if(this.authService.userIsAuthenticated()){
      this.helpersFactoryService.navigateToLastRoute();
    }
    else{
      this.createLoginForm();
      this.createRegisterForm();
      this.googleAuthObserver();
    }
  }
  
  saveClientGeoLocation(userId: any){
    this.geoLocationService.getClientGeoLocation().subscribe({
      next: (response => {
        let geoLocation = new GeoLocation(0, response.country_name, response.country_code, response.city, String(response.latitude).toString(), String(response.longitude).toString(), new Date());
        this.geoLocationService.add(geoLocation).subscribe({
          next: (resultResponse => {
              let userLoginLog = new UserLoginLog(0, userId, resultResponse.data.id, new Date());
              this.userLoginLogService.add(userLoginLog).subscribe({});
          })
        });
      })
    });
  }

  setCurrentTab(tab: TabEnums){
    this.currentTab = tab;
    if(tab.includes('login')){
      this.mainTab = TabEnums.Login;
    }
    else{
      this.mainTab = TabEnums.Register;
    }
  }

  createLoginForm(){
    this.loginForm = this.formBuilder.group({
      userTypeId: [UserTypeEnum.Customer, Validators.required],
      email: ["", [Validators.email, Validators.required]],
      password: ["", Validators.required]
    });
  }

  createRegisterForm(){
    this.registerForm = this.formBuilder.group({
      userTypeId: [UserTypeEnum.Customer, Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.email, Validators.required]],
      password: ["", Validators.required],
      passwordConfirm: ["", Validators.required]
    });
  }

}
