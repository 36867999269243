<div class="dashboard-login-main">
    <div class="background-side">
        <app-ng-image class="background-image" src="../../../assets/backgrounds/dashboard-login.png"></app-ng-image>
    </div>
    <div class="login-side">
        <div class="logo-container">
            <app-ng-image class="logo" src="../../../assets/logos/logo-new-colored.svg"></app-ng-image>
        </div>
        <div class="w-100">
            <router-outlet></router-outlet>
        </div>
        <div class="vector-character m-t-50">
            <app-ng-image src="../../../../assets/vectorCharacters/dashboard-login-vectors-character.svg"></app-ng-image>
        </div>
    </div>
</div>