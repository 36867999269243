import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerModuleService {

  constructor(private ngxSpinnerService: NgxSpinnerService) { }

  show(){
    this.ngxSpinnerService.show();
  }

  hide(){
    this.ngxSpinnerService.hide();
  }
}
