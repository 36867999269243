<div class="dashboard-home">

    <!-- Visitors Map, Visitors Statistics and Recipes Statistics (Start) -->
    <!-- <div class="visitors-map-visitors-recipe-statistics common-group-container">
         <div class="visitors-map common-container flex-1">
            <div class="container-header">
                <h2 class="container-title">DÜNYA'DAKİ ZİYARET SAYILARI</h2>
            </div>
            <div class="container-body">

            </div>
         </div>
         <div class="visitors common-container flex-05">
            <div class="container-header">
                <h2 class="container-title">ZİYARETÇİLER</h2>
            </div>
            <div class="container-body">

            </div>
         </div>
         <div class="recipes common-container flex-05">
            <div class="container-header">
                <h2 class="container-title">YEMEK TARİFLERİ</h2>
            </div>
            <div class="container-body">

            </div>
         </div>
    </div> -->
    <!-- Visitors Map, Visitors Statistics and Recipes Statistics (End) -->

    <!-- FeedBacks and Users Statistics (Start) -->
    <div class="feedbacks-and-users common-group-container">
        <div class="feedbacks common-container flex-03">
            <div class="container-header">
                <h2 class="container-title">GERİ BİLDİRİMLER</h2>
            </div>
            <div class="container-body">
                <div *ngFor="let feedback of feedbacks.items" class="feedback-item">
                    <div class="feedback-header">
                        <div class="feedback-user-avatar">
                            <app-ng-image class="ng-image" src="../../../assets/icons/user-avatar-gray-icon.svg"></app-ng-image>
                        </div>
                        <div class="feedback-user-name-and-date">
                            <p class="m-0 user-name">{{feedback?.name}}</p>
                            <p class="m-0 date">Gönderim Tarihi: {{feedback?.date | date:'dd.MM.yyyy HH:mm'}}</p>
                        </div>
                    </div>
                    <div class="feedback-body scroll-style-one">
                        <p class="feedback-message">"{{feedback?.message}}"</p>
                    </div>
                </div>
            </div>
            <div class="container-bottom">
                <div (click)="feedbacksListPageEvent(DirectionEnum.Down)" class="pb-icon-box">
                    <span>Yukarı çık</span>
                    <app-ng-image class="icon" src="../../../assets/icons/arrow-up-{{feedbacks.hasPrevious ? 'pink' : 'gray'}}-icon.svg"></app-ng-image>
                </div>
                <div (click)="feedbacksListPageEvent(DirectionEnum.Up)" class="pb-icon-box">
                    <app-ng-image class="icon" src="../../../assets/icons/arrow-down-{{feedbacks.hasNext ? 'pink' : 'gray'}}-icon.svg"></app-ng-image>
                    <span>Aşağı in</span>
                </div>
            </div>
         </div>
         <div class="users common-container flex-1">
            <div class="container-header">
                <h2 class="container-title">KULLANICILAR</h2>
            </div>
            <div class="container-body">
                <div class="user-table table-wrapper scroll-style-one">
                    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                        <ng-container matColumnDef="userBasicInformation-fullName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by userBasicInformation-fullName">
                            Kişi
                            </th>
                            <td mat-cell *matCellDef="let element"> 
                                <div class="user-info">
                                    <div class="user-avatar">
                                        <app-ng-image class="ng-image" src="../../../assets/icons/user-avatar-gray-icon.svg"></app-ng-image>
                                    </div>
                                    <div class="user-name-and-login-location">
                                        <p class="m-0 user-name">{{element?.userBasicInformation.fullName}}</p>
                                        <p class="m-0 login-location">Türkiye, İstanbul</p>
                                    </div>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="lastLoginDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by lastLoginDate">
                            Son giriş tarihi
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.lastLoginDate | date: 'dd.MM.yyyy HH:mm'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="totalLoginCount">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by totalLoginCount">
                            Toplam giriş sayısı
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.totalLoginCount}} </td>
                        </ng-container>

                        <ng-container matColumnDef="totalGeneratedRecipeCount">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by totalGeneratedRecipeCount">
                            Toplam ürettiği tarif
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.totalGeneratedRecipeCount}} </td>
                        </ng-container>

                         <ng-container matColumnDef="totalMakedRecipeCount">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by totalMakedRecipeCount">
                            Toplam yaptığı yemek
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.totalMakedRecipeCount}} </td>
                        </ng-container>

                        <tr class="mat-header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
            <div class="container-bottom" *ngIf="userStatisticsDataIsLoaded | async">
                <app-pagination-partial [data]="userStatistics" (paginationOnChange)="getUserStatistics();"></app-pagination-partial>
            </div>
         </div>
    </div>
    <!-- FeedBacks and Users Statistics (End) -->

    <!-- User Login Counts and Most Selections Statistics (Start) -->
    <div class="user-login-counts-and-most-selections common-group-container">
        <div class="users-login-counts common-container flex-1">
            <div class="container-header">
                <h2 class="container-title">KULLANICILARIN GİRİŞ SAYILARI</h2>
            </div>
            <div class="container-body">
                <app-chart-partial class="user-login-counts-chart" [chartOptions]="testDonutChartOption"></app-chart-partial>
            </div>
         </div>
         <div class="most-selections common-container flex-045">
            <div class="container-header">
                <h2 class="container-title">EN ÇOK SEÇİLEN TERCİHLER</h2>
            </div>
            <div class="container-body">
                <div class="ng-progress-bar-box">
                    <input type="range" min="0" max="100" value="10">
                </div>
            </div>
         </div>
    </div>
    <!-- User Login Counts and Most Selections Statistics (End) -->

</div>