import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { UserLoginLog } from '../models/entities/userLoginLog';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/responseModel';
import { ListResponseModel } from '../models/listResponseModel';
import { UserLoginLogDailyLoginCountDto } from '../models/dtos/userLoginLog/userLoginLogDailyLoginCountDto';

@Injectable({
  providedIn: 'root'
})
export class UserLoginLogService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  add(userLoginLog: UserLoginLog): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}UserLoginLogs/Add`;
    return this.httpClient.post<ResponseModel>(newPath, userLoginLog);
  }

  getUserLoginLogDailyLoginCountsDto(): Observable<ListResponseModel<UserLoginLogDailyLoginCountDto>>{
    let newPath = `${this.apiUrl}UserLoginLogs/GetUserLoginLogDailyLoginCountsDto`;
    return this.httpClient.get<ListResponseModel<UserLoginLogDailyLoginCountDto>>(newPath);
  }

}
