import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Subscriber } from '../models/subscriber';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/responseModel';
import { ListResponseModel } from '../models/listResponseModel';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  add(subscriber: Subscriber): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}Subscribers/Add`;
    return this.httpClient.post<ResponseModel>(newPath, subscriber);
  }

  getAll(): Observable<ListResponseModel<Subscriber>>{
    let newPath = `${this.apiUrl}Subscribers/GetAll`;
    return this.httpClient.get<ListResponseModel<Subscriber>>(newPath);
  }
}
