import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-login-layout',
  templateUrl: './dashboard-login-layout.component.html',
  styleUrls: ['./dashboard-login-layout.component.css']
})
export class DashboardLoginLayoutComponent {

}
