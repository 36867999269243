import { RouterName } from './../../../constants/routerName';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelpersFactoryService } from 'src/app/services/helpers-factory.service';
import { PasswordResetLinkService } from 'src/app/services/password-reset-link.service';

@Component({
  selector: 'app-dashboard-password-reset-request',
  templateUrl: './dashboard-password-reset-request.component.html',
  styleUrls: ['./dashboard-password-reset-request.component.css']
})
export class DashboardPasswordResetRequestComponent {

  passwordResetRequestForm: FormGroup;
  RouterName = RouterName;
  passwordResetLinkIsSended: boolean;

  constructor(private formBuilder: FormBuilder,
    private passwordResetLinkService: PasswordResetLinkService,
    private helpersService: HelpersFactoryService){
      console.log(this.passwordResetLinkIsSended);
  }

  ngOnInit(): void {
    this.createLoginForm();
  }

  sendPasswordResetLink(){
    if(this.passwordResetRequestForm.valid){
      this.helpersService.spinnerService.show();
      let model = Object.assign({}, this.passwordResetRequestForm.value);
      let email = model["email"];
      this.passwordResetLinkService.sendPasswordResetLink(email).subscribe({
        next: (response => {
          this.passwordResetLinkIsSended = true;
          this.helpersService.spinnerService.hide();
          this.helpersService.toastService.success(response.message);
        }),
        error: (error => {
          this.passwordResetLinkIsSended = false;
          this.helpersService.spinnerService.hide();
          this.helpersService.toastService.error(error.error.message);
        })
      });
    }
    else{
      this.passwordResetRequestForm.markAllAsTouched();
    }
  }

  createLoginForm(){
    this.passwordResetRequestForm = this.formBuilder.group({
      email: ["", [Validators.email, Validators.required]]
    });
  }

}
