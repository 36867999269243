import { IngiridentItemDto } from "./ingiridentItemDto";

export class IngiridentDetailDto{
    id: number;
    name: string;
    items: IngiridentItemDto[] = [];
    icon: string;
    dropDownIsShowing: boolean;
    searchInput?: string;
    newItemInput?: string;
    addNewItemIsActive?: boolean = false;
}

