<footer>
    <div class="footer-main">
        <div class="footer-main-content">
            <div class="left-side">
                <div (click)="tourVideoIsShowingOutput(true)" class="watch-promotion">
                    <img src="../../../assets/icons/plya-icon.svg" draggable="false" alt="play-icon">
                    <span>{{'Footer.HowToUseWatch' | translate}}</span>
                </div>
                <div class="guide-container">
                    <img class="vertical-dotted-icon" src="../../../assets/icons/guide-dotted-line-vertical-short-gray-icon.svg" draggable="false" alt="dotted-line-icon">
                    <div class="step step-one">
                        <div class="step-number">
                            <p>1</p>
                        </div>
                        <div class="dotted-line">
                            <img src="../../../assets/icons/guide-dotted-line-horizontal-gray-icon.svg" draggable="false" alt="dotted-line-icon">
                        </div>
                        <div class="step-icon">
                            <img src="../../../assets/icons/guide-step-one-icon.svg" draggable="false" alt="guide-step-one-icon">
                        </div>
                        <div class="step-description">
                            <p>{{'Home.Recipe.Init.StepOne' | translate}}</p>
                        </div>
                    </div>
                    <div class="step step-two">
                        <div class="step-number">
                            <p>2</p>
                        </div>
                        <div class="dotted-line">
                            <img src="../../../assets/icons/guide-dotted-line-horizontal-gray-icon.svg" draggable="false" alt="dotted-line-icon">
                        </div>
                        <div class="step-icon">
                            <img src="../../../assets/icons/guide-step-two-icon.svg" draggable="false" alt="guide-step-two-icon">
                        </div>
                        <div class="step-description">
                            <p>{{'Home.Recipe.Init.StepTwo' | translate}}</p>
                        </div>
                    </div>
                    <div class="step step-theree">
                        <div class="step-number">
                            <p>3</p>
                        </div>
                        <div class="dotted-line">
                            <img src="../../../assets/icons/guide-dotted-line-horizontal-gray-icon.svg" draggable="false" alt="dotted-line-icon">
                        </div>
                        <div class="step-icon">
                            <img src="../../../assets/icons/guide-step-theree-icon.svg" draggable="false" alt="guide-step-theree-icon">
                        </div>
                        <div class="step-description">
                            <p>{{'Home.Recipe.Init.StepTheree' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-side">
                <div class="side blogs-categories">
                    <div routerLink="/blogs" class="top">
                        <h2>Blog</h2>
                        <img src="../../../assets/icons/arrow-right-pink-icon.svg" draggable="false" alt="right-arrow">
                    </div>
                    <div class="items">
                        <div *ngFor="let blogCategory of blogCategories" routerLink="/blogs" class="item">
                            <h3>{{'DatabaseData.BlogCategories.' + (blogCategory.id).toString()  | translate}}</h3>
                            <img src="../../../assets/icons/arrow-right-pink-icon.svg" draggable="false" alt="right-arrow">
                        </div>
                    </div>
                </div>
                <div class="side blogs-contents">
                    <div routerLink="/blogs" class="top">
                        <h2>{{'Footer.Contents' | translate}}</h2>
                        <img src="../../../assets/icons/arrow-right-pink-icon.svg" draggable="false" alt="right-arrow">
                    </div>
                    <div class="items">
                        <div *ngFor="let blogBasisData of blogsBasicData" routerLink="/blogs/detail/{{blogBasisData.urlName}}" class="item">
                            <h3>{{blogBasisData.title}}</h3>
                            <img src="../../../assets/icons/arrow-right-pink-icon.svg" draggable="false" alt="right-arrow">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="subscriber-form-container">
            <form [formGroup]="subscriberForm" *ngIf="subscriberForm">
                <div class="form-group name-form-group">
                    <input type="text" class="input" id="subscriberFormName" placeholder="{{'Footer.Form.Name' | translate}}" autocomplete="off" formControlName="name">
                </div>
                <div class="form-group email-form-group">
                    <input type="text" class="input" id="subscriberForEmail" placeholder="{{'Footer.Form.Email' | translate}}" autocomplete="off" formControlName="email">
                </div>
                <div class="form-group">
                    <button (click)="addSubscriber()" class="pb-btn-default"><img class="icon-left" src="../../../assets/icons/arrow-right-white-circle-icon.svg" draggable="false" alt="right">{{'Footer.Form.Subscribe' | translate}}</button>
                </div>
            </form>
        </div>
    </div>
    <div class="bottom">
        <div class="logo-and-powered">
            <div class="logo">
                <img src="../../../assets/logos/logo-new-white.svg" draggable="false" alt="logo">
            </div>
            <div class="powered">
                <span>Powered by</span>
                <a href="https://www.heetsoft.com" target="_blank" rel="noopener"><img src="../../../assets/logos/heetsoft-logo.svg" draggable="false" alt="logo"></a>
            </div>
        </div>
        <div class="copyright">
            <p>Copyright © 2023 HEETSOFT INFORMATION TECHNOLOGY</p>
        </div>
    </div>
</footer>


<app-how-to-use-video (tourVideoIsShowingOutput)="tourVideoIsShowingOutput($event)" [tourVideoIsShowing]="tourVideoIsShowing"></app-how-to-use-video>