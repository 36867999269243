
<div class="cookies-policy">
    <p class="cookies-policy-title">ÇEREZ POLİTİKASI VE ÇEREZLERE İLİŞKİN AYDINLATMA METNİ </p>
    <div class="cookies-policy-content">
        <p>İşbu Çerez Politikası ve Aydınlatma Metni <b>(“Politika”)</b>; HEETSOFT INFORMATION TECHNOLOGY <b>(“Heetsoft”)</b> tarafından yürütülen ve Heetsoft’a ait olan www.nepisir.com internet sitesi hakkında <b>(“İnternet Sitesi”)</b> çerez kullanımına ilişkin ilkeleri ve Heetsoft olarak veri sorumlusu sıfatıyla İnternet Sitesinin tarafımızca işletilmesi sebebiyle; veri işleme amacı, veri kategorileri, verilerin toplanma yöntemi ve hukuki sebebi, kimlere ve hangi amaçlarla aktarılabileceği, veri işleme süresi ve verileriniz ile ilgili haklarınızın neler olduğuna dair beyan ve açıklamalarımızı içermektedir.</p>
        <p>Heetsoft, web sitemizi çalıştırmak için gerekli çerezleri kullanır."Tüm tanımlama bilgilerine izin ver"i seçerseniz,size daha kişiselleştirilmiş içerik ve özel reklamlar göstermek de dahil olmak üzere,Heetsoft ile deneyiminizi geliştirmek için tanımlama bilgilerini de kullanacağız.</p>

        <p class="title">1. Heetsoft Tarafından Hangi Veriler İşlenmektedir?</p>
        <p>Heetsoft tarafından veri sahibinin açık rızasıyla ve Kanun’un 5. ve 6. Maddelerinde öngörülen hallerde açık rıza olmaksızın genel ve özel nitelikli verileri işleyebilme hakkına sahip bulunmaktadır.</p>
        <p>Bu kapsamda Heetsoft tarafından, Aydınlatma Metnine içeriğinde arz edilen hüküm ve koşullara uygun olarak site içerisinde aşağıda belirtilen türde <b>“kişisel veriler”</b> işlenebilmektedir:</p>

        <p class="title">2. Çerezler Ne Anlamı Gelir?</p>
        <p>Çerezler (cookie) bir web sitesi tarafından cihazınızda oluşturulan ve isim-değer formatında veri barındıran küçük metin dosyalarıdır. Herhangi bir web sitesini kullandığınız süre boyunca sizin kullanım alışkanlık ve tercihlerinizi tarayıcı üzerinde depolamaktadır. Çerezler, hem zamandan tasarruf edebilmek hem de daha iyi bir internet deneyimi sunmak mahiyeti ile kullanılır. Bir web sitesi tarafından oluşturulan çerezler siteye erişim için kullandığınız web tarayıcısı tarafından saklanmakta ve çerezlerin içerdiği bilgilere sadece çerezi oluşturan alan adı (örn. site.org.tr) altında sunulan web siteleri tarafından ve aynı tarayıcıyı kullandığınız takdirde uzaktan erişim mümkündür.  </p>
        <p>Çerezler, günümüzde, web teknolojilerinin önemli bir parçası haline gelmiştir ve temel işlevleri; ziyaretçinin ve tercihlerinin hatırlanması ile cihazın tanınmasıdır. Neredeyse her web sitesinde çerez kullanımı söz konusudur</p>

        <p class="title">3. Heetsoft Tarafından Kişisel Verilerin İşlenme Amaçları Nelerdir?</p>
        <p>İnternet Sitemizde çerez kullanılmasının başlıca amaçları aşağıda sıralanmaktadır:</p>
        <ol>
            <li>İnternet Sitesini iyileştirmek ve İnternet Sitesi üzerinden yeni özellikler sunmak ve sunulan özellikleri sizlerin tercihlerine göre kişiselleştirmek;</li>
            <li>Web sitemizin çeşitli özelliklerinin ve işlevlerinin düzgün bir şekilde çalışmasını sağlamak (örn. oturumunuzu açık tutmak, dinamik içeriği yerleştirmek vb.);</li>
            <li>Web sitemizin ziyaretçilerimiz tarafından nasıl kullanıldığı hakkında en çok tıklanan bağlantılar, en çok ziyaret edilen sayfalar, görüntülenen hata mesajı sayısı gibi, şahsa özel olmayan, genel bilgiler toplamak ve bu bilgileri analiz ederek hatalı sayfaları işler hale getirmek, web sitemizi geliştirmek, tercih edilmeyen sayfaları kaldırmak veya iyileştirmek.</li>
            <li>İnternet Sitesinden nasıl haberdar olduğunuzu belirlemek,</li>
            <li>Trafik istatistikleri oluşturmak,</li>
            <li>İnternet reklamcılığı, Targeting ve re-targeting (=hedefleme ve yeniden hedefleme), çapraz satış, kampanya, fırsat ve ürün/hizmet reklamları gibi size özel ürün ve hizmetlere yönelik fırsatlar sunmak, tercihlerinizi göz önünde bulundurarak önerilerde bulunmak.  </li>
        </ol>
        <p>Çerezler bu Politika’da belirtilen amaçlar dışında kullanılmamakta olup tüm ilgili işlemler veri koruma mevzuatına uygun olarak yürütülmektedir. </p>

        <p class="title">4. Kişisel Verilerin Korunmasına İlişkin Hangi Önlemler Alınmıştır ve Veriler Ne Şekilde Saklanacaktır?</p>
        <p>Heetsoft; Kanun’da ve işbu Politika’da belirtilen şartlarla; kişisel verilerin muhafazasını, kişisel verilerin hukuka aykırı olarak işlenmemesini, kişisel verilere hukuka aykırı olarak erişilmemesini sağlamak ve veri sızıntılarını önlemek amaçlarıyla gerekli tüm teknik ve idari tedbirleri almayı, yapılması gereken tüm denetimler zamanında ve eksiksiz olarak yaptırmayı taahhüt etmektedir.</p>
        <p>Kişisel verileriniz, size bildirilen amaçlar ve kapsam dışında kullanılmamak kaydı ile gerekli tüm bilgi güvenliği tedbirleri de alınarak işlenecek ve yasal saklama süresince veya böyle bir süre öngörülmemişse işleme amacının gerekli kıldığı süre boyunca saklanacak ve işlenecektir. Bu süre sona erdiğinde, kişisel verileriniz silinme, yok edilme ya da anonimleştirme yöntemleri ile Heetsoft’un veri akışlarından çıkarılacaktır.</p>
   
        <p class="title">5. Kişisel Veriler Hangi Taraflara Aktarılmaktadır?</p>
        <p>Heetsoft tarafından işbu aydınlatma metninde bahsedilen amaçlarla sınırlı olarak ve kanunun 5. ve 6. Maddelerine uygun olarak işlenen kişisel veriler;</p>
        <ol>
            <li>Türk Telekomünikasyon Anonim Şirketi ve Türk Telekomünikasyon grup şirketlerine,</li>
            <li>Yetki vermiş olduğumuz, Şirketimiz nam ve hesabına faaliyette bulunan şirketlere ve temsilcilerimize,</li>
            <li>Düzenleyici ve denetleyici kurumlara ve kanunlarda açıkça kişisel verileri talep etmeye yetkili olan kamu kurum veya kuruluşlarına,</li>
            <li>Belirtilen amaçlar kapsamında iş ortaklıkları, tedarikçi ve yüklenici şirketler, bankalar, kredi risk ve finans kuruluşları ve sair gerçek veya tüzel kişilere,</li>
            <li>Vergi ve benzeri danışmanlara, yasal takip süreçler ile ilgili zorunlu kişilere, kamu kurum ve kuruluşlara ve deneticimler de dahil olmak üzere danışmanlık aldığımız üçüncü kişilere ve bunlarla sınırlı olmaksızın, yukarıda belirtilen amaçlarla iş ortakları, hizmet alınan üçüncü kişi, yetkilendirilen kişi ve kuruluşlara aktarılabilecektir</li>
        </ol>
        <p>Kişisel verileriniz Heetsoft tarafından herhangi bir şekilde yurt dışına aktarılmamakta olup, yurtiçi aktarım süreçleri ile ilgili olarak gerekmesi halinde aktarıma ilişkin açık rızanız ayrıca temin edilecektir. </p>
    </div>
</div>