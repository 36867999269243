export class Language{

    constructor(id?: number, name?: string, code?: string) {
        this.id = id;
        this.name = name;
        this.code = code;
    }   

    id: number;
    name: string;
    code: string;
}