import { Pipe, PipeTransform } from '@angular/core';
import { BlogWithoutExplanationDto } from '../models/blogWithoutExplanationDto';

@Pipe({
  name: 'blogSearch'
})
export class BlogSearchPipe implements PipeTransform {

  transform(value: BlogWithoutExplanationDto[], searchText: string): BlogWithoutExplanationDto[] {

    searchText = searchText ? searchText.toLocaleLowerCase().trim() : '';
    
    return searchText
      ? value.filter(
          (b: BlogWithoutExplanationDto) =>
            b.title.toLocaleLowerCase().indexOf(searchText) !== -1 ||
            b.blogCategory.name.toLocaleLowerCase().indexOf(searchText) !== -1
        )
      : value;
  }

}
