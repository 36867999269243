import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SingleResponseModel } from '../models/singleResponseModel';
import { AuthenticatedUserCredentialsDto } from '../models/authenticatedUserCredentialsDto';
import { ResponseModel } from '../models/responseModel';
import { UserBasicInformation } from '../models/user/userBasicInformation';
import { PaginateResponseModel } from '../models/responses/paginateResponseModel';
import { UserDashboarStatisticDto } from '../models/dtos/userStatistic/userDashboarStatisticDto';
import { PageRequest } from '../models/paginates/pageRequest';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  getAuthenticatedUserCredentialsByUserId(userId: any): Observable<SingleResponseModel<AuthenticatedUserCredentialsDto>>{
    let newPath = `${this.apiUrl}Users/GetAuthenticatedUserCredentialsByUserId?userId=${userId}`;
    return this.httpClient.get<SingleResponseModel<AuthenticatedUserCredentialsDto>>(newPath);
  }
  
  getAuthenticatedUserCredentialsByEmail(email: any): Observable<SingleResponseModel<AuthenticatedUserCredentialsDto>>{
    let newPath = `${this.apiUrl}Users/GetAuthenticatedUserCredentialsByEmail?email=${email}`;
    return this.httpClient.get<SingleResponseModel<AuthenticatedUserCredentialsDto>>(newPath);
  }

  getUserStatisticsForDashboard(pageRequest?: PageRequest): Observable<PaginateResponseModel<UserDashboarStatisticDto>>{
    let newPath = `${this.apiUrl}Users/GetUserStatisticsForDashboard`;
    return this.httpClient.get<PaginateResponseModel<UserDashboarStatisticDto>>(newPath, {params: {...pageRequest}});
  }

  updateBasicInformation(user: UserBasicInformation): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}Users/UpdateBasicInformation`;
    return this.httpClient.post<ResponseModel>(newPath, user);
  }
}
