import { HelpersFactoryService } from 'src/app/services/helpers-factory.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RecipeTypeEnum } from 'src/app/enums/recipeTypeEnum';
import { RecipeBasicDto } from 'src/app/models/recipeBasicDto';
import { RecipeService } from 'src/app/services/recipe.service';
import { UserRecipeService } from 'src/app/services/user-recipe.service';
import { PageTitle } from 'src/app/constants/pageTitle';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.css']
})
export class RecipesComponent implements OnInit {

  @ViewChild('comingFromYouContent', { read: ElementRef }) public mostLikedContent: ElementRef<any>;

  recipeSearchText: string = '';

  allRecipesBasicData: RecipeBasicDto[] = [];
  userRecipes: RecipeBasicDto[] = [];

  allRecipesBasicDataCount = 15;

  recipeTypeEnum = RecipeTypeEnum;

  userRecipesDataIsLoaded: Promise<boolean>;
  allRecipesDataIsLoaded: Promise<boolean>;
  
  constructor(private recipeService: RecipeService,
    private userRecipeService: UserRecipeService,
    public HelpersFactoryService: HelpersFactoryService) { }

  ngOnInit(): void {
    this.HelpersFactoryService.pageTitleService.setTitleWithCanonical(PageTitle.layouts.mainLayout.recipes);
    this.getRecipeBasicData();
    this.getUserRecipeBasiData();
  }

  getRecipeBasicData(){
    this.recipeService.getRecipesBasicData(this.allRecipesBasicDataCount).subscribe({
      next: (response => {
        this.allRecipesBasicData = response.data;
        this.allRecipesDataIsLoaded = Promise.resolve(true);
      })
    });
  }

  getUserRecipeBasiData(){
    this.userRecipeService.getUserRecipesBasicData().subscribe({
      next: (response => {
        this.userRecipes = response.data;
        this.userRecipesDataIsLoaded = Promise.resolve(true);
      })
    });
  }

  seeMoreRecipe(){
    this.allRecipesBasicDataCount += 15;
    this.recipeService.getRecipesBasicData(this.allRecipesBasicDataCount).subscribe({
      next: (response => {
        this.allRecipesBasicData = response.data;
      })
    });
  }

  public comingFromYouContentScroll(value: number): void {
    this.mostLikedContent.nativeElement.scrollTo({ left: (this.mostLikedContent.nativeElement.scrollLeft + value), behavior: 'smooth' });
  }

  counter(count: number): any{
    let array: number[] = [];
    for(let i = 1; i <= count; i++){
      array.push(i);
    }
    return array;
  }

  redirectRecipeDetail(recipe: RecipeBasicDto){
    this.HelpersFactoryService.routerNavigate(this.getRedirectRecipeUrl(recipe));
  }

  getRedirectRecipeUrl(recipe: RecipeBasicDto): string{
    return `/recipes/detail/${recipe.recipeUrl}-${recipe.recipeId}-${recipe.recipeType.id}`;
  }
}
