import { Component, Input } from '@angular/core';
import { ChartOptions } from 'src/app/models/apexChart/chartOptions';

@Component({
  selector: 'app-chart-partial',
  templateUrl: './chart-partial.component.html',
  styleUrl: './chart-partial.component.css'
})
export class ChartPartialComponent {

  @Input() chartOptions: Partial<ChartOptions>;
  
}
