import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/responseModel';

@Injectable({
  providedIn: 'root'
})
export class BlogReadingTimeService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  increaseBlogNumberOfReads(blogId: number): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}BlogReadingTimes/IncreaseBlogNumberOfReads?blogId=${blogId}`;
    return this.httpClient.get<ResponseModel>(newPath);
  }

  increaseBlogDurationOfReads(blogId: number, second: number): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}BlogReadingTimes/IncreaseBlogDurationOfReads?blogId=${blogId}&second=${second}`;
    return this.httpClient.get<ResponseModel>(newPath);
  }
}
