<router-outlet></router-outlet>

<!-- Cookies Policy Agreement (Start) -->
<div *ngIf="cookiesPolicyAgreementIsShowing" class="app-component-cookie-policy-toast">
    <div class="cookie-policy-messages">
        <p>{{'CookiePolicyToast.Title' | translate}}</p>
        <p>{{'CookiePolicyToast.Explanation' | translate}}<span><a href="/cookies-policy" target="_blank" rel="noopener">{{'CookiePolicyToast.CookiePolicyText' | translate}}</a></span></p>
    </div>
    <button (click)="setCookiesPolicyAgreement()" class="btn-agree-cookie-policy">{{'CookiePolicyToast.AcceptAll' | translate}}</button>
</div>
<!-- Cookies Policy Agreement (End) -->

<ngx-spinner bdColor="rgba(0, 0, 0, 0.9)" size="medium" color="rgb(255, 255, 255)" type="ball-pulse-sync" [fullScreen]="true"><p style="color: white" > {{'Global.PleaseWait' | translate}} </p></ngx-spinner>