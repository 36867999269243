<div class="blog-text-editor-main mt-4">
    <ng-container>
        <div class="new-blog-area">
            <div class="side left-side">
                <div class="blog-settings">
                    <div class="section blog-main-image">
                        <div class="pb-image-upload-box">
                            <div *ngIf="uploadedBlogMainImage.isUploadFile" class="uploaded-image" (click)="selectBlogMainImage.click()">
                                <img [src]="UploadFileHelperService.getUploadedImage(uploadedBlogMainImage)" draggable="false" alt="image">
                            </div>
                            <div *ngIf="!uploadedBlogMainImage.isUploadFile" (click)="selectBlogMainImage.click()" class="empty-image-area">
                                <img src="../../../assets/icons/image-cartoon-icon.svg" draggable="false" alt="image-cartoon-icon">
                                <p class="first">Blog fotoğrafı yükleyin</p>
                                <p class="second">Blogun ana fotoğrafı kullanıcın göreceği ilk resimdir.</p>
                            </div>
                            <input type="file" class="image-upload-input" name="profile" (change)="UploadFileHelperService.onFileSelect($event, uploadedBlogMainImage)" accept="image/png, image/jpg, image/jpeg" #selectBlogMainImage/>
                        </div>
                    </div>
                    <div class="section blog-features">
                        <form [formGroup]="newBlogForm" *ngIf="newBlogForm">
                            <div class="row">
                                <div class="form-group">
                                    <input class="form-control" type="text" id="title" formControlName="title" placeholder="Blog Başlığı" autocomplete="false">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <div class="dropdown">
                                        <button class="btn btn-warning dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {{currentBlogCategory != null ? currentBlogCategory.name : 'Blog Kategorisi'}}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a 
                                              *ngFor="let blogCategory of blogCategories" 
                                              (click)="currentBlogCategory = blogCategory"
                                              class="dropdown-item">
                                              {{blogCategory.name}}
                                            </a>
                                        </div>
                                      </div>
                                </div>
                                <div class="col-md-6 form-group">
                                    <div class="dropdown">
                                        <button class="btn btn-warning dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          {{currentLanguage != null ? currentLanguage.name : 'Dil'}}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <a 
                                            *ngFor="let language of languages" 
                                            (click)="currentLanguage = language"
                                            class="dropdown-item">
                                            {{language.name}}
                                          </a>
                                        </div>
                                      </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="text-editor m-t-30">
                    <app-text-editor-partial *ngIf="!blog" (contentData)="textEditorContent = $event"></app-text-editor-partial>
                    <app-text-editor-partial *ngIf="blog" (contentData)="textEditorContent = $event" [inputData]="blog.explanation"></app-text-editor-partial>
                </div>
                <div class="text-center">
                    <button *ngIf="!blog" (click)="addNewBlog()" type="button" class="btn btn-primary mt-4 mb-4 w-100">Gönder</button>
                    <button *ngIf="blog" (click)="updateBlogExplanation()" type="button" class="btn btn-primary mt-4 mb-4 w-100">Güncelle</button>
                </div>
            </div>
            <div class="right-side">
                <div class="hide-scroll side blog-content-images">
                    <h5 class="section-title">Blog Fotoğrafları <button (click)="selectBlogContentImage.click()" class="btn btn-primary btn-sm m-l-10">Fotoğraf Yükle</button></h5>
                    <div class="pb-image-upload-box ">
                        <div *ngIf="uploadedBlogImages.length > 0" class="uploaded-image m-t-30">
                            <div *ngFor="let uploadedBlogImage of uploadedBlogImages.reverse()" class="uploaded-image-item">
                                <img [src]="UploadFileHelperService.getUploadedImage(uploadedBlogImage)" draggable="false" alt="image">
                                <div *ngIf="uploadedBlogImage.isSaved" class="uploaded-image-edit-utilites">
                                    <app-ng-image (click)="copyTextToClipboard(uploadedBlogImage.filePath)" class="item" src="../../../assets/icons/link-gray-icon.svg"></app-ng-image>
                                    <app-ng-image (click)="deleteUploadedBlogImage(uploadedBlogImage)" class="item" src="../../../assets/icons/trash-pink-fill-icon.svg"></app-ng-image>
                                </div>
                                <div *ngIf="!uploadedBlogImage.isSaved" class="uploaded-image-edit-utilites">
                                    <button (click)="uploadBlogContentImage(uploadedBlogImage)" class="btn btn-success btn-sm m-l-10">Yükle</button>
                                </div>
                            </div>
                        </div>
                        <input type="file" class="image-upload-input" name="profile" (change)="UploadFileHelperService.onFileSelectCollection($event, uploadedBlogImages)" accept="image/png, image/jpg, image/jpeg" #selectBlogContentImage/>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
