import { TranslateModuleService } from './services/translate-module.service';
import { HelpersFactoryService } from './services/helpers-factory.service';
import { GeoLocationService } from './services/geo-location.service';
import { Component, OnInit } from '@angular/core';
import { Visitor } from './models/visitor';
import { VisitorService } from './services/visitor.service';
import { AuthService } from './services/auth.service';
import { GeoLocation } from './models/entities/geoLocation';
import { ClientGeoLocationModel } from './models/common/clientGeoLocationModel';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  title = 'RecipeExpert';

  cookiesPolicyAgreementIsShowing: boolean = true;

  constructor(private authService: AuthService,
    private visitorService: VisitorService,
    private geoLocationService: GeoLocationService,
    private translateModuleService: TranslateModuleService,
    private helpersFactoryService: HelpersFactoryService){
      this.helpersFactoryService.saveLastRoute();
    }

  ngOnInit(): void {
    this.translateModuleService.getBrowserLanguage();
    this.checkCookiesPolicyAgreement();
    this.getClientGeoLocation();
    this.authService.refreshAccessToken();
  }

  
  checkCookiesPolicyAgreement(){
    let cookiesPolicyAgreement = this.helpersFactoryService.localStorageService.getItem("cookiesPolicyAgreement");
    if(cookiesPolicyAgreement == "yes"){
      this.cookiesPolicyAgreementIsShowing = false;
    }
    else{
      this.cookiesPolicyAgreementIsShowing = true;
    }
  }
  
  setCookiesPolicyAgreement(){
    this.helpersFactoryService.localStorageService.setItem("cookiesPolicyAgreement", "yes");
    this.cookiesPolicyAgreementIsShowing = false;
  }

  getClientGeoLocation(){
    this.geoLocationService.getClientGeoLocation().subscribe({
      next: (response => {
        let ipAddress = this.helpersFactoryService.localStorageService.getClientGeoLocation();
        if(ipAddress == undefined || ipAddress == null){
          this.helpersFactoryService.localStorageService.setClientGeoLocation(response);
        }
        this.saveClientGeoLocation(response);
      })
    });
  }

  saveClientGeoLocation(clientGeoLocationModel: ClientGeoLocationModel){
    let geoLocation = new GeoLocation(0, clientGeoLocationModel.country_name, clientGeoLocationModel.country_code, clientGeoLocationModel.city, String(clientGeoLocationModel.latitude).toString(), String(clientGeoLocationModel.longitude).toString(), new Date());
        this.geoLocationService.add(geoLocation).subscribe({
          next: (resultResponse => {
            let clientIp = this.helpersFactoryService.localStorageService.getClientIp();
            let visitor = new Visitor(0, resultResponse.data.id, clientIp, new Date());
            this.visitorService.addAsync(visitor).subscribe({});
          })
    });
  }
  

}
