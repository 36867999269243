<ng-container *ngIf="!passwordResetLinkIsActive">
    <p class="password-reset-link-error-message">{{passwordResetLinkErrorMessage}}</p>
</ng-container>
<ng-container *ngIf="passwordResetLinkIsActive">
    <div class="title m-t-70">
        <p class="title-text">PAROLANI DEĞİŞTİR</p>
    </div>
    <div class="dashboard-login-container m-t-50">
        <form [formGroup]='passwordResetNewPasswordForm' *ngIf="passwordResetNewPasswordForm">
            <div class="login-form w-100">
                <div class="form-group">
                    <div class="pb-input-box">
                        <input type="password" autocomplete="off" formControlName="newPassword" required="required" [class.input-error]="passwordResetNewPasswordForm.get('newPassword').invalid && passwordResetNewPasswordForm.get('newPassword').touched">
                        <span>Yeni Parola</span>
                    </div>
                    <div class="form-group-validation-container" *ngIf="passwordResetNewPasswordForm.get('newPassword')?.invalid && passwordResetNewPasswordForm?.get('newPassword')?.errors && (passwordResetNewPasswordForm.get('newPassword')?.dirty || passwordResetNewPasswordForm.get('newPassword')?.touched)">
                        <small class="text-danger" *ngIf="passwordResetNewPasswordForm.get('newPassword')?.hasError('required')">
                            * Lütfen bu alanı doldurun
                        </small>
                    </div>
                </div>
                <div class="form-group m-t-20">
                    <div class="pb-input-box">
                        <input type="password" autocomplete="off" formControlName="newPasswordConfirm" required="required" [class.input-error]="passwordResetNewPasswordForm.get('newPasswordConfirm').invalid && passwordResetNewPasswordForm.get('newPasswordConfirm').touched">
                        <span>Yeni Parola Tekrar</span>
                    </div>
                    <div class="form-group-validation-container" *ngIf="passwordResetNewPasswordForm.get('newPasswordConfirm')?.invalid && passwordResetNewPasswordForm?.get('newPasswordConfirm')?.errors && (passwordResetNewPasswordForm.get('newPasswordConfirm')?.dirty || passwordResetNewPasswordForm.get('newPasswordConfirm')?.touched)">
                        <small class="text-danger" *ngIf="passwordResetNewPasswordForm.get('newPasswordConfirm')?.hasError('required')">
                            * Lütfen bu alanı doldurun
                        </small>
                    </div>
                </div>
                <div class="buttons m-t-25">
                    <button (click)="updatePassword()" class="pb-btn-default flex-1">Parolayı Değiştir</button>
                </div>
            </div>
        </form>
    </div>
</ng-container>