export class SystemRecipeAddDto{
    userId: number;
    recipeTypeId: number;
    recipeName: string;
    recipeContent: string;
    calorie: string;
    protein: string;
    oil: string;
    carbohydrate: string;
    averageCookingTime: string;
    recipeRequestJson: string;
}