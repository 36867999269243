import { TranslateModuleService } from './../../services/translate-module.service';
import { HelpersFactoryService } from './../../services/helpers-factory.service';
import { Component, OnInit } from '@angular/core';
import { Language } from 'src/app/models/language';
import { AuthService } from 'src/app/services/auth.service';
import { UserBasicInformation } from 'src/app/models/user/userBasicInformation';

class ProfileMenuItem{
  constructor(name?: string, iconPath?: string, routerPath?: string, languageName?: string) {
    this.name = name;
    this.iconPath = iconPath;
    this.routerPath = routerPath;
    this.languageName = languageName;
  }

  name: string;
  iconPath: string;
  routerPath: string;
  languageName: string;
}

enum ProfileMenuItemEnums{
  Profile = "profile",
  MakedRecipes = "maked-recipes",
  SentRecipes = "sent-recipes",
  RecipeBook = "recipe-book",
  Comments = "comments",
  Drafts = "drafts",
  PointSystem = "point-system"
}


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  userBasicInformation: UserBasicInformation;

  userIsAuthenticated: boolean;
  languageTypeDropDownIsShowing: boolean;
  profileMenuDropDownIsShowing: boolean;
  languageSearchText: string;

  currentLanguage: Language;

  profileMenuItems: ProfileMenuItem[] = [];

  navbarIsOpened: boolean;
  
  langauges: any;

  constructor(private authService: AuthService,
    private translateModuleService: TranslateModuleService,
    private helpersFactoryService: HelpersFactoryService) { 
      this.langauges = TranslateModuleService.languages;
    }

  ngOnInit(): void {
   
    this.translateModuleService.getBrowserLanguage();
    this.currentLanguage = TranslateModuleService.currentLanguage;

    this.userIsAuthenticated  = this.authService.userIsAuthenticated();
    if(this.userIsAuthenticated){
      this.userBasicInformation = this.helpersFactoryService.localStorageService.getAuthenticatedUserInformations().userBasicInformation;
      this.getProfileMenuItems();
    }
  }

  getProfileMenuItems(){
    let iconPaths = ["profile-gray-icon.svg", "paper-upload-gray-icon.svg", "document-gray-icon.svg", "chat-gray-icon.svg", "star-gray-icon.svg"];
    let names = ["Profilim", "Gönderdiğim Tarifler", "Tarif Defterim", "Yorumlarım", "Puan Sistemi"];
    let languageNames = ["Profile", "RecipesISent", "RecipeBook", "MyComments", "PointSystem", "AccountSettings", "Exit"];
    let routerPaths = [ProfileMenuItemEnums.Profile, ProfileMenuItemEnums.SentRecipes, ProfileMenuItemEnums.RecipeBook, ProfileMenuItemEnums.Comments, ProfileMenuItemEnums.PointSystem];
    for(let i = 0; i < names.length; i++){
      let item = new ProfileMenuItem(names[i], iconPaths[i], routerPaths[i], languageNames[i]);
      this.profileMenuItems.push(item);
    }
  }

  // getProfileMenuItems(){
  //   let iconPaths = ["profile-gray-icon.svg", "paper-plus-gray-icon.svg", "paper-upload-gray-icon.svg", "document-gray-icon.svg", "chat-gray-icon.svg", "edit-gray-icon.svg", "star-gray-icon.svg"];
  //   let names = ["Profilim", "Yaptığım Tarifler", "Gönderdiğim Tarifler", "Tarif Defterim", "Yorumlarım", "Taslaklarım", "Puan Sistemi"];
  //   let languageNames = ["Profile", "RecipesIMade", "RecipesISent", "RecipeBook", "MyComments", "Drafts", "PointSystem", "AccountSettings", "Exit"];
  //   let routerPaths = [ProfileMenuItemEnums.Profile, ProfileMenuItemEnums.MakedRecipes, ProfileMenuItemEnums.SentRecipes, ProfileMenuItemEnums.RecipeBook, ProfileMenuItemEnums.Comments, ProfileMenuItemEnums.Drafts, ProfileMenuItemEnums.PointSystem];
  //   for(let i = 0; i < names.length; i++){
  //     let item = new ProfileMenuItem(names[i], iconPaths[i], routerPaths[i], languageNames[i]);
  //     this.profileMenuItems.push(item);
  //   }
  // }
  
  changeLanguage(language: Language){
    this.translateModuleService.changeLanguage(language);
  }

  toggleLanguageTypeDropDown(){
    this.languageTypeDropDownIsShowing = !this.languageTypeDropDownIsShowing;
  }

  toggleProfileMenuDropDown(){
    this.profileMenuDropDownIsShowing = !this.profileMenuDropDownIsShowing;
  }

  logout(){
    this.authService.logout();
  }

  routerNavigate(route: any){
    this.helpersFactoryService.routerNavigate(`/${route}`);
    const navbarMenu = document.getElementById('navbarMenu');
    if(navbarMenu != null){
      if(this.navbarIsOpened){
        navbarMenu.style.display = "none";
        this.navbarIsOpened = !this.navbarIsOpened;
      }
    }
  }

  navbarVisibleEvent(){
    const navbarMenu = document.getElementById('navbarMenu');
    if(navbarMenu != null){
      if(this.navbarIsOpened){
        navbarMenu.style.display = "none";
      }
      else{
        navbarMenu.style.display = "block";
      }
      this.navbarIsOpened = !this.navbarIsOpened;
    }
  }
}
