import { Ingirident } from "./ingirident";

export class RecipeRequestDto{
    meatType: string;
    heatSources: string[];
    cookingVessels: string[];
    cookingTechniques: string[];
    units: string[];
    dietTypes: string[];
    portionNumber: string;
    recipeMinute: string;
    portionSize: string;
    unwanteds: string[];
    healthConditions: string[];
    ingiridents: Ingirident[];
    language: string;
}