import { BlogComment } from './../models/blogComment';
import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/responseModel';
import { ListResponseModel } from '../models/listResponseModel';

@Injectable({
  providedIn: 'root'
})
export class BlogCommentService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  add(blogComment: BlogComment): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}BlogComments/Add`;
    return this.httpClient.post<ResponseModel>(newPath, blogComment);
  }

  getAll(): Observable<ListResponseModel<BlogComment>>{
    let newPath = `${this.apiUrl}BlogComments/GetAll`;
    return this.httpClient.get<ListResponseModel<BlogComment>>(newPath);
  }

  getByBlogId(blogId: number): Observable<ListResponseModel<BlogComment>>{
    let newPath = `${this.apiUrl}BlogComments/GetByBlogId?blogId=${blogId}`;
    return this.httpClient.get<ListResponseModel<BlogComment>>(newPath);
  }
}
