import { Guid } from "guid-typescript";

export class FileBase{

    constructor(fileName?: string, uniqueName?: Guid, filePath?: string, fileType?: string, date?: Date) {
        this.fileName = fileName;
        this.uniqueName = uniqueName;
        this.filePath = filePath;
        this.fileType = fileType;
        this.date = date;       
    }

    fileName: string;
    uniqueName: Guid;
    filePath: string;
    fileType: string;
    date: Date;
}