<div class="app-chart-partial">
  <apx-chart
    [series]="chartOptions?.series"
    [chart]="chartOptions?.chart"
    [xaxis]="chartOptions?.xaxis"
    [grid]="chartOptions?.grid"
    [plotOptions]="chartOptions?.plotOptions"
    [dataLabels]="chartOptions?.dataLabels"
    [legend]="chartOptions?.legend"
    [yaxis]="chartOptions?.yaxis"
    [xaxis]="chartOptions?.xaxis"
    [colors]="chartOptions?.colors"
    [labels]="chartOptions?.labels"
    [stroke]="chartOptions?.stroke"
    [tooltip]="chartOptions?.tooltip"
    [states]="chartOptions?.states"
  ></apx-chart>
</div>
