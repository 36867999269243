import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-partial',
  templateUrl: './spinner-partial.component.html',
  styleUrls: ['./spinner-partial.component.css', './spinner-partial.component.scss']
})
export class SpinnerPartialComponent {

  @Input() size: number = 65;
}
