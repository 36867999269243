import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/responseModel';
import { ListResponseModel } from '../models/listResponseModel';
import { SingleResponseModel } from '../models/singleResponseModel';
import { Blog } from '../models/blog';
import { BlogDetailDto } from '../models/blogDetailDto';
import { BlogBasicDataDto } from '../models/blogBasicDataDto';
import { BlogWithoutExplanationDto } from '../models/blogWithoutExplanationDto';
import { PaginateResponseModel } from '../models/responses/paginateResponseModel';
import { PageRequest } from '../models/paginates/pageRequest';
import { Language } from '../models/language';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  add(blog: FormData): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}Blogs/Add`;
    return this.httpClient.post<ResponseModel>(newPath, blog);
  }

  update(blog: Blog): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}Blogs/Update`;
    return this.httpClient.post<ResponseModel>(newPath, blog);
  }

  updateExplanation(blog: Blog): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}Blogs/UpdateExplanation`;
    return this.httpClient.post<ResponseModel>(newPath, blog);
  }

  getAll(): Observable<ListResponseModel<BlogDetailDto>>{
    let newPath = `${this.apiUrl}Blogs/GetAll`;
    return this.httpClient.get<ListResponseModel<BlogDetailDto>>(newPath);
  }

  getBlogsDetailWithoutExplanation(pageRequest?: PageRequest, language?: Language): Observable<PaginateResponseModel<BlogWithoutExplanationDto>>{
    let newPath = `${this.apiUrl}Blogs/GetBlogsDetailWithoutExplanation`;
    return this.httpClient.get<PaginateResponseModel<BlogWithoutExplanationDto>>(newPath, {params: {...pageRequest, ...language}});
  }

  getTopFiveBlogsBasicData(language?: Language): Observable<ListResponseModel<BlogBasicDataDto>>{
    let newPath = `${this.apiUrl}Blogs/GetTopFiveBlogsBasicData`;
    return this.httpClient.get<ListResponseModel<BlogBasicDataDto>>(newPath, {params: {...language}});
  }

  getById(id: number): Observable<SingleResponseModel<BlogDetailDto>>{
    let newPath = `${this.apiUrl}Blogs/GetById?id=${id}`;
    return this.httpClient.get<SingleResponseModel<BlogDetailDto>>(newPath);
  }

  getByBlogTitle(title: string): Observable<SingleResponseModel<BlogDetailDto>>{
    let newPath = `${this.apiUrl}Blogs/GetByBlogTitle?blogTitle=${title}`;
    return this.httpClient.get<SingleResponseModel<BlogDetailDto>>(newPath);
  }

  getByUrlName(urlName: string): Observable<SingleResponseModel<BlogDetailDto>>{
    let newPath = `${this.apiUrl}Blogs/GetByUrlName?urlName=${urlName}`;
    return this.httpClient.get<SingleResponseModel<BlogDetailDto>>(newPath);
  }
}
