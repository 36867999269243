import { HelpersFactoryService } from './../../services/helpers-factory.service';
import { UserRecipeAddDto } from './../../models/userRecipeAddDto';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Messages } from 'src/app/constants/messages';
import { RecipeTypeEnum } from 'src/app/enums/recipeTypeEnum';
import { SendRecipeAddedIngredientDto } from 'src/app/models/sendRecipeAddedIngredientDto';
import { UserRecipeService } from 'src/app/services/user-recipe.service';
import { AuthenticatedUserModel } from 'src/app/models/auth/authenticatedUserModel';
import { PageTitle } from 'src/app/constants/pageTitle';

@Component({
  selector: 'app-send-recipe',
  templateUrl: './send-recipe.component.html',
  styleUrls: ['./send-recipe.component.css']
})
export class SendRecipeComponent implements OnInit {

  image: SafeUrl;
  uploadedRecipeImage: File;

  isUploadedImage: boolean = false;

  addedIngredients: SendRecipeAddedIngredientDto[] = [];
  addedRecipeSteps: string[] = [];

  recipeForm: FormGroup;
  recipeImageUploadForm: FormGroup
  addIngredientForm: FormGroup;
  addRecipeStepFrom: FormGroup;
  
  authenticatedUser: AuthenticatedUserModel;

  constructor(private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private userRecipeService: UserRecipeService,
    private helpersFactoryService: HelpersFactoryService) { }

  ngOnInit(): void {
    this.helpersFactoryService.pageTitleService.setTitleWithCanonical(PageTitle.layouts.mainLayout.sendRecipe);
    this.authenticatedUser = this.helpersFactoryService.localStorageService.getAuthenticatedUserInformations();
    this.createRecipeForm();
    this.createAddIngredientForm();
    this.createAddRecipeStepForm();
    this.createRecipeImageUploadForm();
  }

  sendRecipe(){
    if(this.recipeForm.valid && this.isUploadedImage && this.addedIngredients.length > 0 && this.addedRecipeSteps.length > 0){
      this.helpersFactoryService.spinnerService.show();
      let recipeFormValues = Object.assign({}, this.recipeForm.value);
      let recipeModel = new UserRecipeAddDto();
      recipeModel.userId = this.authenticatedUser.userBasicInformation.id;
      recipeModel.recipeTypeId = RecipeTypeEnum.UserRecipe;
      recipeModel.recipeName = recipeFormValues["recipeName"];
      recipeModel.trickText = recipeFormValues["trickText"];
      recipeModel.categorizationText = recipeFormValues["categorization"];
      recipeModel.ingredients = this.addedIngredients;
      recipeModel.steps = this.addedRecipeSteps;
      recipeModel.averageCookingTime = recipeFormValues["averageCookingTime"];

      let formData = new FormData();
      formData.append('recipeImage', this.uploadedRecipeImage);
      formData.append('recipeModel', JSON.stringify(recipeModel));

      this.userRecipeService.add(formData).subscribe({
        next: (response => {
          this.helpersFactoryService.toastService.success(response.message);
          this.helpersFactoryService.spinnerService.hide();
          this.resetAllForms();
        }),
        error: (error => {
          this.helpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
          this.helpersFactoryService.spinnerService.hide();
        })
      });
    }
    else{
      this.helpersFactoryService.toastService.warning("Lütfen gerekli alanların eksiksiz ve doğru bir şekilde olduğuna emin olunuz!");
    }
  }

  addIngredient(){
    if(this.addIngredientForm.valid){
      let model = Object.assign({}, this.addIngredientForm.value);
      this.addedIngredients.push(model);
      this.addIngredientForm.reset();
    }
  }

  addRecipeStep(){
    if(this.addRecipeStepFrom.valid){
      let model = Object.assign({}, this.addRecipeStepFrom.value);
      this.addedRecipeSteps.push(model["step"]);
      this.addRecipeStepFrom.reset();
    }
  }

  removeIngredient(ingredient: SendRecipeAddedIngredientDto){
    this.addedIngredients = this.addedIngredients.filter(item => item !== ingredient);
  }

  removeRecipeStep(index: any){
    const item = this.addedRecipeSteps.indexOf(index, 0);
    if (index > -1) {
      this.addedRecipeSteps.splice(index, 1);
    }
  }

  createRecipeForm(){
    this.recipeForm = this.formBuilder.group({
      recipeName: ["", Validators.required],
      trickText: [""],
      categorization: [""],
      averageCookingTime: ["", [Validators.min(0), Validators.pattern("^[0-9]*$")]]
    });
  }

  createAddIngredientForm(){
    this.addIngredientForm = this.formBuilder.group({
      ingredientName: ["", Validators.required],
      measurement: [null],
      typeOfAdd: [null]
    });
  }

  createAddRecipeStepForm(){
    this.addRecipeStepFrom = this.formBuilder.group({
      step: ["", [Validators.required]]
    });
  }

  createRecipeImageUploadForm(){
    this.recipeImageUploadForm = this.formBuilder.group({
      image: [null],
    });
  }

  onFileSelect(event:any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.recipeImageUploadForm.get('image').setValue(file);
      this.uploadedRecipeImage = file;
      this.image = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(event.target.files[0])
      );
      this.isUploadedImage = true;
    }
  }

  resetAllForms(){
    this.recipeForm.reset();
    this.addIngredientForm.reset();
    this.addRecipeStepFrom.reset();
    this.image = null;
    this.uploadedRecipeImage = null;
    this.isUploadedImage = false;
    this.addedIngredients = [];
    this.addedRecipeSteps = [];
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

}
