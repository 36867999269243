<div class="blogs">
    <div class="top-content">
        <div class="blog-search">
            <div class="search-bar-container">
                <input type="text" [(ngModel)]="blogSearchText" placeholder="{{'Blogs.SearchPlaceholder' | translate}}">
                <img src="../../../assets/icons/search-icon.svg" draggable="false" alt="search">
            </div>
        </div>
    </div>
    <div class="banner">
        <div class="banner-content">
            <div class="banner-content-left">
                <h1>{{'Blogs.DiscoverWorldCuisine' | translate}}</h1>
            </div>
            <div class="banner-content-right">
                <div class="track-content">
                    <p class="title">{{'Blogs.SubscriberForm.FormTitle' | translate}}</p>
                    <div class="form">
                        <form [formGroup]="subscriberForm" *ngIf="subscriberForm">
                            <div class="form-group">
                                <input type="text" id="subscriberFormName" placeholder="{{'Blogs.SubscriberForm.Name' | translate}}" autocomplete="off" formControlName="name">
                            </div>
                            <div class="form-group">
                                <input type="text" id="subscriberForEmail" placeholder="{{'Blogs.SubscriberForm.Email' | translate}}" autocomplete="off" formControlName="email">
                            </div>
                            <button class="pb-btn-default w-100 mt-2" (click)="addSubscriber()"><img class="icon-left" src="../../../assets/icons/arrow-right-white-circle-icon.svg" draggable="false" alt="right">{{'Blogs.SubscriberForm.Subscribe' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-content">
        <!-- Most Liked Blog (Start) -->
        <div class="side most-liked">
            <div class="side-top">
                <h2 class="title">{{'Blogs.MostLiked.Title' | translate}}</h2>
                <div class="left-and-rigth-buttons">
                    <img (click)="mostLiekdContentScroll(-600)" src="../../../assets/icons/arrow-left-2-pink-icon.svg" alt="left-icon" draggable="false">
                    <img (click)="mostLiekdContentScroll(600)" src="../../../assets/icons/arrow-right-2-pink-icon.svg" alt="right-icon" draggable="false">
                </div>
            </div>
            <div class="content">
                <div #mostLikedContent class="hide-scroll items">
                    <div *ngFor="let blog of blogs.items| blogSearch:blogSearchText" class="item">
                        <div (click)="navigateToBlogDetail(blog.urlName, blog.title)">
                            <h3 class="item-title">{{'DatabaseData.BlogCategories.' + blog.blogCategory?.id.toString() | translate}}</h3>
                            <div class="item-image">
                                <img src="{{HelpersFactoryService.getMediaServerUrl()}}assets/{{blog?.blogImage?.filePath}}" draggable="false" alt="item-image">
                            </div>
                            <div class="item-name-container">
                                <h4 class="item-name">{{blog?.title}}</h4>
                                <img src="../../../assets/icons/arrow-right-pink-icon.svg" draggable="false" alt="arrow-right">
                            </div>
                            <p class="item-date-and-read-minute">{{blog?.date | date:'dd.MM.yyyy'}} . {{'Blogs.AverageReadingTime' | translate}} {{blog?.durationOfReads}} {{'Global.MinuteShort' | translate}}</p>
                        </div>
                        <div class="read-and-like-count">
                            <div class="count-container">
                                <span class="count">{{blog?.numberOfReads}}</span>
                                <img src="../../../assets/icons/eye-dark-icon.svg" draggable="false" alt="eye-icon">
                            </div>
                            <div class="count-container">
                                <span class="count">{{blog?.numberOfLikes}}</span>
                                <img src="../../../assets/icons/like-icon.svg" draggable="false" alt="like-icon">
                            </div>
                            <div class="count-container">
                                <span class="count">{{blog?.numberOfDislikes}}</span>
                                <img src="../../../assets/icons/dislike-icon.svg" draggable="false" alt="dislike-icon">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Most Liked Blog (End) -->

        <!-- Latest Uploads Blog (Start) -->
        <!-- <div class="side latest-uploads">
            <h2 class="title">{{'Blogs.LatestUploads.Title' | translate}}</h2>
            <div class="content">
                <div class="items">
                    <div class="item">
                        <h3 class="item-title">Sağlıklı Tarifler</h3>
                        <div class="item-image">
                            <img src="../../../assets/blogs/healthy-recipe.png" draggable="false" alt="item-image">
                        </div>
                        <div class="item-name-container">
                            <h4 class="item-name">Eşssiz bir salata için 10 öneri</h4>
                            <img src="../../../assets/icons/arrow-right-pink-icon.svg" draggable="false" alt="arrow-right">
                        </div>
                        <p class="item-date">15.04.2023</p>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Latest Uploads Blog (End) -->
        
        <!-- All Blogs (Start) -->
        <ng-container *ngIf="blogsDataIsLoaded | async">
            <div class="all-blogs">
                <h2 class="title">{{'Blogs.All.Title' | translate}}</h2>
                <div class="content">
                    <div class="items">
                        <div *ngFor="let blog of blogs.items| blogSearch:blogSearchText" class="item">
                            <div (click)="navigateToBlogDetail(blog.urlName, blog.title)">
                                <h3 class="item-title">{{'DatabaseData.BlogCategories.' + blog.blogCategory?.id.toString() | translate}}</h3>
                                <div class="item-image">
                                    <img src="{{HelpersFactoryService.getMediaServerUrl()}}assets/{{blog.blogImage.filePath}}" draggable="false" alt="item-image">
                                </div>
                                <div class="item-name-container">
                                    <h4 class="item-name">{{blog.title}}</h4>
                                    <img src="../../../assets/icons/arrow-right-pink-icon.svg" draggable="false" alt="arrow-right">
                                </div>
                                <p class="item-date-and-read-minute">{{blog.date | date:'dd.MM.yyyy'}} . {{'Blogs.AverageReadingTime' | translate}} {{blog.durationOfReads}} {{'Global.MinuteShort' | translate}}</p>
                            </div>
                            <div class="read-and-like-count">
                                <div class="count-container">
                                    <span class="count">{{blog.numberOfReads}}</span>
                                    <img src="../../../assets/icons/eye-dark-icon.svg" draggable="false" alt="eye-icon">
                                </div>
                                <div class="count-container">
                                    <span class="count">{{blog.numberOfLikes}}</span>
                                    <img src="../../../assets/icons/like-icon.svg" draggable="false" alt="like-icon">
                                </div>
                                <div class="count-container">
                                    <span class="count">{{blog.numberOfDislikes}}</span>
                                    <img src="../../../assets/icons/dislike-icon.svg" draggable="false" alt="dislike-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="paginate-box m-t-30">
                    <app-pagination-partial [data]="blogs" (paginationOnChange)="getBlogsDetailWithoutExplanation(); updateUrl()"></app-pagination-partial>
                </div>    
            </div>
        </ng-container>
        <!-- All Blogs (End) -->
        <app-frequently-asked-questions></app-frequently-asked-questions>
        <app-contact-us class="contact-us"></app-contact-us>
    </div>
</div>

<app-start-your-recipe (startYourRecipeModalIsShowingOutput)="startYourRecipeModalIsShowingOutput($event)" [startYourRecipeModalIsShowing]="startYourRecipeModalIsShowing"></app-start-your-recipe>