<div class="contact-us">
    <h2 class="title">{{'Home.ContactUs.Title' | translate}}</h2>
    <div class="content">
        <div class="form-container">
            <div class="top-text-container">
                <p>{{'Home.ContactUs.Header' | translate}}</p>
                <p>{{'Home.ContactUs.HeaderDescription' | translate}}</p>
            </div>
            <div class="form hide-scroll">
                <form [formGroup]="contactUsForm" *ngIf="contactUsForm">
                    <div class="row">
                        <div class="col-12 form-group">
                            <input type="text" class="input" id="contactUsInputName" formControlName="name" [class.input-error]="contactUsForm.get('name').invalid && contactUsForm.get('name').touched" placeholder="{{'Home.ContactUs.Form.Name' | translate}}" autocomplete="off">
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-3 form-group dropdown">
                            <input [(ngModel)]="currentPhoneCode" id="contactUsInputNamePhoneCode" class="input dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a *ngFor="let phone of phoneCodes" (click)="setCurrentPhoneCode(phone)" class="dropdown-item" style="cursor: pointer;">{{phone}}</a>
                            </div>
                          </div> -->
                        <div class="col-12 form-group">
                          <input type="text" class="input" id="contactUsInputNamePhone" formControlName="phoneNumber" [class.input-error]="contactUsForm.get('phoneNumber').invalid && contactUsForm.get('phoneNumber').touched" maxlength="10" placeholder="{{'Home.ContactUs.Form.PhoneNumber' | translate}}" autocomplete="off">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <input type="email" class="input" id="contactUsInputEmail" formControlName="email" [class.input-error]="contactUsForm.get('email').invalid && contactUsForm.get('email').touched" placeholder="{{'Home.ContactUs.Form.Email' | translate}}" autocomplete="off">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <textarea class="input" id="contactUsInputMessage" formControlName="message" [class.input-error]="contactUsForm.get('message').invalid && contactUsForm.get('message').touched" placeholder="{{'Home.ContactUs.Form.Message' | translate}}" autocomplete="off"></textarea>
                        </div>
                    </div>
                    <div class="kvkk-text">
                        <div class="form-check">
                            <input (click)="toggleKvkkForm()" formControlName="kvkkIsChecked" id="contactUsInputKVKK" class="form-check-input" type="checkbox">
                            <p><a href="/cookies-policy" target="_blank" rel="noopener"><span>{{'Home.ContactUs.Form.KVKKOne' | translate}}</span></a>{{'Home.ContactUs.Form.KVKKTwo' | translate}}</p>
                        </div>
                    </div>
                    <button (click)="sendContactUsForm()" class="pb-btn-default btn-send-message">{{'Home.ContactUs.Form.Send' | translate}}</button>
                </form>
            </div>
        </div>
        <div class="image-container">
            <img src="../../../assets/icons/contact-us-woman-image.png" draggable="false" alt="contact-us-icon">
        </div>
    </div>
</div>