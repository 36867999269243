import { TranslateModuleService } from './translate-module.service';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrModuleService {

  constructor(private toastrService: ToastrService, private translateModuleService: TranslateModuleService) { }

  success(message?: string): void {
    this.toastrService.success(message, this.translateModuleService.translate.instant('ToastMessages.Success'));
  }

  info(message?: string): void {
    this.toastrService.info(message, this.translateModuleService.translate.instant('ToastMessages.Info'));
  }

  warning(message?: string): void {
    this.toastrService.warning(message, this.translateModuleService.translate.instant('ToastMessages.Warning'));
  }

  error(message?: string): void {
    this.toastrService.error(message, this.translateModuleService.translate.instant('ToastMessages.Error'));
  }

}
