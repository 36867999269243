<div class="ng-pagination">
  <!-- <div clickOutside (clickOutside)="numberOfDataPerPageItemsDropDownState = false" class="ng-drop-down-box">
      <div (click)="numberOfDataPerPageItemsDropDownState = !numberOfDataPerPageItemsDropDownState" class="drop-down-header">
          <p class="drop-down-header-text">{{numberOfDataPerPage}}</p>
      </div>
      <div *ngIf="numberOfDataPerPageItemsDropDownState" class="drop-down-menu">
          <div *ngFor="let numberOfDataPerPageItem of numberOfDataPerPageItems" 
                (click)="setNumberOfDataPerPageItem(numberOfDataPerPageItem)"
                class="drop-down-menu-item">
              <p class="drop-down-menu-item-text">{{numberOfDataPerPageItem}}</p>
          </div>
      </div>
  </div> -->
  <ul class="ng-pagination-ul">
    <li class="pagination-direciton-button ng-pagination-item" [class.disabled]="!data.hasPrevious">
      <a class="ng-pagination-item-link" (click)="prevPageClick()"><app-ng-image class="ng-image" src="../../../assets/icons/arrow-left-gray-icon.svg"></app-ng-image></a>
    </li>
    <li class="ng-pagination-item" [class.active]="currentPage === data"  *ngFor="let data of arrOfCurrButtons; let index = index">
      <a class="ng-pagination-item-link" (click)="setCurrentPage(data)">{{ data }}</a>
    </li>
    <li class="pagination-direciton-button ng-pagination-item" [class.disabled]="!data.hasNext">
      <a class="ng-pagination-item-link" (click)="nextPageClick()"><app-ng-image class="ng-image" src="../../../assets/icons/arrow-right-gray-icon.svg"></app-ng-image></a>
    </li>
  </ul>
</div>
