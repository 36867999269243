import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genericSearch'
})
export class GenericSearchPipe implements PipeTransform {

  transform(value: any[], property: string, searchText: string): any[] {

    searchText = searchText ? searchText.toLocaleLowerCase().trim() : '';
    
    return searchText
      ? value.filter(
          (p: any) =>
            p[property].toLocaleLowerCase().includes(searchText)
        )
      : value;
  }

}
