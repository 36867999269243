import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { UserLoginModel } from '../models/userLoginModel';
import { Observable } from 'rxjs';
import { SingleResponseModel } from '../models/singleResponseModel';
import { UserRegisterDto } from '../models/userRegisterDto';
import { ResponseModel } from '../models/responseModel';
import { Router } from '@angular/router';
import { SocialUser } from '@abacritt/angularx-social-login';
import { AuthenticatedUserModel } from '../models/auth/authenticatedUserModel';
import { HelpersFactoryService } from './helpers-factory.service';
import { UserTypeEnum } from '../enums/user/userTypeEnum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient, private helpersFactoryService: HelpersFactoryService,
    private router: Router) { }

  userLogin(userLoginModel: UserLoginModel): Observable<SingleResponseModel<AuthenticatedUserModel>>{
    let newPath = this.apiUrl + "Auths/Login";
    return this.httpClient.post<SingleResponseModel<AuthenticatedUserModel>>(newPath, userLoginModel);
  }

  loginWithGoogle(socialUser: SocialUser): Observable<SingleResponseModel<AuthenticatedUserModel>>{
    let newPath = this.apiUrl + "Auths/LoginWithGoogle";
    return this.httpClient.post<SingleResponseModel<AuthenticatedUserModel>>(newPath, socialUser);
  }

  register(model: UserRegisterDto): Observable<ResponseModel>{
    let newPath = this.apiUrl + "Auths/Register";
    return this.httpClient.post<ResponseModel>(newPath, model);
  }

  refreshAccessToken(){
    let user = this.helpersFactoryService.localStorageService.getAuthenticatedUserInformations();
    if(user !== null){
      let expiration = new Date(user.accessToken.expiration);
      let dateTime = new Date()
      if(expiration < dateTime){
        this.clearSession();
        this.helpersFactoryService.toastService.warning("Oturum süresi doldu");
      }
    }
  }

  userIsAuthenticated(): boolean{
    let user = this.helpersFactoryService.localStorageService.getAuthenticatedUserInformations();
    if(user != null && user.accessToken != null){
      return true;
    }
    return false;
  }

  adminIsAuthenticated(): boolean{
    let user = this.helpersFactoryService.localStorageService.getAuthenticatedUserInformations();
    if(user != null && user.accessToken != null && user.userBasicInformation.userType.id == UserTypeEnum.Admin){
      return true;
    }
    return false;
  }

  logout(){
    this.helpersFactoryService.localStorageService.removeAuthenticatedUserInformations();
    this.helpersFactoryService.toastService.success("Oturumunuz sonlandırıldı");
    this.helpersFactoryService.refreshPage();
  }

  loginBefore(): boolean{
    if(!this.userIsAuthenticated()){
      this.helpersFactoryService.toastService.warning("Önce giriş yapmalısınız");
      this.router.navigate(["/login"]);
      return false;
    }
    return true;
  }

  clearSession(){
    this.helpersFactoryService.localStorageService.clear();
  }
}
