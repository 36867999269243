<div class="recipes">
    <div class="blog-search">
        <div class="search-bar-container">
            <input type="text" [(ngModel)]="recipeSearchText" placeholder="{{'Recipes.SearchTextBoxPlaceholder' | translate}}">
            <img src="../../../assets/icons/search-icon.svg" draggable="false" alt="search-icon">
        </div>
    </div>
    <div class="main-content">
        <ng-container *ngIf="!userRecipesDataIsLoaded && !allRecipesDataIsLoaded">
            <div class="m-t-25">
                <app-data-loading-animation-partial></app-data-loading-animation-partial>
            </div>
        </ng-container>
        <!-- Best of Week Section (Start) -->
        <!-- <div class="section best-of-week">
            <div class="section-top">
                <h2 class="section-title">Haftanın Enleri</h2>
                <div class="next-and-previous-buttons">
                    <img class="btn-next" src="../../../assets/icons/arrow-left-2-pink-icon.svg" alt="left-icon" draggable="false">
                    <img class="btn-previous" src="../../../assets/icons/arrow-right-2-pink-icon.svg" alt="right-icon" draggable="false">
                </div>
            </div>
            <div class="content">
                <div class="items">
                    <div class="item">
                        <div class="avatar">
                            <img src="../../../assets/icons/salad-icon.png" draggable="false" alt="salad-icon">
                        </div>
                        <div class="info">
                            <p class="user-name">Burak Cantürk <span> - Master Chef</span></p>
                            <p class="recipe-and-image-count">50 <span>Yemek</span> . 20 <span>Fotoğraf</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Best of Week Section (End) -->

        <!-- Trend Recipes Section (Start) -->
        <!-- <div class="section trends">
            <div class="section-top">
                <h2 class="section-title">Trend Yemekler</h2>
                <div class="next-and-previous-buttons">
                    <img class="btn-next" src="../../../assets/icons/arrow-left-2-pink-icon.svg" alt="left-icon" draggable="false">
                    <img class="btn-previous" src="../../../assets/icons/arrow-right-2-pink-icon.svg" alt="right-icon" draggable="false">
                </div>
            </div>
            <div class="content">
                <div class="items">
                    <div class="item">
                        <div class="recipe-image">
                            <img src="../../../assets/recipe-image-demo.png" draggable="false" alt="recipe-image">
                        </div>
                        <div class="recipe-name">
                            <h2>Tavuklu Şintizel</h2>
                        </div>
                        <div class="recipe-rate">
                            <div class="star-icons">
                                <img *ngFor="let star of counter(4)" src="../../../assets/icons/star-fill-pink-icon.svg" draggable="false" alt="star">
                                <img *ngFor="let star of counter(1)" src="../../../assets/icons/star-empty-gray-icon.svg" draggable="false" alt="star">
                            </div>
                            <div class="point">
                                <p>4.0 Puan</p>
                            </div>
                        </div>
                        <div class="other-info">
                            <div class="other-info-section">
                                <img src="../../../assets/icons/time-circle-gray-icon.svg" draggable="false" alt="time-icon">
                                <span>30 Dk</span>
                            </div>
                            <div class="other-info-section">
                                <img src="../../../assets/icons/document-gray-2-icon.svg" draggable="false" alt="document-icon">
                                <span>15</span>
                            </div>
                            <div class="other-info-section">
                                <img src="../../../assets/icons/heart-gray-icon.svg" draggable="false" alt="heart-icon">
                                <span>10</span>
                            </div>
                            <div class="other-info-section">
                                <img src="../../../assets/icons/chat-gray-2-icon.svg" draggable="false" alt="chat-icon">
                                <span>3</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Trend Recipes Section (End) -->

        <!-- Coming From You Section (Start) -->
        <ng-container *ngIf="userRecipesDataIsLoaded | async">
            <div *ngIf="userRecipes.length > 0" class="section coming-from-you">
                <div class="section-top">
                    <h2 class="section-title">{{'Recipes.IncomingFromYours.Title' | translate}}</h2>
                    <div class="next-and-previous-buttons">
                        <img (click)="comingFromYouContentScroll(-500)" class="btn-next" src="../../../assets/icons/arrow-left-2-pink-icon.svg" alt="left-icon" draggable="false">
                        <img (click)="comingFromYouContentScroll(500)" class="btn-previous" src="../../../assets/icons/arrow-right-2-pink-icon.svg" alt="right-icon" draggable="false">
                    </div>
                </div>
                <div class="content">
                    <div #comingFromYouContent class="hide-scroll items">
                        <div *ngFor="let userRecipe of userRecipes | genericSearch:'recipeName':recipeSearchText" class="item">
                            <div (click)="redirectRecipeDetail(userRecipe)">
                                <div class="recipe-image-container">
                                    <div class="recipe-type">
                                        <p>{{'Recipes.IncomingFromYours.IncomingFromYour' | translate}}</p>
                                    </div>
                                    <div class="recipe-owner">
                                        <div class="user-avatar">
                                            <img src="../../../assets//icons/user.png">
                                        </div>
                                        <div class="user-name">
                                            <p class="user-name-text">{{userRecipe?.user.firstName}} {{userRecipe?.user.lastName}}</p>
                                            <p class="user-name-info-text">({{'Recipes.RecipeOwner' | translate}})</p>
                                        </div>
                                    </div>
                                    <img class="recipe-image" src="{{HelpersFactoryService.getMediaServerUrl()}}assets/{{userRecipe.recipeImage?.filePath}}" draggable="false" alt="recipe-image">
                                </div>
                                <div class="recipe-name">
                                    <h2>{{userRecipe?.recipeName}}</h2>
                                </div>
                            </div>
                            <div class="recipe-rate">
                                <div class="star-icons">
                                    <img *ngFor="let star of counter(5)" src="../../../assets/icons/star-fill-pink-icon.svg" draggable="false" alt="star">
                                    <img *ngFor="let star of counter(0)" src="../../../assets/icons/star-empty-gray-icon.svg" draggable="false" alt="star">
                                </div>
                                <div class="point">
                                    <p>5.0 {{'Global.Point' | translate}}</p>
                                </div>
                            </div>
                            <div class="other-info">
                                <div class="other-info-section">
                                    <img src="../../../assets/icons/time-circle-gray-icon.svg" draggable="false" alt="time-icon">
                                    <span>{{userRecipe?.averageCookingTime}} {{'Global.Minute' | translate}}</span>
                                </div>
                                <div class="other-info-section">
                                    <img src="../../../assets/icons/document-gray-2-icon.svg" draggable="false" alt="document-icon">
                                    <span>{{userRecipe?.recipeBookmarkCount}}</span>
                                </div>
                                <!-- <div class="other-info-section">
                                    <img src="../../../assets/icons/heart-gray-icon.svg" draggable="false" alt="heart-icon">
                                    <span>10</span>
                                </div> -->
                                <div class="other-info-section">
                                    <img src="../../../assets/icons/chat-gray-2-icon.svg" draggable="false" alt="chat-icon">
                                    <span>{{userRecipe?.commentCount}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Coming From You Section (End) -->
        </ng-container>

        <ng-container *ngIf="allRecipesDataIsLoaded | async">
            <div *ngIf="allRecipesBasicData.length > 0" class="section all-recipes">
                <div class="section-top">
                    <h2 class="section-title">{{'Recipes.AllRecipes' | translate}}</h2>
                </div>
                <div class="content">
                    <div class="items">
                        <div *ngFor="let recipe of allRecipesBasicData | genericSearch:'recipeName':recipeSearchText" class="item">
                            <div (click)="redirectRecipeDetail(recipe)" class="all-recipe-recipe-image-container">
                                <div class="recipe-image">
                                    <div *ngIf="recipe.recipeImage != null" class="image-uploaded">
                                        <img src="{{HelpersFactoryService.getMediaServerUrl()}}assets/{{recipe.recipeImage?.filePath}}" draggable="false" alt="recipe-image">
                                    </div>
                                    <div *ngIf="recipe.recipeImage == null" class="image-not-uploaded">
                                        <img src="../../../assets/icons/image-cartoon-icon.svg" draggable="false" alt="image-icon">
                                        <p>{{'Recipes.NoPhotoUploadedYet' | translate}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="all-recipe-recipe-info-container">
                                <div (click)="redirectRecipeDetail(recipe)" class="recipe-name">
                                    <h2>{{recipe?.recipeName}}</h2>
                                </div>
                                <div class="recipe-rate">
                                    <div class="star-icons">
                                        <img *ngFor="let star of counter(5)" src="../../../assets/icons/star-fill-pink-icon.svg" draggable="false" alt="star">
                                        <!-- <img *ngFor="let star of counter(1)" src="../../../assets/icons/star-empty-gray-icon.svg" draggable="false" alt="star"> -->
                                    </div>
                                    <div class="point">
                                        <p>5.0 {{'Global.Point' | translate}}</p>
                                    </div>
                                </div>
                                <div *ngIf="recipe?.recipeType?.id == recipeTypeEnum.UserRecipe" class="recipe-type">
                                    <p>{{'Recipes.IncomingFromYours.IncomingFromYour' | translate}}</p>
                                </div>
                                <div class="other-info">
                                    <div class="other-info-section">
                                        <img src="../../../assets/icons/time-circle-gray-icon.svg" draggable="false" alt="time-icon">
                                        <span>{{recipe?.averageCookingTime}} {{'Global.Minute' | translate}}</span>
                                    </div>
                                    <div class="other-info-section">
                                        <img src="../../../assets/icons/document-gray-2-icon.svg" draggable="false" alt="document-icon">
                                        <span>{{recipe?.recipeBookmarkCount}}</span>
                                    </div>
                                    <div class="other-info-section">
                                        <img src="../../../assets/icons/chat-gray-2-icon.svg" draggable="false" alt="chat-icon">
                                        <span>{{recipe?.commentCount}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="all-recipe-recipe-owner">
                                <div class="user-avatar">
                                    <img src="../../../assets//icons/user.png">
                                </div>
                                <div class="user-name">
                                    <p class="user-name-text">{{recipe.user?.firstName}} {{recipe.user?.lastName}}</p>
                                    <p class="user-name-info-text">({{'Recipes.RecipeOwner' | translate}})</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-see-more-recipe-container">
                    <button (click)="seeMoreRecipe()" class="pb-btn-outlined w-100">{{'Recipes.SeeMoreRecipe' | translate}} <img class="icon-right" src="../../../assets/icons/arrow-down-pink-icon.svg" draggable="false" alt="down-icon"></button>
                </div>
            </div>
        </ng-container>

        <div *ngIf="userRecipes.length <= 0 && allRecipesBasicData.length <= 0 && userRecipesDataIsLoaded && allRecipesDataIsLoaded" class="recipe-not-found">
            <img src="../../../assets/icons/warning-yellow-icon.png" draggable="false" alt="warning">
            <p>Herhangi bir kayıt bulunamadı !</p>
        </div>

    </div>
</div>