<div class="main">
    <div class="banner">
        <div class="banner-content">
            <h1 class="title">{{'HowToUse.Title' | translate}}</h1>
            <div class="description">
                <p>{{'HowToUse.Description' | translate}}</p>
            </div>
            <button (click)="tourVideoIsShowingOutput(true)" class="btn-how-to-use pb-btn-default"><img class="icon-left" src="../../../assets/icons/play-white-icon.svg" draggable="false" alt="play-icon">{{'Global.WatchTheVideo' | translate}}</button>
        </div>
    </div>
    <div class="steps">
        <div class="steps">
            <div class="step">
                <div class="step-content">
                    <div class="step-number">
                        <p class="step-number-text">{{'HowToUse.Step' | translate}} 1</p>
                    </div>
                    <div class="content">
                        <img src="../../../assets/howToUsePageImages/image-1.png" draggable="false" alt="image">
                    </div>
                </div>
            </div>

            <div class="step">
                <div class="step-content">
                    <div class="step-number">
                        <p class="step-number-text">{{'HowToUse.Step' | translate}} 2</p>
                    </div>
                    <div class="content">
                        <img src="../../../assets/howToUsePageImages/image-2.png" draggable="false" alt="image">
                    </div>
                </div>
            </div>

            <div class="step">
                <div class="step-content">
                    <div class="step-number">
                        <p class="step-number-text">{{'HowToUse.Step' | translate}} 3</p>
                    </div>
                    <div class="content">
                        <img src="../../../assets/howToUsePageImages/image-3.png" draggable="false" alt="image">
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="general-features">
        <h2>{{'HowToUse.GeneralFeatures.Title' | translate}}</h2>
        <div class="features">
            <div class="feature-group">
                <div class="feature feature-left">
                    <div class="icon">
                        <img src="../../../assets/howToUsePageImages/pan.svg" draggable="false" alt="icon">
                    </div>
                    <div class="feature-text">
                        <p>{{'HowToUse.GeneralFeatures.FeatureOne' | translate}}</p>
                    </div>
                </div>
                <div class="feature feature-right">
                    <div class="icon">
                        <img src="../../../assets/howToUsePageImages/paper.svg" draggable="false" alt="icon">
                    </div>
                    <div class="feature-text">
                        <p>{{'HowToUse.GeneralFeatures.FeatureTwo' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="feature-group">
                <div class="feature feature-left">
                    <div class="icon">
                        <img src="../../../assets/howToUsePageImages/list.svg" draggable="false" alt="icon">
                    </div>
                    <div class="feature-text">
                        <p>{{'HowToUse.GeneralFeatures.FeatureTheree' | translate}}</p>
                    </div>
                </div>
                <div class="feature feature-right">
                    <div class="icon">
                        <img src="../../../assets/howToUsePageImages/image.svg" draggable="false" alt="icon">
                    </div>
                    <div class="feature-text">
                        <p>{{'HowToUse.GeneralFeatures.FeatureFour' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="feature-group">
                <div class="feature feature-left">
                    <div class="icon">
                        <img src="../../../assets/howToUsePageImages/heart.svg" draggable="false" alt="icon">
                    </div>
                    <div class="feature-text">
                        <p>{{'HowToUse.GeneralFeatures.FeatureFive' | translate}}</p>
                    </div>
                </div>
                <div class="feature feature-right">
                    <div class="icon">
                        <img src="../../../assets/howToUsePageImages/add.svg" draggable="false" alt="icon">
                    </div>
                    <div class="feature-text">
                        <p>{{'HowToUse.GeneralFeatures.FeatureSix' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="partial-components">
        <app-frequently-asked-questions></app-frequently-asked-questions>
    </div>

</div>

<app-how-to-use-video (tourVideoIsShowingOutput)="tourVideoIsShowingOutput($event)" [tourVideoIsShowing]="tourVideoIsShowing"></app-how-to-use-video>