import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Messages } from '../constants/messages';
import { RouterName } from '../constants/routerName';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard  {
  constructor(private toastrService: ToastrService, private router: Router, private authService: AuthService){ }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.authService.userIsAuthenticated()){
      return true
    }
    else{
      this.toastrService.warning("Önce giriş yapmalısınız", Messages.warning);
      this.router.navigate([RouterName.layout.emptyLayout.login]);
      return false;
    }
  }
  
}
