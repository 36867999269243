import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RecipeBook } from '../models/recipeBook';
import { ResponseModel } from '../models/responseModel';
import { ListResponseModel } from '../models/listResponseModel';
import { RecipeBookBasicDto } from '../models/dtos/recipeBook/recipeBookBasicDto';

@Injectable({
  providedIn: 'root'
})
export class RecipeBookService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  add(recipe: RecipeBook): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}RecipeBooks/Add`;
    return this.httpClient.post<ResponseModel>(newPath, recipe);
  }

  getAll(): Observable<ListResponseModel<RecipeBook>>{
    let newPath = `${this.apiUrl}RecipeBooks/GetAll`;
    return this.httpClient.get<ListResponseModel<RecipeBook>>(newPath);
  }

  getRecipeBooksBasicByUserId(userId: any): Observable<ListResponseModel<RecipeBookBasicDto>>{
    let newPath = `${this.apiUrl}RecipeBooks/GetRecipeBooksBasicByUserId?userId=${userId}`;
    return this.httpClient.get<ListResponseModel<RecipeBookBasicDto>>(newPath);
  }

  checkIfRecipeBookIsAdded(userId: any, recipeId: any): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}RecipeBooks/CheckIfRecipeBookIsAdded?userId=${userId}&recipeId=${recipeId}`;
    return this.httpClient.get<ResponseModel>(newPath);
  }
}
