import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ng-image',
  templateUrl: './ng-image.component.html',
  styleUrls: ['./ng-image.component.css']
})
export class NgImageComponent {

  @Input() src: string;

  getImageName(){
    let splitedData = this.src.split("/");
    return splitedData[splitedData.length - 1];
  }

}
