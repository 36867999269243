import { AuthenticatedUserModel } from '../models/auth/authenticatedUserModel';
import { ClientGeoLocationModel } from '../models/common/clientGeoLocationModel';
import { TokenModel } from '../models/tokenModel';
import { UserBasicInformation } from '../models/user/userBasicInformation';
import { EncryptionService } from './encryption.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  
  constructor(private encryptionService: EncryptionService) { }

  setAuthenticatedUserInformations(userInformation: AuthenticatedUserModel){
    this.removeItem("user");
    this.setObjectData("user", userInformation);
  }

  getAuthenticatedUserInformations(): AuthenticatedUserModel | null{
    return this.getObjectData("user") as AuthenticatedUserModel;
  }

  updateAuthenticatedUserInformations(userBasicInformation?: UserBasicInformation, accessToken?: TokenModel){
    let user = this.getAuthenticatedUserInformations();
    user.userBasicInformation = userBasicInformation ?? user.userBasicInformation;
    user.accessToken = accessToken ?? user.accessToken;
    this.setAuthenticatedUserInformations(user);
  }

  removeAuthenticatedUserInformations(){
    this.removeItem("user");
  }


  setClientGeoLocation(clientGeoLocation: ClientGeoLocationModel){
    this.setObjectData("clientGeoLocation", clientGeoLocation);
  }

  getClientGeoLocation(): ClientGeoLocationModel | null{
    return this.getObjectData("clientGeoLocation") as ClientGeoLocationModel;
  }

  getClientIp(): string{
    return this.getClientGeoLocation().ip;
  }

  setObjectData(itemName: string, data: any){
    let encryptedData = this.encryptionService.encrypt(JSON.stringify(data));
    localStorage.setItem(itemName, JSON.stringify(encryptedData))
  }

  getObjectData(itemName: string){
    let data = localStorage.getItem(itemName)
    if(data){
      let decryptedData = this.encryptionService.decrypt(JSON.parse(data));
      return JSON.parse(decryptedData);
    }
    return null;
  }

  setItem(itemName: string, data: any){
    let encryptedData = this.encryptionService.encrypt(data);
    localStorage.setItem(itemName, encryptedData);
  }

  getItem(itemName: string){
    let data = localStorage.getItem(itemName)
    if(data){
      let decryptedData = this.encryptionService.decrypt(data);
      return decryptedData;
    }
    return null;
  }

  removeItem(item: any){
    localStorage.removeItem(item);
  }

  clear(){
    localStorage.clear();
  }

  getAuthenticatedUserId(){
    return this.getAuthenticatedUserInformations()?.userBasicInformation.id;
  }

  getAuthenticatedUserEmail(){
    return this.getAuthenticatedUserInformations()?.userBasicInformation.email;
  }

  clearLocalStorage(){
    localStorage.clear();
  }
  
}
