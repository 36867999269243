import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SingleResponseModel } from '../models/singleResponseModel';
import { SystemRecipeAddDto } from '../models/systemRecipeAddDto';
import { SystemRecipe } from '../models/systemRecipe';
import { ListResponseModel } from '../models/listResponseModel';
import { RecipeBasicDto } from '../models/recipeBasicDto';
import { RecipeDetailDto } from '../models/recipeDetailDto';
import { UserRecipeStatisticDto } from '../models/dtos/userStatistic/userRecipeStatisticDto';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  add(recipe: SystemRecipeAddDto): Observable<SingleResponseModel<SystemRecipe>>{
    let newPath = `${this.apiUrl}Recipes/Add`;
    return this.httpClient.post<SingleResponseModel<SystemRecipe>>(newPath, recipe);
  }

  getRecipesBasicData(itemCount?: number): Observable<ListResponseModel<RecipeBasicDto>>{
    let newPath = `${this.apiUrl}Recipes/GetRecipesBasicData?itemCount=${itemCount}`;
    return this.httpClient.get<ListResponseModel<RecipeBasicDto>>(newPath);
  }

  getRecipeDetailById(id: any): Observable<SingleResponseModel<RecipeDetailDto>>{
    let newPath = `${this.apiUrl}Recipes/GetRecipeDetailById?id=${id}`;
    return this.httpClient.get<SingleResponseModel<RecipeDetailDto>>(newPath);
  }

  getUserRecipeStatisticsByUserId(userId: any): Observable<SingleResponseModel<UserRecipeStatisticDto>>{
    let newPath = `${this.apiUrl}Recipes/GetUserRecipeStatisticsByUserId?userId=${userId}`;
    return this.httpClient.get<SingleResponseModel<UserRecipeStatisticDto>>(newPath);
  }
}
