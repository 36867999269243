import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ng-rich-text-viewer',
  templateUrl: './ng-rich-text-viewer.component.html',
  styleUrls: ['./ng-rich-text-viewer.component.css']
})
export class NgRichTextViewerComponent {

  @Input() content: any;

}
