<div class="profile-main">
    <div class="section left-section">
        <div class="side">
            <div class="content">
                <div class="user-info">
                    <div class="profile-image">
                        <app-ng-image class="ng-image" src="../../../assets/icons/user.png"></app-ng-image>
                    </div>
                    <div class="user-name">
                        <p>{{userBasicInformation?.fullName | uppercase}}</p>
                    </div>
                    <!-- <div class="rank">
                        <p>Master Chef</p>
                    </div> -->
                    <div class="user-statistics">
                        <div class="statistic point">
                            <p class="value">{{userRecipeStatistics?.totalPoint}}</p>
                            <p class="text">{{'Global.Point' | translate}}</p>
                        </div>
                        <div class="statistic followers">
                            <p class="value">{{userFollowersStatistic?.followers}}</p>
                            <p class="text">{{'Profile.Followers' | translate}}</p>
                        </div>
                        <div class="statistic following">
                            <p class="value">{{userFollowersStatistic?.following}}</p>
                            <p class="text">{{'Profile.Follow' | translate}}</p>
                        </div>
                    </div>
                </div>
                <div class="splite"></div>
                <div class="menu-items">
                    <div class="main-items">
                        <div *ngFor="let menuItem of profileMenuItems" (click)="setCurrentProfileMenuItem(menuItem.enumItem)" class="item">
                            <div class="icon">
                                <app-ng-image src="../../../assets/icons/{{getProfileMenuItemIcon(menuItem.iconName, menuItem.enumItem)}}"></app-ng-image>
                            </div>
                            <span [ngStyle]="{'color': currentProfileMenuItem == menuItem.enumItem ? '#FE0045':'#616571'}">{{'Profile.Menu.' + menuItem.languageName.toString() + '.Title' | translate}}</span>
                        </div>
                    </div>
                    <div class="bottom-items">
                        <div (click)="setCurrentProfileMenuItem(ProfileMenuItemEnums.AccountSettings)" class="item">
                            <div class="icon">
                                <app-ng-image src="../../../assets/icons/{{getProfileMenuItemIcon('setting', ProfileMenuItemEnums.AccountSettings)}}"></app-ng-image>
                            </div>
                            <span [ngStyle]="{'color': currentProfileMenuItem == ProfileMenuItemEnums.AccountSettings ? '#FE0045':'#616571'}">{{'Profile.Menu.AccountSettings.Title' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section right-section">
        <!-- Profile Tab (Start) -->
        <div *ngIf="currentProfileMenuItem == ProfileMenuItemEnums.Profile" class="profile-tab">
            <div class="my-profile side">
                <p class="side-title">{{'Profile.Menu.Profile.Title' | translate}}</p>
                <div class="content">
                    <p><span class="text">{{'Profile.Menu.Profile.TotalRecipeIGot' | translate}}</span> <span class="value">{{userRecipeStatistics?.totalRecipeReceivedCount}}</span></p>
                    <!-- <p><span class="text">{{'Profile.Menu.Profile.MealsIMade' | translate}}</span> <span class="value">{{userRecipeStatistics?.mealsICookCount}}</span></p>
                    <p><span class="text">{{'Profile.Menu.Profile.RecipeIUploadedPhotos' | translate}}</span> <span class="value">{{userRecipeStatistics?.dishesUploadedPhotographsCount}}</span></p> -->
                    <p><span class="text">{{'Profile.Menu.Profile.RecipesIPosted' | translate}}</span> <span class="value">{{userRecipeStatistics?.recipesSentCount}}</span></p>
                    <p><span class="text">{{'Profile.Menu.Profile.Comments' | translate}}</span> <span class="value">{{userRecipeStatistics?.totalCommentsCount}}</span></p>
                </div>
            </div>
            <!-- <div class="side">
                <p class="side-title">Rütbem</p>
                <div class="content">
                    
                </div>
            </div> -->
        </div>
        <!-- Profile Tab (End) -->

        <!-- Maked Recipes Tab (Start) -->
        <div *ngIf="currentProfileMenuItem == ProfileMenuItemEnums.MakedRecipes" class="common-tab maked-recipes-tab">
            <div class="side">
                <p class="side-title">{{'Profile.Menu.RecipesIMade.Title' | translate}}</p>
                <div class="content">
                    <div *ngFor="let item of generateArrayForMoqData(0)" class="recipe">
                        <div class="recipe-info">
                            <div class="image">
                                <img src="../../../assets/recipeImages/recipe.svg" draggable="false" alt="recipe-image">
                            </div>
                            <div class="recipe-name">
                                <p>Tavuklu Sebzeli Fırın Pilavı</p>
                                <div class="statistics">
                                    <div class="statistic">
                                        <img src="../../../assets/icons/document-gray-2-icon.svg" draggable="false" alt="document-icon">
                                        <span>0</span>
                                    </div>
                                    <div class="statistic">
                                        <img src="../../../assets/icons/chat-gray-2-icon.svg" draggable="false" alt="chat-icon">
                                        <span>0</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="redirect-recipe">
                            <button class="pb-btn-outlined">Tarife Git</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Maked Recipes Tab (End) -->

        <!-- Sent Recipes Tab (Start) -->
        <div *ngIf="currentProfileMenuItem == ProfileMenuItemEnums.SentRecipes" class="common-tab sent-recipes-tab">
            <div class="side">
                <p class="side-title">{{'Profile.Menu.RecipesISent.Title' | translate}}</p>
                <div class="content">
                    <div *ngFor="let userSentRecipe of userSentRecipes" class="recipe">
                        <div class="recipe-info">
                            <div class="image">
                                <app-ng-image class="ng-image" [src]="HelpersFactoryService.getMediaFromMediaServer(userSentRecipe.recipeImage.filePath)"></app-ng-image>
                            </div>
                            <div class="recipe-name">
                                <p>{{userSentRecipe.recipeName}}</p>
                                <div class="statistics">
                                    <div class="statistic">
                                        <img src="../../../assets/icons/document-gray-2-icon.svg" draggable="false" alt="document-icon">
                                        <span>{{userSentRecipe.recipeBookmarkCount}}</span>
                                    </div>
                                    <div class="statistic">
                                        <img src="../../../assets/icons/chat-gray-2-icon.svg" draggable="false" alt="chat-icon">
                                        <span>{{userSentRecipe.commentCount}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="redirect-recipe">
                            <button (click)="redirectRecipeDetail(userSentRecipe)" class="pb-btn-outlined">{{'Global.GoToRecipe' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Sent Recipes Tab (End) -->

        <!-- Recipe Book Tab (Start) -->
        <div *ngIf="currentProfileMenuItem == ProfileMenuItemEnums.RecipeBook" class="common-tab recipe-book-tab">
            <div class="side">
                <p class="side-title">{{'Profile.Menu.RecipeBook.Title' | translate}}</p>
                <div class="content">
                    <div *ngFor="let userRecipeBook of userRecipeBooks" class="recipe">
                        <div class="recipe-info">
                            <div class="image">
                                <app-ng-image *ngIf="userRecipeBook.recipe.recipeImage" class="ng-image" [src]="HelpersFactoryService.getMediaFromMediaServer(userRecipeBook.recipe.recipeImage.filePath)"></app-ng-image>
                            </div>
                            <div class="recipe-name">
                                <p>{{userRecipeBook.recipe.recipeName}}</p>
                                <div class="statistics">
                                    <div class="statistic">
                                        <img src="../../../assets/icons/document-gray-2-icon.svg" draggable="false" alt="document-icon">
                                        <span>{{userRecipeBook.recipe.recipeBookmarkCount}}</span>
                                    </div>
                                    <div class="statistic">
                                        <img src="../../../assets/icons/chat-gray-2-icon.svg" draggable="false" alt="chat-icon">
                                        <span>{{userRecipeBook.recipe.commentCount}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="redirect-recipe">
                            <button (click)="redirectRecipeDetail(userRecipeBook.recipe)" class="pb-btn-outlined">{{'Global.GoToRecipe' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Recipe Book Tab Tab (End) -->

        <!-- Comments Tab (Start) -->
        <div *ngIf="currentProfileMenuItem == ProfileMenuItemEnums.Comments" class="comments-tab">
            <div class="side">
                <p class="side-title">{{'Profile.Menu.MyComments.Title' | translate}}</p>
                <div class="content">
                    <div class="comments">
                        <div *ngFor="let userRecipeComment of userRecipeComments" class="comment">
                            <div class="top">
                                <div class="recipe-name">
                                    <p><span>{{'Global.FoodName' | translate}}: </span> {{userRecipeComment.recipe.recipeName}}</p>
                                </div>
                                <div (click)="redirectRecipeDetail(userRecipeComment.recipe)" class="redirect-recipe">
                                    <span>{{'Global.GoToRecipe' | translate}}</span>
                                    <img src="../../../assets/icons/arrow-right-pink-circle-icon.svg">
                                </div>
                            </div>
                            <div class="comment-content">
                                <p>{{userRecipeComment.comment}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Comments Tab (End) -->

        <!-- Drafts Tab (Start) -->
        <div *ngIf="currentProfileMenuItem == ProfileMenuItemEnums.Drafts" class="common-tab drafts-tab">
            <div class="side">
                <p class="side-title">{{'Profile.Menu.Drafts.Title' | translate}}</p>
                <div class="content">
                    <div *ngFor="let item of generateArrayForMoqData(0)" class="recipe">
                        <div class="recipe-info">
                            <div class="image">
                                <!-- <img src="" draggable="false" alt="recipe-image"> -->
                            </div>
                            <div class="recipe-name">
                                <p class="mt-4">Tavuklu Sebzeli Fırın Pilavı</p>
                            </div>
                        </div>
                        <div class="redirect-recipe">
                            <button class="pb-btn-outlined">Tarife Git</button>
                            <img style="cursor: pointer; margin-left: 20px;" src="../../../assets/icons/trash-pink-fill-icon.svg" draggable="false" alt="trash">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Drafts Tab (End) -->

        <!-- Points Tab (Start) -->
        <div *ngIf="currentProfileMenuItem == ProfileMenuItemEnums.PointSystem" class="points-tab">
            <div class="point-systems side">
                <p class="side-title">{{'Profile.Menu.PointSystem.Title' | translate}}</p>
                <div class="content">
                    <!-- <p><span class="text">{{'Profile.Menu.Profile.MealsIMade' | translate}}</span> <span class="value">{{PointSystem.MealsIMade}} {{'Global.Point' | translate}}</span></p> -->
                    <p><span class="text">{{'Profile.Menu.Profile.Comments' | translate}}</span> <span class="value">{{PointSystem.Comments}} {{'Global.Point' | translate}}</span></p>
                    <!-- <p><span class="text">{{'Profile.Menu.Profile.RecipeIUploadedPhotos' | translate}}</span> <span class="value">{{PointSystem.RecipeIUploadedPhotos}}</span></p> -->
                    <p><span class="text">{{'Profile.Menu.Profile.RecipesIPosted' | translate}}</span> <span class="value">{{PointSystem.RecipeSent}} {{'Global.Point' | translate}}</span></p>
                </div>
            </div>
        </div>
        <!-- Points Tab (End) -->

        <!-- Account Settings Tab (Start) -->
        <div *ngIf="currentProfileMenuItem == ProfileMenuItemEnums.AccountSettings" class="account-settings-tab">
            <div class="point-systems side">
                <p class="side-title">{{'Profile.Menu.AccountSettings.Title' | translate}}</p>
                <div class="content">
                    <div class="account-">
                        <form [formGroup]='userInformationForm' *ngIf="userInformationForm">
                            <div class="user-information-form">
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <div class="pb-input-box">
                                            <input type="text" id="userInformationFormFirstName" formControlName="firstName" required="required" [class.input-error]="userInformationForm.get('firstName').invalid && userInformationForm.get('firstName').touched">
                                            <span>{{'Global.FormItems.FirstName' | translate}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <div class="pb-input-box">
                                            <input type="text" id="userInformationFormLastName" formControlName="lastName" required="required" [class.input-error]="userInformationForm.get('lastName').invalid && userInformationForm.get('lastName').touched">
                                            <span>{{'Global.FormItems.LastName' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="pb-input-box">
                                        <input type="email" id="email" autocomplete="off" formControlName="email" required="required" [class.input-error]="userInformationForm.get('email').invalid && userInformationForm.get('email').touched">
                                        <span>{{'Global.FormItems.Email' | translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <app-ng-button (click)="updateBasicInformation()"  [isAction]="updateBasiInformationButtonAction" classValue="pb-btn-default w-100" text="{{'Global.SaveChanges' | translate}}"></app-ng-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Account Settings Tab (End) -->

    </div>
</div>