import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-start-your-recipe',
  templateUrl: './start-your-recipe.component.html',
  styleUrls: ['./start-your-recipe.component.css']
})
export class StartYourRecipeComponent implements OnInit {

  @Input() startYourRecipeModalIsShowing: boolean = false;
  @Output() startYourRecipeModalIsShowingOutput = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  closeModal(){
    this.startYourRecipeModalIsShowing = false;
    this.startYourRecipeModalIsShowingOutput.emit(false);
  }
}
