import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UploadFile } from '../models/uploadFile/uploadFile';

@Injectable({
  providedIn: 'root'
})

export class UploadFileHelperService {

  constructor(private sanitizer: DomSanitizer) { }

  onFileSelect(event:any, file: UploadFile) {
    if (event.target.files.length > 0) {
      const fileTemp = event.target.files[0];
      file.uploadedFile = fileTemp;
      file.isUploadFile = true;
    }
  }

  onFileSelectCollection(event: any, files: UploadFile[]){
    if (event.target.files.length > 0) {
      const fileTemp = event.target.files[0];
      let file = new UploadFile(fileTemp, true);
      files.push(file);
    }
  }

  getUploadedImage(file: UploadFile): SafeUrl{
    let image: string | SafeUrl = "";
    if(file && file.uploadedFile){
      image = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file.uploadedFile)
      );
    }
    return image;
  }

}
