import { ResponseModel } from './../models/responseModel';
import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { BlogCategory } from '../models/blogCategory';
import { Observable } from 'rxjs';
import { SingleResponseModel } from '../models/singleResponseModel';
import { ListResponseModel } from '../models/listResponseModel';

@Injectable({
  providedIn: 'root'
})
export class BlogCategoryService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  add(blogCategory: BlogCategory): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}BlogCategories/Add`;
    return this.httpClient.post<ResponseModel>(newPath, blogCategory);
  }

  getAll(): Observable<ListResponseModel<BlogCategory>>{
    let newPath = `${this.apiUrl}BlogCategories/GetAll`;
    return this.httpClient.get<ListResponseModel<BlogCategory>>(newPath);
  }

  getById(id: number): Observable<SingleResponseModel<BlogCategory>>{
    let newPath = `${this.apiUrl}BlogCategories/GetById?id=${id}`;
    return this.httpClient.get<SingleResponseModel<BlogCategory>>(newPath);
  }
}
