<div class="main">
    <div class="main-content">
        <!-- Section One (Start) -->
        <div class="section main-content-section-one">
            <div class="left">
                <h1>{{'LandingPage.SectionOne.Title' | translate}}</h1>
                <p>{{'LandingPage.SectionOne.Description' | translate}}</p>
                <div class="buttons">
                    <button class="pb-btn-default mr-3" routerLink="/recipe-engine">{{'LandingPage.ProduceARecipe' | translate}}</button>
                    <button class="pb-btn-outlined" (click)="tourVideoIsShowing = true"><img class="icon-left" src="../../../assets/icons/play-pink-icon.svg" draggable="false" alt="play-icon"> {{'LandingPage.HowToUse' | translate}}</button>
                </div>
            </div>
            <div class="right">
                <img class="section-main-image" [src]="getSectionImage('one')" draggable="false" alt="recipe">
            </div>
        </div>
        <!-- Section One (End) -->

        <!-- Section Two (Start) -->
        <div class="section main-content-section-two">
            <h2>{{'LandingPage.SectionTwo.Title' | translate}}</h2>
            <p class="description description-1">{{'LandingPage.SectionTwo.DescriptionOne' | translate}}</p>
            <p class="description description-2">{{'LandingPage.SectionTwo.DescriptionTwo' | translate}}</p>
            <div class="cards">
                <div *ngFor="let item of assistanPromotionCardItems; let i = index" class="card">
                    <img class="card-icon" src="../../../assets/icons/{{item.icon}}" draggable="false" alt="free-ticket-icon">
                    <h3 class="card-title">{{'LandingPage.SectionTwo.Cards.Card-' + i.toString() + '.Title'  | translate}}</h3>
                    <h4 class="card-sub-title">{{'LandingPage.SectionTwo.Cards.Card-' + i.toString() + '.SubTitle'  | translate}}</h4>
                    <p class="text">{{'LandingPage.SectionTwo.Cards.Card-' + i.toString() + '.Description'  | translate}}</p>
                </div>
            </div>
        </div>
        <!-- Section Two (End) -->

        <!-- Section Theree (Start) -->
        <div class="section common-section-type-one main-content-section-theree">
            <div class="left">
                <h2>{{'LandingPage.SectionTheree.Title' | translate}}</h2>
                <div class="items">
                    <div class="item">
                        <img class="bean-icon" src="../../../assets/icons/bean-pink-icon.svg" draggable="false" alt="bean-icon">
                        <h3 class="item-text">{{'LandingPage.SectionTheree.Item-0' | translate}}</h3>
                    </div>
                    <div class="item">
                        <img class="bean-icon" src="../../../assets/icons/bean-pink-icon.svg" draggable="false" alt="bean-icon">
                        <h3 class="item-text">{{'LandingPage.SectionTheree.Item-1' | translate}}</h3>
                    </div>
                </div>
                <button class="pb-btn-default mt-4" routerLink="/recipe-engine">{{'Global.TryNow' | translate}}</button>
            </div>
            <div class="right">
                <img class="section-main-image" [src]="getSectionImage('theree')" draggable="false" alt="recipe">
            </div>
        </div>
        <!-- Section Theree (End) -->


        <!-- Section Four (Start) -->
        <div class="section common-section-type-two main-content-section-four">
            <div class="left">
                <img class="section-main-image" [src]="getSectionImage('four')" draggable="false" alt="recipe">
            </div>
            <div class="right">
                <h2>{{'LandingPage.SectionFour.Title' | translate}}</h2>
                <div class="items">
                    <div class="item">
                        <img class="bean-icon" src="../../../assets/icons/bean-pink-icon.svg" draggable="false" alt="bean-icon">
                        <h3 class="item-text">{{'LandingPage.SectionFour.Item-0' | translate}}</h3>
                    </div>
                    <div class="item">
                        <img class="bean-icon" src="../../../assets/icons/bean-pink-icon.svg" draggable="false" alt="bean-icon">
                        <h3 class="item-text">{{'LandingPage.SectionFour.Item-1' | translate}}</h3>
                    </div>
                    <div class="item">
                        <img class="bean-icon" src="../../../assets/icons/bean-pink-icon.svg" draggable="false" alt="bean-icon">
                        <h3 class="item-text">{{'LandingPage.SectionFour.Item-2' | translate}}</h3>
                    </div>
                </div>
                <button class="pb-btn-default mt-4" routerLink="/recipe-engine">{{'Global.TryNow' | translate}}</button>
            </div>
        </div>
        <!-- Section Four (End) -->

        <!-- Section Five (Start) -->
        <div class="section common-section-type-one main-content-section-five">
            <div class="left">
                <h2>{{'LandingPage.SectionFive.Title' | translate}}</h2>
                <div class="items">
                    <div class="item">
                        <img class="bean-icon" src="../../../assets/icons/bean-pink-icon.svg" draggable="false" alt="bean-icon">
                        <h3 class="item-text">{{'LandingPage.SectionFive.Item-0' | translate}}</h3>
                    </div>
                    <div class="item">
                        <img class="bean-icon" src="../../../assets/icons/bean-pink-icon.svg" draggable="false" alt="bean-icon">
                        <h3 class="item-text">{{'LandingPage.SectionFive.Item-1' | translate}}</h3>
                    </div>
                    <div class="item">
                        <img class="bean-icon" src="../../../assets/icons/bean-pink-icon.svg" draggable="false" alt="bean-icon">
                        <h3 class="item-text">{{'LandingPage.SectionFive.Item-2' | translate}}</h3>
                    </div>
                </div>
                <button class="pb-btn-default mt-4" routerLink="/recipe-engine">{{'Global.TryNow' | translate}}</button>
            </div>
            <div class="right">
                <img class="section-main-image" [src]="getSectionImage('five')" draggable="false" alt="recipe">
            </div>
        </div>
        <!-- Section Five (End) -->

         <!-- Section Six (Start) -->
         <div class="section common-section-type-two main-content-section-six">
            <div class="left">
                <img class="section-main-image" [src]="getSectionImage('six')" draggable="false" alt="recipe">
            </div>
            <div class="right">
                <h2>{{'LandingPage.SectionSix.Title' | translate}}</h2>
                <div class="items">
                    <div class="item">
                        <img class="bean-icon" src="../../../assets/icons/bean-pink-icon.svg" draggable="false" alt="bean-icon">
                        <h3 class="item-text">{{'LandingPage.SectionSix.Item-0' | translate}}</h3>
                    </div>
                    <div class="item">
                        <img class="bean-icon" src="../../../assets/icons/bean-pink-icon.svg" draggable="false" alt="bean-icon">
                        <h3 class="item-text">{{'LandingPage.SectionSix.Item-1' | translate}}</h3>
                    </div>
                    <div class="item">
                        <img class="bean-icon" src="../../../assets/icons/bean-pink-icon.svg" draggable="false" alt="bean-icon">
                        <h3 class="item-text">{{'LandingPage.SectionSix.Item-2' | translate}}</h3>
                    </div>
                    <div class="item">
                        <img class="bean-icon" src="../../../assets/icons/bean-pink-icon.svg" draggable="false" alt="bean-icon">
                        <h3 class="item-text">{{'LandingPage.SectionSix.Item-3' | translate}}</h3>
                    </div>
                </div>
                <button class="pb-btn-default mt-4" routerLink="/recipe-engine">{{'Global.TryNow' | translate}}</button>
            </div>
        </div>
        <!-- Section Six (End) -->

        <!-- Section Seven (Start) -->
        <div class="section main-content-section-seven">
            <div class="left">
                <h2>{{'LandingPage.SectionSeven.Title' | translate}}</h2>
                <p>{{'LandingPage.SectionSeven.Description' | translate}}</p>
                <button routerLink="/recipe-engine">{{'Global.TryNow' | translate | uppercase}}</button>
            </div>
            <div class="right">
                <img class="section-main-image" [src]="getSectionImage('seven')" draggable="false" alt="recipe">
            </div>
        </div>
        <!-- Section Seven (End) -->

        <!-- Frequently Asked Questions Component (Start)-->
        <app-frequently-asked-questions class="frequently-asked-questions"></app-frequently-asked-questions>
        <!-- Frequently Asked Questions Component (End)-->

        <!-- Contact Us Component (Start)-->
        <app-contact-us></app-contact-us>
        <!-- Contact Us Component (End)-->

    </div>
</div>

<app-how-to-use-video (tourVideoIsShowingOutput)="tourVideoIsShowingOutput($event)" [tourVideoIsShowing]="tourVideoIsShowing"></app-how-to-use-video>