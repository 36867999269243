import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Messages } from '../constants/messages';

@Injectable({
  providedIn: 'root'
})
export class BlogTextEditorGuard  {
  constructor(private toastrService: ToastrService, private router: Router){ }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let key = localStorage.getItem("jKxlfkj75N");
      if(key !== null && key === "QDcjYtZ0aMUk2pTaqqBFo8IbSJ8of5Kk"){
        return true
      }
      else{
        this.toastrService.error("Yetkisiz giriş", Messages.error);
        this.router.navigate(["/home"]);
        return false;
      }
  }
  
}
