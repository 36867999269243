import { HelpersFactoryService } from './../../services/helpers-factory.service';
import { ChatGptRecipeResultDto } from './../../models/chatGptRecipeResultDto';
import { SystemRecipe } from '../../models/systemRecipe';
import { IngiridentItemDto } from './../../models/ingiridentItemDto';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SystemRecipeAddDto } from 'src/app/models/systemRecipeAddDto';
import { Ingirident } from 'src/app/models/ingirident';
import { IngiridentDetailDto } from 'src/app/models/ingiridentDetailDto';
import { RecipeRequestDto } from 'src/app/models/recipeRequestDto';
import { SimpleRecipeRequestDto } from 'src/app/models/simpleRecipeRequestDto';
import { Language } from 'src/app/models/language';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { RecipeBook } from 'src/app/models/recipeBook';
import { RecipeBookService } from 'src/app/services/recipe-book.service';
import { RecipeTypeEnum } from 'src/app/enums/recipeTypeEnum';
import { SystemRecipeService } from 'src/app/services/system-recipe.service';
import { RecipeEngineAIService } from 'src/app/services/recipe-engine-ai.service';
import { TranslateModuleService } from 'src/app/services/translate-module.service';
import { AuthenticatedUserModel } from 'src/app/models/auth/authenticatedUserModel';
import { PageTitle } from 'src/app/constants/pageTitle';

enum TopContentItemsTypeEnum{
  HeatSources,
  CookingVessel,
  CookingTechniques,
  Units,
  DietTypes,
  AdvancedSearch
}

enum RecipeTabsEnum{
  Init,
  AddedIngridents,
  RecipeLoading,
  Recipe
}

class TopContentItemDto{
  name: string;
  isChecked?: boolean = false;
  isDefaultItem: boolean = false;
}

class RecipeUnwantedDto{
  name: string;
  isChecked?: boolean = false;
  isDefaultItem?: boolean = false;
}

class HealthConditionDto{
  name: string;
  isChecked?: boolean = false;
  isDefaultItem?: boolean = false;
}

interface AdvancedSearchItemDtoo{
  name: string;
  value: string;
}

class PortionNumber implements AdvancedSearchItemDtoo{
  name: string;
  value: string;
}
class RecipeMinute implements AdvancedSearchItemDtoo{
  name: string;
  value: string;
}
class PortionSize implements AdvancedSearchItemDtoo{
  name: string;
  value: string;
}

class TopContentItemsDto{
  id: number;
  type: TopContentItemsTypeEnum;
  name: string;
  iconPath: string;
  items: TopContentItemDto[];
  selectedFromCheckBoxs: string[] = [];
  dropDownIsShowing: boolean;
  dropDownAddAnotherFormIsShowing: boolean;
}

@Component({
  selector: 'app-recipe-engine',
  templateUrl: './recipe-engine.component.html',
  styleUrls: ['./recipe-engine.component.css'],
  animations: [
    trigger('dropDownExpandAnimation', [
      state('in', style({ transform: 'translateY(0)' })),
      transition('void => *', [
        style({ transform: 'translateY(100%)'}),
        animate(300)
      ]),
      transition('* => void', [
        animate(300, style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class RecipeEngineComponent implements OnInit {

  RecipeTabsEnum = RecipeTabsEnum;
  currentRecipeTab: RecipeTabsEnum = RecipeTabsEnum.Init;

  newItemTopContentItems: string;
  topContentItems: TopContentItemsDto[] = [
    {
      id: 1,
      type: TopContentItemsTypeEnum.HeatSources,
      name: "HeatSource",
      iconPath: "icons/oven-icon.svg",
      items: [{name:"Fırın", isDefaultItem: true}, {name:"Gaz Sobası", isDefaultItem: true}, {name:"Izgara", isDefaultItem: true}, {name:"Yavaş Pişirici", isDefaultItem: true}, {name:"Vakumda Pişirme", isDefaultItem: true}, {name:"Mini Fırın", isDefaultItem: true}],
      selectedFromCheckBoxs: [],
      dropDownIsShowing: false,
      dropDownAddAnotherFormIsShowing: false
    },
    {
      id: 2,
      type: TopContentItemsTypeEnum.CookingVessel,
      name: "CookingVessel",
      iconPath: "icons/pan-icon.svg",
      items: [{name:"Tencere", isDefaultItem: true}, {name:"Güveç", isDefaultItem: true}, {name:"Tava", isDefaultItem: true}, {name:"Düdüklü Tencere", isDefaultItem: true}, {name:"Fırın Tepsisi", isDefaultItem: true},  {name:"Bor Cam", isDefaultItem: true}],
      selectedFromCheckBoxs: [],
      dropDownIsShowing: false,
      dropDownAddAnotherFormIsShowing: false
    },
    {
      id: 3,
      name: "CookingTechnique",
      type: TopContentItemsTypeEnum.CookingTechniques,
      iconPath: "icons/cooker-icon.svg",
      items: [{name:"Buharlama", isDefaultItem: true}, {name:"Kızartma", isDefaultItem: true}, {name:"Izgara", isDefaultItem: true}, {name:"Soteleme", isDefaultItem: true}, {name:"Güveç", isDefaultItem: true}, {name:"Kaynatma", isDefaultItem: true}, {name:"Marine etme", isDefaultItem: true}],
      selectedFromCheckBoxs: [],
      dropDownIsShowing: false,
      dropDownAddAnotherFormIsShowing: false
    },
    {
      id: 4,
      name: "Units",
      type: TopContentItemsTypeEnum.Units,
      iconPath: "icons/carafe-icon.svg",
      items: [{name:"Metrik sistem", isDefaultItem: true}, {name:"ABD geleneksel sistemi", isDefaultItem: true}, {name:"Uluslararası birim sistemi (SI)", isDefaultItem: true}, {name:"İngiliz imparatorluk sistemi", isDefaultItem: true}, {name:"Çin sistemi", isDefaultItem: true}, {name:"Japon sistemi", isDefaultItem: true}],
      selectedFromCheckBoxs: [],
      dropDownIsShowing: false,
      dropDownAddAnotherFormIsShowing: false
    },
    {
      id: 5,
      name: "DietTypes",
      type: TopContentItemsTypeEnum.DietTypes,
      iconPath: "icons/diyet-icon.svg",
      items: [{name:"Hepçil", isDefaultItem: true}, {name:"Vejetaryen", isDefaultItem: true}, {name:"Vegan", isDefaultItem: true}, {name:"Pesketaryen", isDefaultItem: true}, {name:"Esnek", isDefaultItem: true}, {name:"Glutensiz", isDefaultItem: true}, {name:"Paleo", isDefaultItem: true}, {name:"Akdeniz", isDefaultItem: true}, {name:"Çiğ yemek", isDefaultItem: true}],
      selectedFromCheckBoxs: [],
      dropDownIsShowing: false,
      dropDownAddAnotherFormIsShowing: false
    }
  ]

  ingredients: IngiridentDetailDto[] = [];

  portionNumbers: PortionNumber[] = [{name: "1 Kişilik", value: "1"}, {name: "2 Kişilik", value: "2"}, {name: "4 Kişilik", value: "4"}];
  recipeMinutes: RecipeMinute[] = [{name: "30 Dk", value: "30"}, {name: "60 Dk", value: "60"}, {name: "90 Dk", value: "90"}]
  portionSizes: PortionNumber[] = [{name: "Büyük", value: "Büyük"}, {name: "Orta", value: "Orta"}, {name: "Küçük", value: "Küçük"}];
  unwanteds: RecipeUnwantedDto[] = [{name: "Acı", isDefaultItem: true}, {name: "Tatlı", isDefaultItem: true}, {name: "Tuzlu", isDefaultItem: true}, {name: "Ekşi", isDefaultItem: true}]
  healthConditions: HealthConditionDto[] = [{name: "Diyabet", isDefaultItem: true}, {name: "Kolesterol", isDefaultItem: true}, {name: "Çölyak", isDefaultItem: true}, {name: "Reflü", isDefaultItem: true}, {name: "Gastrit", isDefaultItem: true}]
  

  currentPortionNumber: PortionNumber = this.portionNumbers[1];
  currentRecipeMinute: RecipeMinute = this.recipeMinutes[1];
  currentPortionSize: PortionSize = this.portionSizes[1];

  meatTypes: string[] = ["Ana yemek", "Kahvaltılık", "Çorba", "Tatlı", "Ara sıcak", "Başlangıç", "Salata", "Farketmez"];
  currentMeatType: string = this.meatTypes[0];

  advancedSearchDropDownIsShowing: boolean = false;
  topContentIsShowing: boolean = false;
  currentTopContentItems: TopContentItemsDto;

  currentHeatSources: string;
  heatSourceForm: FormGroup;

  selectedIngridentsItemCount: number = 0;

  portionNumber: number = 2;
  recipeMinute: number = 60;

  addAnotherUnWantedIsShowing: boolean = false;
  newAnotherUnWanted: string = "";
  addAnotherHealthConditionIsShowing: boolean = false;
  newAnotherHealthCondition: string;

  recipeRequest: ChatGptRecipeResultDto;
  recipe: SystemRecipe;
  recipeLinkBase: string = "https://www.pibean.com/recipe/detail/";

  currentLanguage: Language;
  currentIngredient: IngiridentDetailDto

  calorieIndicatorIcon: string;
  calorieIndicatorClass: string;

  authenticatedUser: AuthenticatedUserModel;

  constructor(public authService: AuthService,
    public formBuilder: FormBuilder,
    private recipeEngineAIService: RecipeEngineAIService,
    private systemRecipeService: SystemRecipeService,
    private router: Router,
    private helpersFactoryService: HelpersFactoryService,
    private recipeBookService: RecipeBookService) { }

  ngOnInit(): void {
    this.helpersFactoryService.pageTitleService.setTitleWithCanonical(PageTitle.layouts.mainLayout.recipeEngine);
    this.authenticatedUser = this.helpersFactoryService.localStorageService.getAuthenticatedUserInformations();
    this.getIngredients();
    this.getBrowserLanguage();
  }

  getIngredients(){
    let ingredientsData: string[] = ["Sebzeler", "Etler", "Baklagiller", "Hayvansal Gıdalar", "Bitkiler", "Baharatlar", "Meyveler", "Diğer Malzemeler"];
    let ingredientsIcons: string[] = ["ingrident-vegetables-icon.png", "ingrident-meats-icon.png", "ingrident-legumes-icon.png",  "ingrident-animal-foods-icon.png", "ingrident-plants-icon.png","ingrident-spices-icon.png","ingrident-fruits-icon.png", "ingrident-another-ingrident-icon.png"]
    let ingredientItemsData: string[][] = [
      ["Havuç", "Domates",  "Patates", "Soğan","Ispanak", "Kabak", "Patlıcan", "Karnabahar", "Dolmalık Biber","Salatalık", "Enginar", "Kereviz", "Sivri Biber", "Kırmızı Biber (Kapya)"],
      ["Balık","Havyar","Karides","Dana","İnek","Tavuk","Kuzu","Domuz","Koyun","Hindi"],
      ["Pirinç","Nohut","Kuru Fasulye","Mercimek","Barbunya","Şeker Fasulyesi","Bulgur","Kinoa","Un", "Yulaf"],
      ["Yumurta","Tereyağı","Süt","Yoğurt","Peynir","Kaymak","Bal"],
      ["Maydanoz","Marul", "Kuru Nane","Kıvırcık","Dere Otu","Roka","Soya Filizi","Kuşkonmaz","Yeşil Soğan","Fesleğen"],
      ["Karabiber","Kırmızıbiber","Kimyon","Kekik","Nane","Susam","Çörek Otu","Tarçın","Zencefil", "Kişniş","Tuz","Şeker"],
      ["Kavun","Portakal","Çilek","Limon", "Avakado","Şeftali", "Elma","Üzüm", "Böğürtlen"],
      ["Ayçiçek Yağı","Zeytin Yağı","Mısır Özü Yağı","Susam Yağı","Limon Suyu","Soya Sosu","Vasabi","Ketçap","Mayonez","Hardal"]]

      for(let i = 0; i < ingredientsData.length; i++){
        let ingredient = new IngiridentDetailDto();
        ingredient.id = i + 1;
        ingredient.name = ingredientsData[i];
        ingredient.icon = ingredientsIcons[i];
        ingredient.dropDownIsShowing = false;
        ingredient.addNewItemIsActive = false;
        for(let j = 0; j < ingredientItemsData[i].length; j++){
          let ingiridentItem = new IngiridentItemDto()
          ingiridentItem.id = j + 1;
          ingiridentItem.name = ingredientItemsData[i][j];
          ingiridentItem.isChecked = false;
          ingiridentItem.isDefaultItem = true;
          ingredient.items.push(ingiridentItem);
        }
        this.ingredients.push(ingredient);
      }
  }

  getBrowserLanguage(){
    this.currentLanguage = TranslateModuleService.currentLanguage;
  }

  sendRecipeRequest(){
    if(this.authService.loginBefore()){
      this.getBrowserLanguage();
      this.currentRecipeTab = RecipeTabsEnum.RecipeLoading;
      let request = new RecipeRequestDto();
      this.scroll("recipeContainer");
      if(this.currentMeatType === this.meatTypes[this.meatTypes.length - 1]){
        request.meatType = null;
      }
      else{
        request.meatType = this.currentMeatType;
      }
      request.heatSources = this.topContentItems[0].selectedFromCheckBoxs;
      request.cookingVessels = this.topContentItems[1].selectedFromCheckBoxs;
      request.cookingTechniques = this.topContentItems[2].selectedFromCheckBoxs;
      request.units = this.topContentItems[3].selectedFromCheckBoxs;
      request.dietTypes = this.topContentItems[4].selectedFromCheckBoxs;
      request.portionNumber = this.portionNumber.toString();
      request.recipeMinute = this.recipeMinute.toString();
      request.portionSize = this.currentPortionSize.value;
      request.unwanteds = this.getSelectedItems(this.unwanteds);
      request.healthConditions = this.getSelectedItems(this.healthConditions);
      request.ingiridents = this.getSelectedIngridents();
      request.language = this.currentLanguage.name;
      this.recipeEngineAIService.recipeRequest(request).subscribe({
        next: (response => {
          this.currentRecipeTab = RecipeTabsEnum.Recipe;
          this.recipeRequest = response.data;
          this.getIndicatorStyle(this.recipeRequest.calorie);
          let recipeAddModel = new SystemRecipeAddDto()
          recipeAddModel.userId = this.authenticatedUser.userBasicInformation.id;
          recipeAddModel.recipeTypeId = RecipeTypeEnum.SystemRecipe;
          recipeAddModel.recipeName = response.data.eatName;
          recipeAddModel.calorie = response.data.calorie;
          recipeAddModel.protein = response.data.protein;
          recipeAddModel.oil = response.data.oil;
          recipeAddModel.carbohydrate = response.data.carbohydrate;
          recipeAddModel.averageCookingTime = response.data.averageCookingTime;
          recipeAddModel.recipeContent = response.data.content;
          recipeAddModel.recipeRequestJson = response.data.recipeRequestJson;
          this.systemRecipeService.add(recipeAddModel).subscribe({})
        }),
        error: (error => {
          this.sendRecipeRequest();
        })
      });
    }
  }

  simpleRecipeRequest(eatType: string){
    if(this.authService.loginBefore()){
      this.getBrowserLanguage();
      let eatTypeTemp = eatType;
      this.scroll("recipeContainer");
      this.currentRecipeTab = RecipeTabsEnum.RecipeLoading;
      let recipeRequest = new SimpleRecipeRequestDto()
      recipeRequest.eatName = eatType;
      recipeRequest.language = this.currentLanguage.name;
      this.recipeEngineAIService.simpleRecipeRequest(recipeRequest).subscribe({
        next: (response => {
          this.currentRecipeTab = RecipeTabsEnum.Recipe;
          this.recipeRequest = response.data;
          this.getIndicatorStyle(this.recipeRequest.calorie);
          let recipeAddModel = new SystemRecipeAddDto()
          recipeAddModel.userId = this.authenticatedUser.userBasicInformation.id;
          recipeAddModel.recipeTypeId = RecipeTypeEnum.SystemRecipe;
          recipeAddModel.recipeName = response.data.eatName;
          recipeAddModel.calorie = response.data.calorie;
          recipeAddModel.averageCookingTime = response.data.averageCookingTime;
          recipeAddModel.protein = response.data.protein;
          recipeAddModel.oil = response.data.oil;
          recipeAddModel.carbohydrate = response.data.carbohydrate;
          recipeAddModel.recipeContent = response.data.content;
          recipeAddModel.recipeRequestJson = response.data.recipeRequestJson;
          this.systemRecipeService.add(recipeAddModel).subscribe({})
        }),
        error: (error => {
          this.simpleRecipeRequest(eatTypeTemp);
        })
      });
    }
  }

  addRecipeToBook(){
    if(this.authenticatedUser != null){
      this.helpersFactoryService.spinnerService.show();
      let recipeBook = new RecipeBook();
      recipeBook.id = 0;
      recipeBook.userId = this.authenticatedUser.userBasicInformation.id;
      recipeBook.recipeId = this.recipe.id;
      recipeBook.date = new Date();
      this.recipeBookService.add(recipeBook).subscribe({
        next: (response => {
          this.helpersFactoryService.spinnerService.hide();
          this.helpersFactoryService.toastService.success("Tarif defterinze eklendi");
        }),
        error: (error => {
          this.helpersFactoryService.spinnerService.hide();
          this.helpersFactoryService.toastService.error(error.error.message);
        })
      });
    }
    else{
      this.helpersFactoryService.toastService.warning("Önce giriş yapmalısınız");
      this.router.navigate(["login"]);
    }
  }

  getSelectedItems(items: any[]){
    let selectedItems: any[] = [];
    items.forEach(element => {
      if(element.isChecked){
        selectedItems.push(element.name);
      }
    });
    return selectedItems;
  }

  getSelectedIngridents(){
    let selectedIngridents: Ingirident[] = [];
    this.ingredients.forEach(element => {
      let result = this.getSelectedItems(element.items);
      if(result.length > 0){
        let ingrident = new Ingirident()
        ingrident.name = element.name
        ingrident.items = result;
        selectedIngridents.push(ingrident);
      }
    });
    return selectedIngridents;
  }

  addNewItemToTopContentItems(item: TopContentItemsDto){
    if(this.newItemTopContentItems.length > 0){
      let added = new TopContentItemDto()
      added.name = this.newItemTopContentItems;
      added.isChecked = true;
      let topContentItem = this.topContentItems.find(c => c.id === item.id);
      topContentItem?.items.push(added);
      this.selectedTopContentItemsCheckBoxOnChange(item.id, added);
      this.newItemTopContentItems = "";
    }
  }

  setCurrentTopContentItems(item: TopContentItemsDto){
    this.currentTopContentItems = item;
    item.dropDownIsShowing = !item.dropDownIsShowing;
  }

  toggleAdvancedSearchDropDownState(){
    this.advancedSearchDropDownIsShowing = !this.advancedSearchDropDownIsShowing;
  }

  ingiridentDropDownToggle(ingirident: IngiridentDetailDto){
    if(this.currentIngredient != null && this.currentIngredient != ingirident){
      this.currentIngredient.dropDownIsShowing = false;
      ingirident.dropDownIsShowing = true;
    }
    else{
      if(ingirident.dropDownIsShowing){
        ingirident.dropDownIsShowing = false;
      }
      else{
        ingirident.dropDownIsShowing = true;
      }
    }
    this.currentIngredient = ingirident;
  }

  toogleTopContent(){
    const topContent = document.getElementById('topContent');
    const mainContent = document.getElementById('mainContent');
    if(topContent != null && mainContent != null && mainContent != null){
      if(this.topContentIsShowing){
        this.topContentIsShowing = false;
        topContent.style.display = "none";
        mainContent.style.marginTop = "120px"
      }
      else{
        this.topContentIsShowing = true;
        topContent.style.display = "block";
        mainContent.style.marginTop = "750px"
      }
    }
  }

  getSelectedItemsCount(items: TopContentItemDto[]): number{
    let count = 0;
    items.forEach(element => {
      if(element.isChecked){
        count += 1;
      }
    });
    return count;
  }

  getSelectedIngridentItemsCount(items: IngiridentItemDto[]): number{
    let count = 0;
    items.forEach(element => {
      if(element.isChecked){
        count += 1;
      }
    });
    return count;
  }

  getIndicatorStyle(calorie: string){
    let value = parseInt(calorie);
    if(value < 250){
      this.calorieIndicatorIcon = "low";
      this.calorieIndicatorClass = "calorie-value-text calorie-value-low"
    }
    else if(value >= 250 && value < 500){
      this.calorieIndicatorIcon = "medium";
      this.calorieIndicatorClass = "calorie-value-text calorie-value-medium"
    }
    else if(value >= 500 && value < 750){
      this.calorieIndicatorIcon = "high";
      this.calorieIndicatorClass = "calorie-value-text calorie-value-high"
    }
    else{
      this.calorieIndicatorIcon = "very-high";
      this.calorieIndicatorClass = "calorie-value-text calorie-value-very-high"
    }
  }

  selectedTopContentItemsCheckBoxOnChange(topContentItemId: number, value: TopContentItemDto): void {
    let topContentItem = this.topContentItems.find(item => item.id === topContentItemId); 
    if (topContentItem?.selectedFromCheckBoxs.includes(value.name)) {
      topContentItem.selectedFromCheckBoxs = topContentItem?.selectedFromCheckBoxs.filter((item) => item !== value.name);
    } else {
      topContentItem?.selectedFromCheckBoxs.push(value.name);
    }
  }

  selectIngridentItem(item: IngiridentItemDto){
    if(item.isChecked){
      this.selectedIngridentsItemCount -= 1;
      item.isChecked = false;
    }
    else{
      item.isChecked = true;
      this.selectedIngridentsItemCount += 1;
    }

    if(this.selectedIngridentsItemCount > 0){
      this.currentRecipeTab = RecipeTabsEnum.AddedIngridents;
    }
    else{
      this.currentRecipeTab = RecipeTabsEnum.Init;
    }
  }

  selectUnwantedItem(item: RecipeUnwantedDto){
    if(item.isChecked){
      item.isChecked = false;
    }
    else{
      item.isChecked = true;
    }
  }

  selectHealthConditionItem(item: HealthConditionDto){
    if(item.isChecked){
      item.isChecked = false;
    }
    else{
      item.isChecked = true;
    }
  }

  setPortionNumberItem(item: PortionNumber){
    this.currentPortionNumber = item;
    this.portionNumber = parseInt(item.value)
  }

  setRecipeMinuteItem(item: RecipeMinute){
    this.currentRecipeMinute = item;
    this.recipeMinute = parseInt(item.value)
  }

  setPortionSizeItem(item: PortionNumber){
    this.currentPortionSize = item;
  }

  selectCurrentMeatType(type: string){
    this.currentMeatType = type;
  }

  addNewIngridentItem(item: IngiridentDetailDto){
    if(item.addNewItemIsActive){
      let ingrident = this.ingredients.find(i => i.id === item.id);
      if(ingrident.newItemInput.length > 0){
        let id = ingrident.items.length + 1;
        let ingridentItem = new IngiridentItemDto();
        ingridentItem.id = id;
        ingridentItem.name = item.newItemInput;
        ingridentItem.isChecked = false;
        ingridentItem.isDefaultItem = false;
        ingrident.items.push(ingridentItem);
        ingrident.newItemInput = "";
        item.searchInput = "";
        this.selectIngridentItem(ingridentItem);
      }
    }
  }

  setAddNewIngridentItem(item: IngiridentDetailDto, type: boolean){
    item.addNewItemIsActive = type;
  }

  porisonSizeValueEvent(value: number){
    if(value < 0){
      if(this.portionNumber > 1){
        this.portionNumber += value;
      }
    }
    else{
      this.portionNumber += value;
    }
  }

  recipeMinuteValueEvent(value: number){
    if(value < 0){
      if(this.recipeMinute > 1){
        this.recipeMinute += value;
      }
    }
    else{
      this.recipeMinute += value;
    }
  }

  addAnotherUnwanted(){
    if(this.newAnotherUnWanted.length > 0){
      this.unwanteds.push({name: this.newAnotherUnWanted, isChecked: true});
      this.newAnotherUnWanted = "";
    }
  }

  addAnotherHealthCondition(){
    if(this.newAnotherHealthCondition.length > 0){
      this.healthConditions.push({name: this.newAnotherHealthCondition, isChecked: true});
      this.newAnotherHealthCondition = "";
    }
  }

  getNavbarItemsNameForTranslate(item: string){
    return `Home.Navbar.${item}.Name`;
  }

  getNavbarItemsItemNameForTranslate(item: string, index: number){
    return `Home.Navbar.${item}.Items.Item-${index}`;
  }

  getAdvancedSearchItemsItemNameForTranslate(item: string, index: number){
    return `Home.Navbar.AdvancedSearch.Items.${item}.Items.Item-${index}`;
  }

  getIngridentsItemsNameForTranslate(index: number){
    return `Home.Ingredients.Items.Item-${index}.Name`;
  }
  getIngridentsItemsItemNameForTranslate(itemsIndex: number, itemIndex: number){
    return `Home.Ingredients.Items.Item-${itemsIndex}.Items.Item-${itemIndex}`;
  }

  scroll(element: any) {
    document.getElementById(element).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  getIngredientMenuItemDropDownIcon(state: boolean){
    if(state){
      return `../../../assets/icons/arrow-up-pink-icon.svg`;
    }
    return `../../../assets/icons/arrow-down-gray-icon.svg`;
  }

}
