import { Component, OnInit } from '@angular/core';
import { PageTitle } from 'src/app/constants/pageTitle';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-how-to-use',
  templateUrl: './how-to-use.component.html',
  styleUrls: ['./how-to-use.component.css']
})
export class HowToUseComponent implements OnInit {

  constructor(private pageTitleService: PageTitleService) { }

  tourVideoIsShowing: boolean = false;

  ngOnInit(): void {
    this.pageTitleService.setTitleWithCanonical(PageTitle.layouts.mainLayout.howToUse);
  }

  tourVideoIsShowingOutput(state: boolean){
    this.tourVideoIsShowing = state;
  }

}
