import { Component, OnInit } from '@angular/core';
import { PageTitle } from 'src/app/constants/pageTitle';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private pageTitleService: PageTitleService) { }

  ngOnInit(): void {
    this.pageTitleService.setTitleWithCanonical(PageTitle.layouts.globalLayout.pageNotFound);
  }

}
