export class UserFollower {

    constructor(id?: number, userId?: number, followingUserId?: number, date?: Date) {
        this.id = id;
        this.userId = userId;
        this.followingUserId = followingUserId;
        this.date = date;
    }

    id: number;
    userId: number;
    followingUserId: number;
    date: Date | null;
}