<div class="main">
    <div class="main-container">
        <div class="recipe-detail-top">
            <div class="recipe-detail">
                <div class="recipe-image">
                    <div *ngIf="recipe?.recipeImage != null" class="upload-image">
                        <img *ngIf="recipe?.recipeImage != null" src="{{HelpersFactoryService.getMediaServerUrl()}}assets/{{recipe?.recipeImage?.filePath}}" draggable="false" alt="recipe-image">
                    </div>
                    <div *ngIf="recipe?.recipeImage == null" class="not-image">
                        <img *ngIf="recipe?.recipeImage == null" src="../../../assets/icons/image-cartoon-icon.svg" draggable="false" alt="image-icon">
                        <p>{{'Recipes.NoPhotoUploadedYet' | translate}}</p>
                    </div>
                </div>
                <div class="recipe-info">
                    <div class="recipe-name">
                        <h1>{{recipe?.recipeName}}</h1>
                    </div>
                    <div class="recipe-rate">
                        <div class="star-icons">
                            <img *ngFor="let star of counter(5)" src="../../../assets/icons/star-fill-pink-icon.svg" draggable="false" alt="star">
                            <!-- <img *ngFor="let star of counter(1)" src="../../../assets/icons/star-empty-gray-icon.svg" draggable="false" alt="star"> -->
                        </div>
                        <div class="point">
                            <p>5.0 {{'Recipes.Point' | translate}}</p>
                        </div>
                    </div>
                    <div class="recipe-time">
                        <img src="../../../assets/icons/time-circle-red-icon.svg" draggable="false" alt="time-icon">
                        <span>{{recipe?.averageCookingTime}} {{'Global.Minute' | translate}} ({{'RecipesDetail.AverageTime' | translate}})</span>
                    </div>
                    <div class="action-buttons">
                        <!-- <button class="pb-btn-default"><img class="icon-left" src="../../../assets/icons/arrow-right-white-circle-icon.svg">{{'RecipesDetail.MakeTheRecipe' | translate}}</button> -->
                        <button *ngIf="!recipeBookIsAddedd" (click)="addRecipeToBook()" class="pb-btn-outlined"><img class="icon-left" src="../../../assets/icons/document-pink-icon.svg">{{'RecipesDetail.AddToBook' | translate}}</button>
                    </div>
                </div>
            </div>
            <div class="recipe-images">
                <!-- <div *ngFor="let star of counter(3)" class="image">
                    <img *ngIf="recipe?.recipeImage?.filePath" src="{{HelpersFactoryService.getMediaServerUrl()}}assets/{{recipe?.recipeImage?.filePath}}" draggable="false" alt="recipe-image">
                </div> -->
            </div>
            <div class="tabs">
                <div class="tabs-content">
                    <div (click)="setCurrentTab(tabEnums.Recipe)" [class]="getCurrentTabClass(tabEnums.Recipe)">
                        <p>{{'RecipesDetail.Recipe.Title' | translate}}</p>
                    </div>
                    <div (click)="setCurrentTab(tabEnums.Comments)" [class]="getCurrentTabClass(tabEnums.Comments)">
                        <p>{{'RecipesDetail.Comments.Title' | translate}} ({{recipe?.recipeComments?.length}})</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div *ngIf="currentTab == tabEnums.Recipe" class="recipe-content">
                <div class="left">
                    <div class="side">
                        <h2 class="side-title">{{'RecipesDetail.Recipe.FoodRecipes.Title' | translate}}</h2>
                        <div class="side-content">
                            <div *ngIf="systemRecipe != null" class="recipe-values">
                                <div class="calorie-value">
                                    <h3 class="recipe-value-title">{{'RecipesDetail.Recipe.FoodRecipes.CalorieAmount' | translate}}</h3>
                                    <div class="recipe-value-content">
                                        <div class="indicator">
                                            <img src="../../../assets/icons/calorie-indicator-{{calorieIndicatorIcon}}.svg" draggable="false" alt="indicator">
                                            <p [class]="calorieIndicatorClass">{{systemRecipe?.calorie}} <span>KCAL</span></p>
                                        </div>
                                        <p class="info-text">*{{'RecipesDetail.Recipe.FoodRecipes.ApproximateValuesPerServing' | translate}}</p>
                                    </div>
                                </div>
                                <div class="nutritive-value">
                                    <h3 class="recipe-value-title">{{'RecipesDetail.Recipe.FoodRecipes.NutritiveValue' | translate}}</h3>
                                    <div class="recipe-value-content">
                                        <p class="property">{{'RecipesDetail.Recipe.FoodRecipes.Protein' | translate}}: <span>{{systemRecipe?.protein || '--'}} Gram</span></p>
                                        <p class="property">{{'RecipesDetail.Recipe.FoodRecipes.Oil' | translate}}: <span>{{systemRecipe?.oil || '--'}} Gram</span></p>
                                        <p class="property">{{'RecipesDetail.Recipe.FoodRecipes.Carbohydrate' | translate}}: <span>{{systemRecipe?.carbohydrate || '--'}} Gram</span></p>
                                        <p class="info-text">*{{'RecipesDetail.Recipe.FoodRecipes.ApproximateValuesPerServing' | translate}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 recipe-content-container">
                                <div *ngIf="systemRecipe != null" class="system-recipe-content">
                                    <span style="white-space: pre-line">
                                        {{systemRecipe?.recipeContent}}
                                    </span>
                                </div>
                                <div *ngIf="userRecipe != null" class="user-recipe-content">
                                    <div class="ingredients">
                                        <p class="title">{{'RecipesDetail.Recipe.FoodRecipes.Ingredients' | translate}}</p>
                                        <ul>
                                            <li *ngFor="let ingredient of userRecipe.userRecipeIngredients">{{ingredient.measurement}} {{ingredient.name}}</li>
                                        </ul>
                                    </div>
                                    <div class="preparations">
                                        <p class="title">{{'RecipesDetail.Recipe.FoodRecipes.Preparation' | translate}}</p>
                                        <ol>
                                            <li *ngFor="let step of userRecipe.userRecipeMakingSteps">{{step.step}}</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="side popular-photo-uploader">
                        <h2 class="side-title">{{'RecipesDetail.Recipe.LoaderOfFood.Title' | translate}}</h2>
                        <div class="side-content">
                            <div class="avatar">
                                <img src="../../../assets/icons/salad-icon.png" draggable="false" alt="avatar">
                                <span>{{recipe?.user?.firstName}} {{recipe?.user?.lastName}} <span *ngIf="authenticatedUser && authenticatedUser?.userBasicInformation?.id == recipe?.user?.id">({{'Global.You' | translate}})</span> </span>
                            </div>
                            <div class="activities">
                                <div class="statistics">
                                    <p>{{'Profile.Followers' | translate}}: <span>{{userFollowerStatistic?.followers}}</span></p>
                                    <p>{{'Profile.Follow' | translate}}: <span>{{userFollowerStatistic?.following}}</span></p>
                                </div>
                                <div *ngIf="authenticatedUser && authenticatedUser?.userBasicInformation?.id != recipe?.user?.id" class="subscribe">
                                    <button *ngIf="!(recipeOwnerIsFollowing | async)" (click)="followToUser()" class="pb-btn-outlined">{{'RecipesDetail.Recipe.LoaderOfFood.Follow' | translate}}</button>
                                    <button *ngIf="(recipeOwnerIsFollowing | async)" (click)="unFollowToUser()" class="pb-btn-outlined">{{'Profile.Followed' | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="side another-recipes">
                        <h2 class="side-title">{{'RecipesDetail.Recipe.AnotherFoods.Title' | translate}}</h2>
                        <div class="side-content">
                            <div *ngFor="let anotherRecipe of anotherRecipes" class="another-recipe-item">
                                <h3 class="item-title">{{anotherRecipe?.recipeName}}</h3>
                                <div class="other-info">
                                    <div class="other-info-section">
                                        <img src="../../../assets/icons/time-circle-gray-icon.svg" draggable="false" alt="time-icon">
                                        <span>{{anotherRecipe?.averageCookingTime}} {{'Global.MinuteShort' | translate}}</span>
                                    </div>
                                    <div class="other-info-section">
                                        <img src="../../../assets/icons/document-gray-2-icon.svg" draggable="false" alt="document-icon">
                                        <span>0</span>
                                    </div>
                                    <div class="other-info-section">
                                        <img src="../../../assets/icons/heart-gray-icon.svg" draggable="false" alt="heart-icon">
                                        <span>0</span>
                                    </div>
                                    <div class="other-info-section">
                                        <img src="../../../assets/icons/chat-gray-2-icon.svg" draggable="false" alt="chat-icon">
                                        <span>0</span>
                                    </div>
                                </div>
                                <button routerLink="/recipes/detail/{{anotherRecipe.recipeUrl}}-{{anotherRecipe.recipeId}}-{{anotherRecipe.recipeType?.id}}" class="pb-btn-outlined mt-3">{{'RecipesDetail.Recipe.AnotherFoods.SeeTheFood' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="currentTab == tabEnums.Comments" class="evaluations-and-comments-content">
                <!-- <div class="side evaluations">
                    <h2 class="side-title">Değerlendirmeler</h2>
                    <div class="side-content">
                        <div class="votes-average-point">
                            <p class="total-count">Değerlendirenler (32+)</p>
                            <div class="star-icon">
                                <img src="../../../assets/icons/star-fill-pink-icon.svg" draggable="false" alt="star-icon">
                                <span class="average-count">3.0</span>
                            </div>
                        </div>
                        <div class="votes-counts">
                            <div *ngFor="let voteItem of voteTempArray" class="item">
                                <div class="stars-count">
                                    <img *ngFor="let item of counter(voteItem)" src="../../../assets/icons/star-fill-pink-icon.svg" draggable="false" alt="star">
                                    <img *ngFor="let item of counter(5 - voteItem)" src="../../../assets/icons/star-empty-gray-icon.svg" draggable="false" alt="star">
                                </div>
                                <div class="progress-bar">
                                    <progress [value]="voteItem" [max]="5"></progress>
                                </div>
                                <div class="vote-count">
                                    <span>{{voteItem}} Kişi</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="side comments">
                    <h2 class="side-title">{{'RecipesDetail.Comments.Title' | translate}}</h2>
                    <div class="side-content">
                        <div class="send-comment">
                            <form [formGroup]='newCommentForm' *ngIf="newCommentForm">
                                <p class="send-comment-text">{{'RecipesDetail.Comments.CommentToo' | translate}}</p>
                                <div class="new-comment">
                                    <div class="user-avatar">
                                        <img src="../../../assets/icons/user.png" draggable="false" alt="avatar">
                                    </div>
                                    <div class="pb-input-box">
                                        <input type="text" required="required" id="text" autocomplete="off" formControlName="comment">
                                        <span>{{'RecipesDetail.Comments.TypeYourComment' | translate}}</span>
                                    </div>
                                    <div class="send-comment-button">
                                        <img (click)="sendComment()" src="../../../assets/icons/send-pink-icon.svg" draggable="false" alt="send">
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="comments-made">
                            <div *ngFor="let comment of recipe?.recipeComments" class="comment">
                                <div class="primary-comment comment-common">
                                    <div class="user-avatar">
                                        <img src="../../../assets/icons/user.png" draggable="false" alt="avatar">
                                    </div>
                                    <div class="comment-content">
                                        <div class="comment-top">
                                            <div class="user-name">
                                                <p *ngIf="authenticatedUser && authenticatedUser?.userBasicInformation?.id == comment.user.id; else commentNotOwner">{{'Global.You' | translate}}</p>
                                                <ng-template #commentNotOwner><p>{{comment.user?.firstName}} {{comment.user?.lastName}}</p></ng-template>
                                            </div>
                                            <div class="comment-date">
                                                <p>{{comment.date | date:'dd.MM.yyyy'}}</p>
                                            </div>
                                        </div>
                                        <div class="comment-text">
                                            <p>{{comment.comment}}</p>
                                        </div>  
                                    </div>
                                    <div class="comment-bottom">
                                        <div (click)="likeComment(comment.id)" class="like">
                                            <span>{{'Global.Like' | translate}} ({{comment.recipeCommentLikes?.length}})</span>
                                            <img src="../../../{{getRecipeCommentHeartIcon(comment.recipeCommentLikes)}}" draggable="false" alt="like">
                                        </div>
                                        <!-- <div class="reply">
                                            <span>Yanıtla</span>
                                        </div> -->
                                    </div>
                                </div>
                                <!-- <div *ngFor="let replyComment of comment.replyComments" class="reply-comment comment-common">
                                    <div class="user-avatar">
                                        <img src="../../../assets/icons/user.png" draggable="false" alt="avatar">
                                    </div>
                                    <div class="comment-content">
                                        <div class="comment-top">
                                            <div class="user-name">
                                                <p>Okan ALTIPARMAK</p>
                                            </div>
                                            <div class="comment-date">
                                                <p>28.07.2023</p>
                                            </div>
                                        </div>
                                        <div class="comment-text">
                                            <p>Yorumunuzu dikkate alarak tarifi denedim ve hiç pişman olmadım, size de afiyet olsun.</p>
                                        </div>  
                                    </div>
                                    <div class="comment-bottom">
                                        <div class="like">
                                            <span>Beğen</span>
                                            <img src="../../../assets/icons/heart-pink-icon.svg" draggable="false" alt="like">
                                        </div>
                                        <div class="reply">
                                            <span>Yanıtla</span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
