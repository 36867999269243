<div *ngIf="startYourRecipeModalIsShowing" class="start-your-recipe">
    <div class="content">
        <div class="top">
            <div class="left-side">
                <span class="modal-title">{{'StartYourRecipeModal.Title' | translate | uppercase}}</span>
            </div>
            <div class="right-side">
                <img (click)="closeModal()" src="../../../assets/icons/close-circle-light-gray-icon.svg" draggable="false" alt="close-icon">
            </div>
        </div>
        <div class="main-content">
            <h2>{{'StartYourRecipeModal.SubTitle' | translate | uppercase}}</h2>
            <h3>{{'StartYourRecipeModal.Description' | translate | uppercase}}</h3>
            <img src="../../../assets/icons/start-your-recipe-icon.png" draggable="false" alt="start-your-recipe">
        </div>
        <div class="footer">
            <button class="pb-btn-default w-100" routerLink="/recipe-engine">{{'Global.TryNow' | translate | uppercase}}</button>
        </div>
    </div>
</div>

<div *ngIf="startYourRecipeModalIsShowing" id="page-mask"></div>