<div class="navbar">
    <div class="content">
        <div routerLink="/home" class="logo">
            <img src="../../../assets/logos/logo-new-colored.svg" draggable="false" alt="logo">
        </div>
        <div class="hamburger-menu">    
            <img (click)="navbarVisibleEvent()" src="../../../assets/icons/hamburger-menu-icon.svg" draggable="false" alt="hamburger-menu">
        </div>
        <div id="navbarMenu" class="navbar-menu">
            <div class="left-content">
                <div class="items">
                    <div (click)="routerNavigate('how-to-use')" class="item">
                        <span>{{'Navbar.HowIsItWorking' | translate}}</span>
                    </div>
                    <div (click)="routerNavigate('recipes')" class="item">
                        <span>{{'Navbar.Recipes' | translate}}</span>
                    </div>
                    <div (click)="routerNavigate('send-recipe')" class="item">
                        <span>{{'Navbar.ShareARecipe' | translate}}</span>
                    </div>
                    <div (click)="routerNavigate('blogs')" class="item">
                        <span>Blog</span>
                    </div>
                </div>
            </div>
            <div class="right-content">
                <div class="items">
                    <div class="item language" clickOutside (clickOutside)="languageTypeDropDownIsShowing = false">
                        <div (click)="toggleLanguageTypeDropDown()">
                            <img src="../../../assets/icons/language-icon.svg" draggable="false" alt="language-icon">
                            <span>{{currentLanguage.code | uppercase}}</span>
                        </div>
                        <div *ngIf="languageTypeDropDownIsShowing" class="language-type-drop-down">
                            <div class="language-type-drop-down-top">
                                <div class="left">
                                    <img src="../../../assets/icons/language-pink-icon.svg" draggable="false" alt="language-icon">
                                    <span>{{'Navbar.SelectLanguage' | translate}}</span>
                                </div>
                                <!-- <div class="right">
                                    <img (click)="toggleLanguageTypeDropDown()" src="../../../assets/icons/close-circle-gray-icon.svg" draggable="false" alt="close-icon">
                                </div> -->
                            </div>
                            <div class="language-type-drop-down-main">
                                <!-- <div class="search-language">
                                    <input [(ngModel)]="languageSearchText" type="text" placeholder="{{'Navbar.SearchLanguage' | translate}}">
                                </div> -->
                                <div class="languages">
                                    <ul class="hide-scroll">
                                        <li *ngFor="let language of langauges" (click)="changeLanguage(language);languageTypeDropDownIsShowing = false; $event.stopPropagation()">{{language.name}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!userIsAuthenticated" routerLink="/login" class="item login">
                        <img src="../../../assets/icons/user-white-icon.svg" draggable="false" alt="user-icon">
                        <span>{{'Navbar.RegisterLogIn' | translate}}</span>
                    </div>
                    <div  *ngIf="userIsAuthenticated" class="item profile" clickOutside (clickOutside)="profileMenuDropDownIsShowing = false">
                        <div (click)="toggleProfileMenuDropDown()">
                            <img src="../../../assets/icons/user-white-icon.svg" draggable="false" alt="user-icon">
                            <span>{{userBasicInformation.fullName | uppercase}}</span>
                        </div>
                        <div *ngIf="profileMenuDropDownIsShowing" class="profile-menu" novalidate>
                            <div class="top">
                                <img src="../../../assets/icons/user.png" draggable="false" alt="user">
                                <span>{{userBasicInformation.fullName | uppercase}}</span>
                            </div>
                            <!-- <div class="title">
                                <p>Ünvan</p>
                                <p>Master Chef</p>
                            </div> -->
                            <div class="menu-items">
                                <div class="main-items">
                                    <div *ngFor="let menuItem of profileMenuItems" routerLink="/profile/{{menuItem.routerPath}}" (click)="profileMenuDropDownIsShowing = false" class="item">
                                        <div class="icon">
                                            <app-ng-image src="../../../assets/icons/{{menuItem.iconPath}}"></app-ng-image>
                                        </div>
                                        <span>{{'Profile.Menu.' + menuItem.languageName.toString() + '.Title' | translate}}</span>
                                    </div>
                                </div>
                                <div class="bottom-items">
                                    <div class="item" routerLink="/profile/account-settings"  (click)="profileMenuDropDownIsShowing = false">
                                        <div class="icon">
                                            <app-ng-image src="../../../assets/icons/setting-gray-icon.svg"></app-ng-image>
                                        </div>
                                        <span>{{'Profile.Menu.AccountSettings.Title' | translate}}</span>
                                    </div>
                                    <div (click)="logout()" class="item">
                                        <div class="icon">
                                            <app-ng-image src="../../../assets/icons/logout-gray-icon.svg"></app-ng-image>
                                        </div>
                                        <span>{{'Profile.Menu.Exit' | translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div routerLink="/recipe-engine" class="item try-now">
                        <button>{{'Global.TryNow' | translate}}</button>
                    </div>
                </div>
            </div>  
        </div>
    </div>
</div>