import { Guid } from "guid-typescript";
import { FileBase } from "./file/fileBase";

export class BlogImage extends FileBase{

    constructor(id?: number, blogUUId?: Guid, fileName?: string, uniqueName?: Guid, filePath?: string, fileType?: string, date?: Date) {
        super(fileName, uniqueName, filePath, fileType, date);
        
    }

    id: number;
    blogUUId: Guid;

}