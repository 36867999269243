import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ng-link',
  templateUrl: './ng-link.component.html',
  styleUrls: ['./ng-link.component.css']
})
export class NgLinkComponent {

  @Input() text: string;
  @Input() href: string;
  @Input() target: string = "_self";
  @Input() rel: string = "noopener";

}
