import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-data-loading-animation-partial',
  templateUrl: './data-loading-animation-partial.component.html',
  styleUrls: ['./data-loading-animation-partial.component.css']
})
export class DataLoadingAnimationPartialComponent {

  @Input() loadingText: string = "Yükleniyor...";

}
