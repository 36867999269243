import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class EncryptionService {

  secretKey: string = 'K`<RNVWbKj,(=+^b$^Ar4.1-UUUpVD?XMa]Aj!U4F3YRV3{k1qdCRecipeExpert2024*Heetsoft?';

  encrypt(text: string): string {
    const encrypted = CryptoJS.AES.encrypt(text, this.secretKey).toString();
    return encrypted;
  }

  decrypt(encryptedText: string): string {
    const decrypted = CryptoJS.AES.decrypt(encryptedText, this.secretKey);
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

}
