import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeedbackService } from 'src/app/services/feedback.service';
import { HelpersFactoryService } from 'src/app/services/helpers-factory.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  
  kvkkFormIsChecked: boolean = false;

  contactUsForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private HelpersFactoryService: HelpersFactoryService,
    private feedbackService: FeedbackService) { }

  ngOnInit(): void {
    this.createContactUsForm();
  }

  sendContactUsForm(){
    if(this.contactUsForm.valid){
      if(this.kvkkFormIsChecked){
        this.HelpersFactoryService.spinnerService.show();
        let model = Object.assign({}, this.contactUsForm.value);
        model.id = 0;
        model.date = new Date();
        this.feedbackService.add(model).subscribe({
          next: (response => {
            this.HelpersFactoryService.spinnerService.hide();
            this.HelpersFactoryService.toastService.success("Mesajınız başarılı bir şekilde gönderildi.")
            this.contactUsForm.reset();
            this.toggleKvkkForm();
          }),
          error: (response => {
            this.HelpersFactoryService.spinnerService.hide();
          })
        })
      }
      else{
        this.HelpersFactoryService.toastService.warning("KVKK metnini onaylamadınız");
      }
    }
    else{
      this.HelpersFactoryService.toastService.warning("Lütfen tüm alanları eksiksiz ve doğru bir şekilde doldurunuz");
    }
    
  }

  createContactUsForm(){
    this.contactUsForm = this.formBuilder.group({
      id: [0],
      name: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      message: ["", Validators.required],
      date: [new Date()],
      kvkkIsChecked: [false, Validators.required]
    })
  }

  toggleKvkkForm(){
    this.kvkkFormIsChecked = !this.kvkkFormIsChecked;
  }

}
