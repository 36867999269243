import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginateResponseModel } from '../models/responses/paginateResponseModel';
import { Feedback } from '../models/entities/feedback';
import { PageRequest } from '../models/paginates/pageRequest';
import { ResponseModel } from '../models/responseModel';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  getList(pageRequest?: PageRequest): Observable<PaginateResponseModel<Feedback>>{
    let newPath = `${this.apiUrl}Feedbacks/GetList`;
    return this.httpClient.get<PaginateResponseModel<Feedback>>(newPath, {params: {...pageRequest}});
  }

  add(user: Feedback): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}Feedbacks/Add`;
    return this.httpClient.post<ResponseModel>(newPath, user);
  }

}
