import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-navbar',
  templateUrl: './dashboard-navbar.component.html',
  styleUrls: ['./dashboard-navbar.component.css']
})

export class DashboardNavbarComponent {

  constructor() {
        
  }

}
