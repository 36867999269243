export class GeoLocation {

    constructor(id?: any, country?: any, countryCode?: any, city?: any, latitude?: any, longitude?: any, date?: any){
        this.id = id;
        this.country = country;
        this.countryCode = countryCode;
        this.city = city;
        this.latitude = latitude;
        this.longitude = longitude;
        this.date = date;
    }

    id: number;
    country: string | null;
    countryCode: string | null;
    city: string | null;
    latitude: string | null;
    longitude: string | null;
    date: string | null;
}