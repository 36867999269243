import { Injectable } from '@angular/core';
import { ToastrModuleService } from './toastr-module.service';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor(private toastrModuleService: ToastrModuleService) { }

  copyTextToClipboard(text: any){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastrModuleService.success("Panoya kopyalandı");
  }
}
