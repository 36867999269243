import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SingleResponseModel } from '../models/singleResponseModel';
import { RecipeRequestDto } from '../models/recipeRequestDto';
import { ChatGPTResponseDto } from '../models/chatGPTResponseDto';
import { ChatGptRecipeResultDto } from '../models/chatGptRecipeResultDto';
import { SimpleRecipeRequestDto } from '../models/simpleRecipeRequestDto';

@Injectable({
  providedIn: 'root'
})
export class RecipeEngineAIService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  chat(prompt: string): Observable<SingleResponseModel<ChatGPTResponseDto>>{
    let newPath = `${this.apiUrl}RecipeEngineAI/Chat?prompt=${prompt}`
    return this.httpClient.get<SingleResponseModel<ChatGPTResponseDto>>(newPath);
  }

  recipeRequest(recipeRequest: RecipeRequestDto): Observable<SingleResponseModel<ChatGptRecipeResultDto>>{
    let newPath = `${this.apiUrl}RecipeEngineAI/RecipeRequest`;
    return this.httpClient.post<SingleResponseModel<ChatGptRecipeResultDto>>(newPath, recipeRequest);
  }

  simpleRecipeRequest(recipeRequest: SimpleRecipeRequestDto): Observable<SingleResponseModel<ChatGptRecipeResultDto>>{
    let newPath = `${this.apiUrl}RecipeEngineAI/SimpleRecipeRequest`;
    return this.httpClient.post<SingleResponseModel<ChatGptRecipeResultDto>>(newPath, recipeRequest);
  }
}
