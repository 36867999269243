export class Visitor{
    constructor(id?: number, geoLocationId?: number, ipAddress?: string, date?: Date){
        this.id = id;
        this.geoLocationId = geoLocationId;
        this.ipAddress = ipAddress;
        this.date = date;
    }

    id: number;
    geoLocationId: number;
    ipAddress: string;
    date: Date;
}