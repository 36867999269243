import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { PageTitleService } from './page-title.service';
import { LocalStorageService } from './local-storage.service';
import { ToastrModuleService } from './toastr-module.service';
import { SpinnerModuleService } from './spinner-module.service';
import { MediaServerUrl } from '../constants/serverConstant';
import { filter } from 'rxjs';
import { RouterName } from '../constants/routerName';

@Injectable({
  providedIn: 'root'
})

export class HelpersFactoryService {

  pageTitleService: PageTitleService;
  localStorageService: LocalStorageService;
  toastService: ToastrModuleService;
  spinnerService: SpinnerModuleService;
  router: Router;

  constructor(private pageTitleModuleService: PageTitleService,
    private localStorageModuleService: LocalStorageService, 
    private routerModule: Router, 
    private toastServiceModule: ToastrModuleService,
    private spinnerServiceModule: SpinnerModuleService) {
    this.pageTitleService = pageTitleModuleService;
    this.toastService = toastServiceModule;
    this.spinnerService = spinnerServiceModule;
    this.localStorageService = localStorageModuleService;
    this.router = routerModule
  }

  routerNavigate(routeName: any){
    this.router.navigate([routeName]);
  }

  navigateToLastRoute(){
    let lastRoute = this.getLastRoute();
    if(lastRoute){
      this.routerNavigate(lastRoute);
    }
    else{
      this.routerNavigate("/home");
    }
  }

  refreshPage(){
    window.location.reload();
  }

  getMediaFromMediaServer(mediaPath: any){
    return `${this.getMediaServerUrl()}assets/${mediaPath}`;
  }

  getMediaServerUrl(){
    return MediaServerUrl.url;
  }

  saveLastRoute(){
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event) => {
      let navigation = event as NavigationStart;
      if(!navigation.url.includes("login")){
        this.localStorageModuleService.setItem("lastRoute", navigation.url);
      }
    });
  }

  getLastRoute(){
    return this.localStorageModuleService.getItem("lastRoute");
  }

  loginBefore(){
    this.routerNavigate(RouterName.layout.emptyLayout.login);
    this.toastService.warning("Önce giriş yapmalısınız");
  }

}
