import { RouterName } from './../../../constants/routerName';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PasswordResetNewPasswordDto } from 'src/app/models/passwordReset/passwordResetNewPasswordDto';
import { PasswordResetLink } from 'src/app/models/passwordResetLink/passwordResetLink';
import { HelpersFactoryService } from 'src/app/services/helpers-factory.service';
import { PasswordResetLinkService } from 'src/app/services/password-reset-link.service';

@Component({
  selector: 'app-dashboard-password-reset-new-password',
  templateUrl: './dashboard-password-reset-new-password.component.html',
  styleUrls: ['./dashboard-password-reset-new-password.component.css']
})
export class DashboardPasswordResetNewPasswordComponent implements OnInit {

  linkId: any;
  passwordResetLink: PasswordResetLink;
  passwordResetLinkErrorMessage: string;
  passwordResetNewPasswordForm: FormGroup;

  passwordResetLinkIsActive: boolean = false;

  RouterName = RouterName;

  constructor(private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private passwordResetLinkService: PasswordResetLinkService,
    private helpersService: HelpersFactoryService){

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params['linkId']) {
        this.linkId = params['linkId'];
        this.checkPasswordResetLink(this.linkId);
      }
    });
  }

  updatePassword(){
    if(this.passwordResetNewPasswordForm.valid){
      this.helpersService.spinnerService.show();
      let model = Object.assign({}, this.passwordResetNewPasswordForm.value);
      let passwordResetNewPasswordDto = new PasswordResetNewPasswordDto();
      passwordResetNewPasswordDto.passwordResetLink = this.passwordResetLink;
      passwordResetNewPasswordDto.newPassword = model["newPassword"];
      passwordResetNewPasswordDto.newPasswordConfirm = model["newPasswordConfirm"];
      this.passwordResetLinkService.updatePassword(passwordResetNewPasswordDto).subscribe({
        next: (response => {
          this.helpersService.spinnerService.hide();
          this.helpersService.toastService.success(response.message);
          this.helpersService.routerNavigate(RouterName.dashboard.login.adminLogin);
        }),
        error: (error => {
          this.helpersService.spinnerService.hide();
          this.helpersService.toastService.error(error.error.message);
        })
      })
    }
  }


  checkPasswordResetLink(linkId: any){
    this.helpersService.spinnerService.show();
    this.passwordResetLinkService.checkPasswordResetLink(linkId).subscribe({
      next: (response => {
        this.helpersService.spinnerService.hide();
        this.passwordResetLink = response.data;
        this.passwordResetLinkIsActive = true;
        this.createNewPasswordForm();
      }),
      error: (error => {
        this.helpersService.spinnerService.hide();
        this.helpersService.toastService.error(error.error.message)
        this.passwordResetLinkErrorMessage = error.error.message;
      })
    })
  }

  createNewPasswordForm(){
    this.passwordResetNewPasswordForm = this.formBuilder.group({
      newPassword: ["", [Validators.required]],
      newPasswordConfirm: ["", [Validators.required]]
    });
  }

}
