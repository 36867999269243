import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { BlogLike } from '../models/blogLike';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/responseModel';

@Injectable({
  providedIn: 'root'
})
export class BlogLikeService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  add(blogLike: BlogLike): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}BlogLikes/Add`;
    return this.httpClient.post<ResponseModel>(newPath, blogLike);
  }

  delete(blogLike: BlogLike): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}BlogLikes/Delete`;
    return this.httpClient.post<ResponseModel>(newPath, blogLike);
  }

}
