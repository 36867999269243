import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModuleService } from 'src/app/services/translate-module.service';

@Component({
  selector: 'app-how-to-use-video',
  templateUrl: './how-to-use-video.component.html',
  styleUrls: ['./how-to-use-video.component.css']
})
export class HowToUseVideoComponent implements OnInit {

  tourVideoSoruce: string;

  @Input() tourVideoIsShowing: boolean = false;
  @Output() tourVideoIsShowingOutput = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    this.getTourVideoSource();
  }

  getTourVideoSource(){
    let language = TranslateModuleService.currentLanguage;
    if(language.code == "tr"){
      this.tourVideoSoruce = "../../../assets/videos/tour_tr.mp4"
    }
    else{
      this.tourVideoSoruce = "../../../assets/videos/tour_en.mp4"
    }
  }

  closeVideo(){
    this.tourVideoIsShowing = false;
    this.tourVideoIsShowingOutput.emit(false);
  }

}
