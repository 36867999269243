import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';
import { BlogTextEditorComponent } from './components/blog-text-editor/blog-text-editor.component';
import { BlogsComponent } from './components/blogs/blogs.component';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';
import { DashboardHomeComponent } from './components/dashboard/dashboard-home/dashboard-home.component';
import { DashboardLoginComponent } from './components/dashboard/dashboard-login/dashboard-login.component';
import { DashboardPasswordResetNewPasswordComponent } from './components/dashboard/dashboard-password-reset-new-password/dashboard-password-reset-new-password.component';
import { DashboardPasswordResetRequestComponent } from './components/dashboard/dashboard-password-reset-request/dashboard-password-reset-request.component';
import { HomeComponent } from './components/home/home.component';
import { HowToUseComponent } from './components/how-to-use/how-to-use.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RecipeEngineComponent } from './components/recipe-engine/recipe-engine.component';
import { RecipesDetailComponent } from './components/recipes-detail/recipes-detail.component';
import { RecipesComponent } from './components/recipes/recipes.component';
import { SendRecipeComponent } from './components/send-recipe/send-recipe.component';
import { PageTitle } from './constants/pageTitle';
import { RouterName } from './constants/routerName';
import { AdminLoginGuard } from './guards/admin-login.guard';
import { BlogTextEditorGuard } from './guards/blog-text-editor.guard';
import { LoginGuard } from './guards/login.guard';
import { DashboardAdminLayoutComponent } from './shared/layouts/dashboard-admin-layout/dashboard-admin-layout.component';
import { DashboardLoginLayoutComponent } from './shared/layouts/dashboard-login-layout/dashboard-login-layout.component';
import { EmptyLayoutComponent } from './shared/layouts/empty-layout/empty-layout.component';
import { MainLayoutComponent } from './shared/layouts/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: RouterName.layout.mainLayout.home,
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: RouterName.layout.mainLayout.home, component: HomeComponent, title: PageTitle.layouts.mainLayout.home},
      { path: RouterName.layout.mainLayout.recipeEngine, component: RecipeEngineComponent, title: PageTitle.layouts.mainLayout.recipeEngine},
      { path: RouterName.layout.mainLayout.cookiesPolicy, component: CookiesPolicyComponent, title: PageTitle.layouts.mainLayout.cookiesPolicy},
      { path: RouterName.layout.mainLayout.blogs, component: BlogsComponent, title: PageTitle.layouts.mainLayout.blogs},
      { path: RouterName.layout.mainLayout.blogsWithPage, component: BlogsComponent, title: PageTitle.layouts.mainLayout.blogs},
      { path: RouterName.layout.mainLayout.blogDetail, component: BlogDetailComponent},
      { path: RouterName.layout.mainLayout.recipes, component: RecipesComponent, title: PageTitle.layouts.mainLayout.recipes},
      { path: RouterName.layout.mainLayout.recipeDetail, component: RecipesDetailComponent},
      { path: RouterName.layout.mainLayout.sendRecipe, component: SendRecipeComponent, title: PageTitle.layouts.mainLayout.sendRecipe, canActivate: [LoginGuard]},
      { path: RouterName.layout.mainLayout.howToUse, component: HowToUseComponent, title: PageTitle.layouts.mainLayout.howToUse},
      { path: RouterName.layout.mainLayout.profile, component: ProfileComponent, title: PageTitle.layouts.mainLayout.profile, canActivate: [LoginGuard]}
    ]
  },
  {
    path: '',
    component: EmptyLayoutComponent,
    children: [
      { path: RouterName.layout.emptyLayout.blogTextEditorUpdate, component: BlogTextEditorComponent, title: PageTitle.layouts.emptyLayout.blogTextEditor, canActivate: [BlogTextEditorGuard]},
      { path: RouterName.layout.emptyLayout.blogTextEditorNew, component: BlogTextEditorComponent, title: PageTitle.layouts.emptyLayout.blogTextEditor, canActivate: [BlogTextEditorGuard]},
      { path: RouterName.layout.emptyLayout.login, component: LoginComponent, title: PageTitle.layouts.emptyLayout.login}
    ]
  },
  {
    path: '',
    component: DashboardLoginLayoutComponent,
    children: [
      { path: RouterName.dashboard.login.adminLogin, component: DashboardLoginComponent, title: PageTitle.layouts.dashboardLoginLayout.adminLoginPanel},
      { path: RouterName.dashboard.passwordReset.passwordResetRequest, component: DashboardPasswordResetRequestComponent, title: PageTitle.layouts.dashboardLoginLayout.passwordReset},
      { path: RouterName.dashboard.passwordReset.newPassword, component: DashboardPasswordResetNewPasswordComponent, title: PageTitle.layouts.dashboardLoginLayout.newPassword}
    ]
  },
  {
    path: '',
    component: DashboardAdminLayoutComponent,
    canActivate: [AdminLoginGuard],
    children: [
      { path: RouterName.dashboard.home, component: DashboardHomeComponent, title: PageTitle.layouts.dashboardAdminLayout.dashboard},
    ]
  },
  { path: '**', component: PageNotFoundComponent, title: PageTitle.layouts.globalLayout.pageNotFound }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
