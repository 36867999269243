import { HelpersFactoryService } from './../../../services/helpers-factory.service';
import { RouterName } from './../../../constants/routerName';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserTypeEnum } from 'src/app/enums/user/userTypeEnum';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Messages } from 'src/app/constants/messages';
import { GeoLocationService } from 'src/app/services/geo-location.service';
import { GeoLocation } from 'src/app/models/entities/geoLocation';
import { UserLoginLog } from 'src/app/models/entities/userLoginLog';
import { UserLoginLogService } from 'src/app/services/user-login-log.service';

@Component({
  selector: 'app-dashboard-login',
  templateUrl: './dashboard-login.component.html',
  styleUrls: ['./dashboard-login.component.css']
})
export class DashboardLoginComponent implements OnInit{

  loginForm: FormGroup;

  RouterName = RouterName;

  constructor(
    private formBuilder: FormBuilder, 
    private helpersFactoryService: HelpersFactoryService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private geoLocationService: GeoLocationService,
    private userLoginLogService: UserLoginLogService){

  }

  ngOnInit(): void {
    this.createLoginForm();
  }

  login(){
    if(this.loginForm.valid){
      this.helpersFactoryService.spinnerService.show();
      let model = Object.assign({}, this.loginForm.value);
      this.authService.userLogin(model).subscribe({
        next: (response => {
          this.helpersFactoryService.spinnerService.hide();
          this.toastrService.success(response.message, Messages.successfully);
          this.saveClientGeoLocation(response.data.userBasicInformation.id);
          this.helpersFactoryService.localStorageService.setAuthenticatedUserInformations(response.data);
          this.helpersFactoryService.routerNavigate(RouterName.dashboard.home);
        }),
        error: (error => {
          this.helpersFactoryService.spinnerService.hide();
          this.toastrService.error(error.error.message, Messages.error);
        })
      });
    }
  }

  saveClientGeoLocation(userId: any){
    this.geoLocationService.getClientGeoLocation().subscribe({
      next: (response => {
        let geoLocation = new GeoLocation(0, response.country_name, response.country_code, response.city, String(response.latitude).toString(), String(response.longitude).toString(), new Date());
        this.geoLocationService.add(geoLocation).subscribe({
          next: (resultResponse => {
              let userLoginLog = new UserLoginLog(0, userId, resultResponse.data.id, new Date());
              this.userLoginLogService.add(userLoginLog).subscribe({});
          })
        });
      })
    });
  }

  createLoginForm(){
    this.loginForm = this.formBuilder.group({
      userTypeId: [UserTypeEnum.Admin, Validators.required],
      email: ["", [Validators.email, Validators.required]],
      password: ["", Validators.required]
    });
  }
}
