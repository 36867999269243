import { HelpersFactoryService } from 'src/app/services/helpers-factory.service';
import { ClipboardService } from './../../services/clipboard.service';
import { UploadFileHelperService } from './../../services/upload-file-helper.service';
import { BlogCategoryService } from './../../services/blog-category.service';
import { Language } from './../../models/language';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Messages } from 'src/app/constants/messages';
import { BlogCategory } from 'src/app/models/blogCategory';
import { BlogDetailDto } from 'src/app/models/blogDetailDto';
import { BlogService } from 'src/app/services/blog.service';
import { UploadFile } from 'src/app/models/uploadFile/uploadFile';
import { Guid } from 'guid-typescript';
import { UploadedBlogImage } from 'src/app/models/blog/blogImage/uploadedBlogImage';
import { BlogImageService } from 'src/app/services/blog-image.service';
import { BlogImage } from 'src/app/models/blogImage';
import { Blog } from 'src/app/models/blog';
import { MediaServerUrl } from 'src/app/constants/serverConstant';
import { PageTitle } from 'src/app/constants/pageTitle';

@Component({
  selector: 'app-blog-text-editor',
  templateUrl: './blog-text-editor.component.html',
  styleUrls: ['./blog-text-editor.component.css']
})

export class BlogTextEditorComponent implements OnInit {

  blog: BlogDetailDto;

  newBlogForm: FormGroup;

  languages: Language[] = [];
  currentLanguage: Language;

  blogCategories: BlogCategory[] = [];
  currentBlogCategory: BlogCategory;

  uploadedBlogMainImage: UploadFile;
  uploadedBlogImages: UploadedBlogImage[] = [];

  blogUUId: any;

  MediaServerUrl = MediaServerUrl;

  textEditorContent: string;

  constructor(private activatedRoute: ActivatedRoute,
    private helpersFactoryService: HelpersFactoryService,
    private clipboardService: ClipboardService,
    private blogService: BlogService,
    private formBuilder: FormBuilder,
    private blogCategoryService: BlogCategoryService,
    private blogImageService: BlogImageService,
    public UploadFileHelperService: UploadFileHelperService) {
      this.uploadedBlogMainImage = new UploadFile();
      this.blogUUId =  Guid.create().toString();
    }

  ngOnInit(): void {
    
    this.helpersFactoryService.pageTitleService.setTitleWithCanonical(PageTitle.layouts.emptyLayout.blogTextEditor);

    this.activatedRoute.params.subscribe((params) => {
      if (params['blogId']) {
        this.getBlogById(params['blogId'])
      }
    });
    
    
    this.createNewBlogForm();
    this.getBlogCategories();
    this.getLanguages();
  }

  getBlogById(blogId: number){
    this.helpersFactoryService.spinnerService.show();
    this.blogService.getById(blogId).subscribe({
      next: (response => {
        this.blog = response.data;
        this.helpersFactoryService.spinnerService.hide();
        this.textEditorContent = response.data.explanation;
      }),
      error: (error => {
        this.helpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
        this.helpersFactoryService.spinnerService.hide();
      })
    });
  }

  getBlogCategories(){
    this.blogCategoryService.getAll().subscribe({
      next: (response => {
        this.blogCategories = response.data;
      })
    })
  }

  getLanguages(){
    let names = ["Türkçe", "İngilizce"];
    let codes = ["tr", "en"];
    for (let i = 0; i < names.length; i++) {
      let language = new Language(i + 1, names[i], codes[i]);
      this.languages.push(language);
    }
    return this.languages;
  }

  addNewBlog(){
    if(this.checkNewBlogIsCorrect()){
      if(this.newBlogForm.valid){
        this.helpersFactoryService.spinnerService.show();

        let newBlogModel = Object.assign({}, this.newBlogForm.value);

        let blog = new Blog();
        blog.blogUUId = this.blogUUId;
        blog.blogCategoryId = this.currentBlogCategory.id;
        blog.language = this.currentLanguage.code;
        blog.title = newBlogModel["title"];
        blog.explanation = this.textEditorContent;

        let formData = new FormData();
        formData.append('blogMainImage', this.uploadedBlogMainImage.uploadedFile);
        formData.append('blog', JSON.stringify(blog));

        this.blogService.add(formData).subscribe({
          next: (response => {
            this.helpersFactoryService.spinnerService.hide();
            this.helpersFactoryService.toastService.success("Blog başarılı bir şekilde eklendi.");
            window.location.reload();
          }),
          error: (error => {
            this.helpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
            this.helpersFactoryService.spinnerService.hide();
          })
        });
      }
      else{
        this.helpersFactoryService.toastService.warning("Lütfen gerekli alanları eksiksiz ve doğru bir şekilde doldurun");
      }
    }
  }

  updateBlogExplanation(){
    if(this.textEditorContent.length > 0){
      this.helpersFactoryService.spinnerService.show();
      let blogModel = new Blog();
      blogModel.id = this.blog.id;
      blogModel.explanation = this.textEditorContent;
      this.blogService.updateExplanation(blogModel).subscribe({
        next: (response => {
          this.helpersFactoryService.spinnerService.hide();
          this.helpersFactoryService.toastService.success("Blog başarılı bir şekilde güncellendi.");
          window.location.reload();
        }),
        error: (error => {
          this.helpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
          this.helpersFactoryService.spinnerService.hide();
        })
      });
    }
  }

  uploadBlogContentImage(uploadedBlogImage: UploadedBlogImage){
    this.helpersFactoryService.spinnerService.show();
    let formData = new FormData();
    formData.append('image', uploadedBlogImage.uploadedFile);
    formData.append('blogUUId', JSON.stringify(this.blogUUId));

    this.blogImageService.add(formData).subscribe({
      next: (response => {
        this.helpersFactoryService.spinnerService.hide();
        this.helpersFactoryService.toastService.success(response.message);
        uploadedBlogImage.id = response.data.id;
        uploadedBlogImage.isSaved = true;
        uploadedBlogImage.filePath = response.data.filePath;
      }),
      error: (error => {
        this.helpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
        this.helpersFactoryService.spinnerService.hide();
      })
    });
  }

  deleteUploadedBlogImage(uploadedBlogImage: UploadedBlogImage){
    let blogImage = new BlogImage();
    blogImage.id = uploadedBlogImage.id;
    blogImage.filePath = uploadedBlogImage.filePath;
    this.helpersFactoryService.spinnerService.show();
    this.blogImageService.delete(blogImage).subscribe({
      next: (response => {
        this.uploadedBlogImages = this.uploadedBlogImages.filter(bi => bi.id != uploadedBlogImage.id);
        this.helpersFactoryService.spinnerService.hide();
        this.helpersFactoryService.toastService.success();
      }),
      error: (error => {
        this.helpersFactoryService.spinnerService.hide();
        this.helpersFactoryService.toastService.error();
      })
    })
  }

  checkNewBlogIsCorrect(): boolean{
    if(!this.uploadedBlogMainImage.isUploadFile){
      this.helpersFactoryService.toastService.warning("Blog ana fotoğrafı boş bırakılamaz!");
      return false;
    }
    if(this.currentLanguage == undefined || this.currentLanguage.id <= 0){
      this.helpersFactoryService.toastService.warning("Blog dili boş bırakılamaz!");
      return false;
    }
    if(this.currentBlogCategory == undefined || this.currentBlogCategory.id <= 0){
      this.helpersFactoryService.toastService.warning("Blog kategorisi boş bırakılamaz!");
      return false;
    }
    return true;
  }

  copyTextToClipboard(text: string){
    this.clipboardService.copyTextToClipboard(`${this.MediaServerUrl.url}assets/${text}`);
  }

  createNewBlogForm(){
    this.newBlogForm = this.formBuilder.group({
      title: ["", Validators.required],
    });
  }
}
