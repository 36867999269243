import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { SingleResponseModel } from '../models/singleResponseModel';
import { Observable } from 'rxjs';
import { SystemRecipeDetailDto } from '../models/systemRecipeDetailDto';
import { ResponseModel } from '../models/responseModel';

@Injectable({
  providedIn: 'root'
})
export class SystemRecipeService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }
  
  add(recipe: any): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}SystemRecipes/Add`;
    return this.httpClient.post<ResponseModel>(newPath, recipe);
  }

  getSystemRecipeDetailById(recipeId: any): Observable<SingleResponseModel<SystemRecipeDetailDto>>{
    let newPath = `${this.apiUrl}SystemRecipes/GetSystemRecipeDetailById?recipeId=${recipeId}`;
    return this.httpClient.get<SingleResponseModel<SystemRecipeDetailDto>>(newPath);
  }

}