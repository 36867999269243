import { Guid } from "guid-typescript";

export class Blog{
    id: number; 
    blogUUId: Guid;
    blogCategoryId: number;
    blogImageId: number;
    title: string;
    explanation: string;
    date: Date;
    language: string;
    state: Boolean;
}