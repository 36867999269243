<div class="send-recipe">
    <div class="main-content">
        <form [formGroup]='recipeForm' *ngIf="recipeForm">
            <div class="section recipe-name">
                <h2 class="section-title"><span>1. {{'SendRecipe.Stage' | translate}}:</span> {{'SendRecipe.RecipeNameSection.Title' | translate}}</h2>
                <div class="content">
                    <input type="text" placeholder="{{'SendRecipe.RecipeNameSection.InputText' | translate}}" formControlName="recipeName" required="required">
                </div>
            </div>
            <div class="section recipe-image">
                <h2 class="section-title"><span>2. {{'SendRecipe.Stage' | translate}}:</span> {{'SendRecipe.RecipeImageSection.Title' | translate}}</h2>
                <div class="content">
                    <form [formGroup]='recipeImageUploadForm' *ngIf="recipeImageUploadForm">
                        <div *ngIf="isUploadedImage" class="recipe-image">
                            <img [src]="image" draggable="false" alt="recipe-image">
                            <div class="mt-4">
                                <button (click)="selectImage.click()" class="pb-btn-outlined"><img class="icon-left" src="../../../assets/icons/reload-pink-icon.svg" draggable="false" alt="reload"> Yeniden yükle</button>
                            </div>
                        </div>
                        <div *ngIf="!isUploadedImage" (click)="selectImage.click()" class="empty-image-area">
                            <img src="../../../assets/icons/image-cartoon-icon.svg" draggable="false" alt="image-cartoon-icon">
                            <p class="first">{{'SendRecipe.RecipeImageSection.DescriptionOne' | translate}}</p>
                            <p class="second">{{'SendRecipe.RecipeImageSection.DescriptionTwo' | translate}}</p>
                        </div>
                        <input type="file" id="imageUploadInput" name="profile" (change)="onFileSelect($event)" accept="image/png, image/jpg, image/jpeg" #selectImage/>
                    </form>
                </div>
            </div>
            <div class="section ingredients">
                <h2 class="section-title"><span>3. {{'SendRecipe.Stage' | translate}}:</span> {{'SendRecipe.IngredientsSection.Title' | translate}}</h2>
                <div class="content">
                    <div *ngIf="addedIngredients.length > 0" class="added-items">
                        <div *ngFor="let item of addedIngredients" class="item">
                            <div class="item-info">
                                <p><span>{{'SendRecipe.IngredientsSection.IngredientName2' | translate}}:</span> {{item.ingredientName}}</p>
                                <p><span>{{'SendRecipe.IngredientsSection.Measurement' | translate}}:</span> {{item.measurement}}</p>
                                <p><span>{{'SendRecipe.IngredientsSection.TypeOfAdding2' | translate}}:</span> {{item.typeOfAdd}}</p>
                            </div>
                            <div class="item-edit">
                                <div (click)="removeIngredient(item)" class="remove">
                                    <img src="../../../assets/icons/trash-pink-icon.svg" draggable="false" alt="trash">
                                    <span>{{'SendRecipe.Remove' | translate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form [formGroup]='addIngredientForm' *ngIf="addIngredientForm">
                        <div class="form-group">
                            <input type="text" placeholder="{{'SendRecipe.IngredientsSection.IngredientName' | translate}}" formControlName="ingredientName" required="required">
                        </div>
                        <div class="row form-group">
                            <div class="col-6">
                                <input type="text" placeholder="{{'SendRecipe.IngredientsSection.MeasurementAdd' | translate}}" formControlName="measurement">
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="{{'SendRecipe.IngredientsSection.TypeOfAdding' | translate}}" formControlName="typeOfAdd">
                            </div>
                        </div>
                        <button (click)="addIngredient()" class="pb-btn-outlined w-100 mt-2"><img class="icon-left" src="../../../assets/icons/plus-pink-icon.svg" draggable="false" alt="plus-icon"> {{'SendRecipe.IngredientsSection.AddIngredient' | translate}}</button>
                    </form>
                </div>
            </div>
            <div class="section how-to-make">
                <h2 class="section-title"><span>4. {{'SendRecipe.Stage' | translate}}:</span> {{'SendRecipe.StepsSection.Title' | translate}}</h2>
                <div class="content">
                    <div *ngIf="addedRecipeSteps.length > 0" class="added-items">
                        <div *ngFor="let item of addedRecipeSteps; let i = index" class="item">
                            <div class="item-info">
                                <p><span>{{i + 1}}. {{'SendRecipe.StepsSection.Step' | translate}}:</span> {{item}}</p>
                            </div>
                            <div class="item-edit">
                                <div (click)="removeRecipeStep(i)" class="remove">
                                    <img src="../../../assets/icons/trash-pink-icon.svg" draggable="false" alt="trash">
                                    <span>{{'SendRecipe.Remove' | translate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form [formGroup]='addRecipeStepFrom' *ngIf="addRecipeStepFrom">
                        <div class="form-group">
                            <input type="text" placeholder="{{addedRecipeSteps.length + 1}}. {{'SendRecipe.StepsSection.Step' | translate}}" formControlName="step" required="required">
                        </div>
                        <button (click)="addRecipeStep()" class="pb-btn-outlined w-100 mt-2"><img class="icon-left" src="../../../assets/icons/plus-pink-icon.svg" draggable="false" alt="plus-icon"> {{'SendRecipe.StepsSection.AddStep' | translate}}</button>    
                    </form>
                </div>
            </div>
            <div class="section trick">
                <h2 class="section-title"><span>5. {{'SendRecipe.Stage' | translate}}:</span> {{'SendRecipe.TrickSection.Title' | translate}}</h2>
                <div class="content">
                    <input type="text" placeholder="{{'SendRecipe.TrickSection.InputText' | translate}}" formControlName="trickText" required="trickText">
                </div>
            </div>
            <div class="section recipe-categorized">
                <h2 class="section-title"><span>6. {{'SendRecipe.Stage' | translate}}:</span> {{'SendRecipe.CategorizationSection.Title' | translate}}</h2>
                <div class="content">
                    <input type="text" placeholder="{{'SendRecipe.CategorizationSection.InputText' | translate}}" formControlName="categorization" required="required">
                </div>
            </div>
            <div class="section recipe-average-cooking-time">
                <h2 class="section-title"><span>7. {{'SendRecipe.Stage' | translate}}:</span> {{'SendRecipe.AverageCookingTime.Title' | translate}}</h2>
                <div class="content">
                    <input type="number" placeholder="{{'SendRecipe.AverageCookingTime.InputText' | translate}}" formControlName="averageCookingTime" required="required">
                </div>
            </div>
            <div class="action-buttons">
                <button (click)="sendRecipe()" class="pb-btn-default btn-send-recipe">{{'SendRecipe.SendRecipeToConfirmation' | translate}}</button>
                <!-- <button class="pb-btn-outlined btn-save-recipe-to-task">{{'SendRecipe.SaveAsDraft' | translate}}</button> -->
            </div>
        </form>
    </div>
</div>