export class Paginate<T>{

    constructor(index: number = 0, size: number = 25){
        this.index = index;
        this.size = size;
    }

    from: number;
    index: number = 0;
    size: number = 25;
    count: number;
    pages: number;
    items: T[];
    hasPrevious: boolean;
    hasNext: boolean;
}