import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-blue',
  templateUrl: './spinner-blue.component.html',
  styleUrls: ['./spinner-blue.component.css', './spinner-blue.component.scss']
})
export class SpinnerBlueComponent {

  @Input() size: number = 65;

}
