<div class="login-register">
    <div class="content">
        <div class="top">
            <div routerLink="/home" class="logo">
                <img src="../../../assets/logos/logo-new-white.svg" draggable="false" alt="pibean-logo">
            </div>
        </div>
        <div class="main-content">
            <div>
                <p *ngIf="mainTab == tabEnums.Login" class="title">{{'Login.LoginTab.Title' | translate}}</p>
                <p *ngIf="mainTab == tabEnums.Register" class="title">{{'Login.RegisterTab.Title' | translate}}</p>
            </div>
            <div *ngIf="currentTab == tabEnums.LoginStart" class="login-register-types">
                <div (click)="setCurrentTab(tabEnums.Login)" class="type">
                    <img src="../../../assets/icons/email-border-black-icon.svg" draggable="false" alt="email-icon">
                    <span>{{'Login.LoginTab.SignInWithEmail' | translate}}</span>
                </div>
                <div class="google-auth-login">
                    <asl-google-signin-button type="standard" size="large" shape="pill" ></asl-google-signin-button>
                </div>
            </div>
            <div *ngIf="currentTab == tabEnums.RegisterStart" class="login-register-types">
                <!-- <div class="type">
                    <img src="../../../assets/icons/google-colorful-icon.svg" draggable="false" alt="google-icon">
                    <span>Google ile devam et</span>
                </div> -->
                <div (click)="setCurrentTab(tabEnums.Register)" class="type">
                    <img src="../../../assets/icons/email-border-black-icon.svg" draggable="false" alt="email-icon">
                    <span>{{'Login.RegisterTab.SignUpWithEmail' | translate}}</span>
                </div>
                <div class="google-auth-login">
                    <asl-google-signin-button type="standard" size="large" shape="pill" text="continue_with"></asl-google-signin-button>
                </div>
            </div>
            <div class="login-and-register-container">
                <!-- Login Form (Start) -->
                <div *ngIf="currentTab == tabEnums.Login" class="login-container">
                    <form [formGroup]='loginForm' *ngIf="loginForm">
                        <div class="login-form">
                            <div class="form-group">
                                <div class="pb-input-box">
                                    <input type="email" id="email" autocomplete="off" formControlName="email" required="required" [class.input-error]="loginForm.get('email').invalid && loginForm.get('email').touched">
                                    <span>{{'Global.FormItems.Email' | translate}}</span>
                                </div>
                                <div class="form-group-validation-container" *ngIf="loginForm.get('email')?.invalid && loginForm?.get('email')?.errors && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched)">
                                    <small class="text-danger" *ngIf="loginForm.get('email')?.hasError('required')">
                                      * {{'Global.FormItems.PleaseFillThisArea' | translate}}
                                    </small>
                                    <small class="text-danger" *ngIf="loginForm.get('email')?.hasError('email') && loginForm.get('email').touched">
                                      * {{'Global.FormItems.PleaseEnterAValidEmail' | translate}}
                                    </small>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="pb-input-box">
                                    <input [type]="passwordVisibilityType" id="password" formControlName="password" required="required" [class.input-error]="loginForm.get('password').invalid && loginForm.get('password').touched">
                                    <span>{{'Global.FormItems.Password' | translate}}</span>
                                </div>
                                <div class="form-group-validation-container" *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.errors && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
                                    <small class="text-danger" *ngIf="loginForm.get('password')?.hasError('required')">
                                        * {{'Global.FormItems.PleaseFillThisArea' | translate}}
                                    </small>
                                </div>
                            </div>
                            <app-ng-button (click)="login()" [isAction]="loginButtonAction" classValue="pb-btn-default w-100" text="{{'Login.SignIn' | translate}}"></app-ng-button>
                        </div>
                    </form>
                </div>
                <!-- Login Form (End) -->

                <!-- Register Form (Start) -->
                <div *ngIf="currentTab == tabEnums.Register" class="registerr-container">
                    <form [formGroup]='registerForm' *ngIf="registerForm">
                        <div class="register-form">
                            <div class="row">
                                <div class="col-6 form-group">
                                    <div class="pb-input-box">
                                        <input type="text" id="firstName" autocomplete="off" formControlName="firstName" required="required" [class.input-error]="registerForm.get('firstName').invalid && registerForm.get('firstName').touched">
                                        <span>{{'Global.FormItems.FirstName' | translate}}</span>
                                    </div>
                                </div>
                                <div class="col-6 form-group">
                                    <div class="pb-input-box">
                                        <input style="text-transform: uppercase;" type="text" id="lastName" autocomplete="off" formControlName="lastName" required="required" [class.input-error]="registerForm.get('lastName').invalid && registerForm.get('lastName').touched">
                                        <span>{{'Global.FormItems.LastName' | translate}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                               <div class="pb-input-box">
                                    <input type="email" id="email" autocomplete="off" formControlName="email" required="required" [class.input-error]="registerForm.get('email').invalid && registerForm.get('email').touched">
                                    <span>{{'Global.FormItems.Email' | translate}}</span>
                               </div>
                            </div>
                            <div class="form-group">
                                <div class="pb-input-box">
                                    <input [type]="passwordVisibilityType" id="password" formControlName="password" required="required" [class.input-error]="registerForm.get('password').invalid && registerForm.get('password').touched">
                                    <span>{{'Global.FormItems.Password' | translate}}</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="pb-input-box">
                                    <input [type]="passwordVisibilityType" id="passwordConfirm" formControlName="passwordConfirm" required="required" [class.input-error]="registerForm.get('passwordConfirm').invalid && registerForm.get('passwordConfirm').touched">
                                    <span>{{'Global.FormItems.PasswordConfirm' | translate}}</span>
                                </div>
                            </div>
                            <app-ng-button (click)="register()" [isAction]="loginButtonAction" classValue="pb-btn-default w-100" text="{{'Login.Register' | translate}}"></app-ng-button>
                        </div>
                    </form>
                </div>
                <!-- Register Form (End) -->

            </div>
        </div>
        <div class="footer">
            <div class="footer-content">
                <div class="top">
                    <p class="cursor-pointer" routerLink="/cookies-policy">{{'Login.CookiesPolicy' | translate}}</p>
                </div>
                <div class="bottom">
                    <p *ngIf="mainTab == tabEnums.Login">{{'Login.IsNotHaveAccount' | translate}}<span (click)="setCurrentTab(tabEnums.RegisterStart)">{{'Login.Register' | translate}}</span></p>
                    <p *ngIf="mainTab == tabEnums.Register">{{'Login.IsAlreadyHaveAccount' | translate}}<span (click)="setCurrentTab(tabEnums.LoginStart)">{{'Login.SignIn' | translate}}</span></p>
                </div>
            </div>
        </div>
    </div>
</div>