import { TranslateModuleService } from './../../services/translate-module.service';
import { HelpersFactoryService } from 'src/app/services/helpers-factory.service';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Messages } from 'src/app/constants/messages';
import { BlogWithoutExplanationDto } from 'src/app/models/blogWithoutExplanationDto';
import { BlogService } from 'src/app/services/blog.service';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { PageRequest } from 'src/app/models/paginates/pageRequest';
import { Paginate } from 'src/app/models/paginates/paginate';
import { ActivatedRoute } from '@angular/router';
import { PageTitle } from 'src/app/constants/pageTitle';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit, AfterViewInit {

  @ViewChild('mostLikedContent', { read: ElementRef }) public mostLikedContent: ElementRef<any>;

  startYourRecipeModalIsShowing: boolean = false;

  blogs: Paginate<BlogWithoutExplanationDto>;
  blogsDataIsLoaded: Promise<boolean>;
  blogSearchText: string = "";
  currentLanguage: any;
  subscriberForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private subscriberService: SubscriberService,
    private blogService: BlogService,
    private activatedRoute: ActivatedRoute,
    public HelpersFactoryService: HelpersFactoryService) { 
      this.blogs = new Paginate<BlogWithoutExplanationDto>();
    }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.startYourRecipeModalIsShowingOutput(true);
    }, 15000)
  }

  ngOnInit(): void {

    this.HelpersFactoryService.pageTitleService.setTitleWithCanonical(PageTitle.layouts.mainLayout.blogs);

    this.activatedRoute.params.subscribe((params) => {
      let page = params['page'];
      if (page){
        page = Number(page);
        if (page <= 0){ page = 1; }
      }
      else if(page == null){
        page = 1;
      }
      this.blogs.size = 15;
      this.blogs.index = page - 1;
    });

    this.getCurrentLanguage();
    this.getBlogsDetailWithoutExplanation();
    this.createSubscriberForm();
  }

  getBlogsDetailWithoutExplanation(){
    this.HelpersFactoryService.spinnerService.show();
    let pageRequest = new PageRequest(
      this.blogs.index,
      this.blogs.size
    );
 
    this.blogService.getBlogsDetailWithoutExplanation(pageRequest, this.currentLanguage).subscribe({
      next: (response => {
        this.blogs = response.data;
        this.blogsDataIsLoaded = Promise.resolve(true);
        this.HelpersFactoryService.spinnerService.hide();
      }),
      error: (error => {
        this.HelpersFactoryService.spinnerService.hide();
        this.HelpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
      })
    })
  }

  updateUrl(){
    this.HelpersFactoryService.routerNavigate(`/blogs/${this.blogs.index + 1}`)
  }

  addSubscriber(){
    if(this.subscriberForm.valid){
      this.HelpersFactoryService.spinnerService.show();
      let model = Object.assign({}, this.subscriberForm.value);
      this.subscriberService.add(model).subscribe({
        next: (response => {
          this.HelpersFactoryService.spinnerService.hide();
          this.HelpersFactoryService.toastService.success(response.message);
          this.subscriberForm.reset();
        }),
        error: (error => {
          this.HelpersFactoryService.spinnerService.hide();
          this.HelpersFactoryService.toastService.error(error.error.message);
        })
      });
    }
  }

  createSubscriberForm(){
    this.subscriberForm = this.formBuilder.group({
      id: [0, Validators.required],
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      date: [new Date()]
    });
  }

  public mostLiekdContentScroll(value: number): void {
    this.mostLikedContent.nativeElement.scrollTo({ left: (this.mostLikedContent.nativeElement.scrollLeft + value), behavior: 'smooth' });
  }

  getCurrentLanguage(){
    this.currentLanguage = TranslateModuleService.currentLanguage;
  }

  startYourRecipeModalIsShowingOutput(state: boolean){
    this.startYourRecipeModalIsShowing = state;
  }

  navigateToBlogDetail(urlName: string, pageTitle: string){
    this.HelpersFactoryService.routerNavigate(`/blogs/detail/${urlName}`);
  }

}
