<div class="title m-t-70">
    <p class="title-text">{{!passwordResetLinkIsSended ? 'HESABINI KURTAR' : 'E-posta Adresinize Giriş Bağlantısı Gönderildi'}}</p>
    <p *ngIf="!passwordResetLinkIsSended" class="sub-title-text m-t-30">E-postanızı giriniz ve e-posta adresinize gönderdiğimiz linke tıklayınız. </p>
</div>
<div class="dashboard-login-container m-t-50">
    <form [formGroup]='passwordResetRequestForm' *ngIf="passwordResetRequestForm && !passwordResetLinkIsSended">
        <div class="login-form w-100">
            <div class="form-group">
                <div class="pb-input-box">
                    <input type="email" id="email" autocomplete="off" formControlName="email" required="required" [class.input-error]="passwordResetRequestForm.get('email').invalid && passwordResetRequestForm.get('email').touched">
                    <span>E-posta adresiniz</span>
                </div>
                <div class="form-group-validation-container" *ngIf="passwordResetRequestForm.get('email')?.invalid && passwordResetRequestForm?.get('email')?.errors && (passwordResetRequestForm.get('email')?.dirty || passwordResetRequestForm.get('email')?.touched)">
                    <small class="text-danger" *ngIf="passwordResetRequestForm.get('email')?.hasError('required')">
                        * Lütfen bu alanı doldurun
                    </small>
                    <small class="text-danger" *ngIf="passwordResetRequestForm.get('email')?.hasError('email') && passwordResetRequestForm.get('email').touched">
                        * Lütfen geçerli bir email adresi girin
                    </small>
                </div>
            </div>
        </div>
    </form>
    <div class="buttons m-t-25">
        <button routerLink="/{{RouterName.dashboard.login.adminLogin}}" class="pb-btn-outlined">Geri Dön</button>
        <button (click)="sendPasswordResetLink()" class="pb-btn-default flex-1">{{!passwordResetLinkIsSended ? 'Giriş Bağlantısı' : 'Tekrar'}} Gönder</button>
    </div>
</div>