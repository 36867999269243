import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './shared/layouts/main-layout/main-layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericSearchPipe } from './pipes/generic-search.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './components/footer/footer.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FrequentlyAskedQuestionsComponent } from './components/frequently-asked-questions/frequently-asked-questions.component';
import { BlogsComponent } from './components/blogs/blogs.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BlogSearchPipe } from './pipes/blog-search.pipe';
import { RecipeEngineComponent } from './components/recipe-engine/recipe-engine.component';
import { HowToUseVideoComponent } from './components/how-to-use-video/how-to-use-video.component';
import { EmptyLayoutComponent } from './shared/layouts/empty-layout/empty-layout.component';
import { BlogTextEditorComponent } from './components/blog-text-editor/blog-text-editor.component';
import { StartYourRecipeComponent } from './modals/start-your-recipe/start-your-recipe.component';
import { LoginComponent } from './components/login/login.component';
import { RecipesComponent } from './components/recipes/recipes.component';
import { SendRecipeComponent } from './components/send-recipe/send-recipe.component';
import { RecipesDetailComponent } from './components/recipes-detail/recipes-detail.component';
import { HowToUseComponent } from './components/how-to-use/how-to-use.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleSigninButtonModule} from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { DashboardLoginComponent } from './components/dashboard/dashboard-login/dashboard-login.component';
import { NgImageComponent } from './components/attributes/ng-image/ng-image.component';
import { DashboardLoginLayoutComponent } from './shared/layouts/dashboard-login-layout/dashboard-login-layout.component';
import { DashboardPasswordResetRequestComponent } from './components/dashboard/dashboard-password-reset-request/dashboard-password-reset-request.component';
import { DashboardPasswordResetNewPasswordComponent } from './components/dashboard/dashboard-password-reset-new-password/dashboard-password-reset-new-password.component';
import { DashboardHomeComponent } from './components/dashboard/dashboard-home/dashboard-home.component';
import { DashboardAdminLayoutComponent } from './shared/layouts/dashboard-admin-layout/dashboard-admin-layout.component';
import { DashboardNavbarComponent } from './components/dashboard/dashboard-navbar/dashboard-navbar.component';
import { TextEditorPartialComponent } from './partials/text-editor-partial/text-editor-partial.component';
import { EditorModule, TINYMCE_SCRIPT_SRC  } from '@tinymce/tinymce-angular';
import { DataLoadingAnimationPartialComponent } from './partials/data-loading-animation-partial/data-loading-animation-partial.component';
import { SpinnerPartialComponent } from './partials/spinner-partial/spinner-partial.component';
import { NgRichTextViewerComponent } from './components/attributes/ng-rich-text-viewer/ng-rich-text-viewer.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe'
import { SpinnerBlueComponent } from './animations/GifAnimations/spinner-blue/spinner-blue.component';
import { SpinnerComponent } from './animations/GifAnimations/spinner/spinner.component';
import { ClickOutsideDirective } from './directives/clickOutsideDirective';
import { NgLinkComponent } from './components/attributes/ng-link/ng-link.component';
import { NgButtonComponent } from './components/attributes/ng-button/ng-button.component';
import { PaginationPartialComponent } from './partials/pagination-partial/pagination-partial.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChartPartialComponent } from './partials/chart-partial/chart-partial.component';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    NavbarComponent,
    HomeComponent,
    GenericSearchPipe,
    FooterComponent,
    CookiesPolicyComponent,
    PageNotFoundComponent,
    ContactUsComponent,
    FrequentlyAskedQuestionsComponent,
    BlogsComponent,
    BlogDetailComponent,
    BlogSearchPipe,
    RecipeEngineComponent,
    HowToUseVideoComponent,
    EmptyLayoutComponent,
    BlogTextEditorComponent,
    StartYourRecipeComponent,
    LoginComponent,
    RecipesComponent,
    SendRecipeComponent,
    RecipesDetailComponent,
    HowToUseComponent,
    ProfileComponent,
    DashboardLoginComponent,
    NgImageComponent,
    DashboardLoginLayoutComponent,
    DashboardPasswordResetRequestComponent,
    DashboardPasswordResetNewPasswordComponent,
    DashboardHomeComponent,
    DashboardAdminLayoutComponent,
    DashboardNavbarComponent,
    TextEditorPartialComponent,
    DataLoadingAnimationPartialComponent,
    SpinnerPartialComponent,
    NgRichTextViewerComponent,
    SafeHtmlPipe,
    SpinnerBlueComponent,
    SpinnerComponent,
    ClickOutsideDirective,
    NgLinkComponent,
    NgButtonComponent,
    PaginationPartialComponent,
    ChartPartialComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AngularEditorModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
    EditorModule,
    NgApexchartsModule,
    MatTableModule, 
    MatSortModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      positionClass:"toast-bottom-right",
      progressBar: true,
      closeButton: true,
      timeOut: 7000,
      maxOpened: 5
    })
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('344765827033-lp40q0dir0o227fmenh84m7ngcfto565.apps.googleusercontent.com'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}