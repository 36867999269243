<div class="frequently-asked-questions">
    <h2 class="title">{{'FrequentlyAskedQuestions.Title' | translate}}</h2>
    <div class="content">
        <div class="questions">
            <div *ngFor="let question of questions" class="question">
                <div (click)="questionVisibleHandle(question)" class="question-info">
                    <span class="question-title">{{question.title}}</span>
                    <img [src]="toggleArrowIcon(question.answerIsShowing)" draggable="false" alt="arrow-down">
                </div>
                <div *ngIf="question.answerIsShowing" [@dropDownExpandAnimation] class="answer-drop-down">
                    <p><b>{{'FrequentlyAskedQuestions.Answer' | translate}}: </b>{{question.answer}}</p>
                </div>
            </div>
        </div>
    </div>
</div>