import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SingleResponseModel } from '../models/singleResponseModel';
import { GeoLocation } from '../models/entities/geoLocation';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { ClientGeoLocationModel } from '../models/common/clientGeoLocationModel';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  
  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  getClientGeoLocation(): Observable<ClientGeoLocationModel>{
    return this.httpClient.get<ClientGeoLocationModel>('https://api.ipdata.co/?api-key=2d7ee71dcb5603853fef4807809169a295382e8e06d21c0201009abc');
  }

  add(geoLocation: GeoLocation): Observable<SingleResponseModel<GeoLocation>>{
    let newPath = `${this.apiUrl}GeoLocations/Add`;
    return this.httpClient.post<SingleResponseModel<GeoLocation>>(newPath, geoLocation);
  }

}
