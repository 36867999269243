import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-text-editor-partial',
  templateUrl: './text-editor-partial.component.html',
  styleUrls: ['./text-editor-partial.component.css']
})

export class TextEditorPartialComponent implements OnInit{

  @Output() contentData = new EventEmitter<string>();
  @Input() inputData: string;

  content: string;

  editorConfig = {
    base_url: '/tinymce',
    suffix: '.min',
    plugins: [
       'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
      'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
      'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
    ],
    toolbar: "undo redo spellcheckdialog  | blocks fontfamily fontsizeinput | bold italic underline forecolor backcolor | link image | align lineheight checklist bullist numlist | indent outdent | removeformat typography",
    height: 800,
    toolbar_sticky: true,
    autosave_restore_when_empty: true,
    font_size_formats: '10px 12px 14px 15px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px'
  };

  ngOnInit(): void {
    if(this.inputData){
      this.content = this.inputData;
    }
  }

  onContentChange(contentData: any){
    this.contentData.emit(contentData);
  }

}
