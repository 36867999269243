import { UserFollowerStatisticDto } from './../../models/dtos/userFollower/userFollowerStatisticDto';
import { UserFollowerService } from './../../services/user-follower.service';
import { HelpersFactoryService } from './../../services/helpers-factory.service';
import { RecipeCommentLike } from './../../models/recipeCommentLike';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Messages } from 'src/app/constants/messages';
import { RecipeBook } from 'src/app/models/recipeBook';
import { RecipeBookService } from 'src/app/services/recipe-book.service';
import { RecipeTypeEnum } from 'src/app/enums/recipeTypeEnum';
import { UserRecipeService } from 'src/app/services/user-recipe.service';
import { RecipeDetailDto } from 'src/app/models/recipeDetailDto';
import { UserRecipeDetailDto } from 'src/app/models/userRecipeDetailDto';
import { SystemRecipeDetailDto } from 'src/app/models/systemRecipeDetailDto';
import { SystemRecipeService } from 'src/app/services/system-recipe.service';
import { RecipeService } from 'src/app/services/recipe.service';
import { RecipeBasicDto } from 'src/app/models/recipeBasicDto';
import { RecipeCommentService } from 'src/app/services/recipe-comment.service';
import { RecipeComment } from 'src/app/models/recipeComment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecipeCommentLikeService } from 'src/app/services/recipe-comment-like.service';
import { UserFollower } from 'src/app/models/userFollower';
import { AuthenticatedUserModel } from 'src/app/models/auth/authenticatedUserModel';

enum TabEnums{
  Recipe = 'recipe',
  Comments = 'comments'
}

@Component({
  selector: 'app-recipes-detail',
  templateUrl: './recipes-detail.component.html',
  styleUrls: ['./recipes-detail.component.css']
})

export class RecipesDetailComponent implements OnInit {

  recipe: RecipeDetailDto;
  userRecipe: UserRecipeDetailDto;
  systemRecipe: SystemRecipeDetailDto;

  anotherRecipes: RecipeBasicDto[] = [];
  
  currentTab: TabEnums = TabEnums.Recipe;
  tabEnums = TabEnums;
  voteTempArray: number[] = [5, 4, 3, 2, 1];
  calorieIndicatorIcon: string;
  calorieIndicatorClass: string;

  authenticatedUser: AuthenticatedUserModel;
  userFollowerStatistic: UserFollowerStatisticDto;

  newCommentForm: FormGroup;

  recipeOwnerIsFollowing: Promise<boolean>;
  recipeBookIsAddedd: Promise<boolean>;

  mySubscription;
  constructor(private recipeService: RecipeService,
    private userRecipeService: UserRecipeService,
    private systemRecipeService: SystemRecipeService,
    private recipeCommentLikeService: RecipeCommentLikeService,
    private activatedRoute: ActivatedRoute,
    private recipeBookService: RecipeBookService,
    private recipeCommentService: RecipeCommentService,
    private userFollowerService: UserFollowerService,
    private router: Router,
    public formBuilder: FormBuilder,
    public HelpersFactoryService: HelpersFactoryService) 
    {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.mySubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.router.navigated = false;
        }
      }); 
    }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params['recipe']) {
        this.authenticatedUser = this.HelpersFactoryService.localStorageService.getAuthenticatedUserInformations();
        let url = String(params['recipe']).split('-');
        this.getRecipeById(url[url.length - 2], url[url.length - 1]);
        this.getAnotherRecipes();
        this.createNewCommentForm();
      }
    });
  }
  
  addRecipeToBook(){
    if(this.authenticatedUser != null){
      this.HelpersFactoryService.spinnerService.show();
      let recipeBook = new RecipeBook();
      recipeBook.userId = this.authenticatedUser.userBasicInformation.id;
      recipeBook.recipeId = this.recipe.id;
      recipeBook.date = new Date();
      this.recipeBookService.add(recipeBook).subscribe({
        next: (response => {
          this.HelpersFactoryService.spinnerService.hide();
          this.HelpersFactoryService.toastService.success("Tarif defterinze eklendi");
        }),
        error: (error => {
          this.HelpersFactoryService.spinnerService.hide();
          this.HelpersFactoryService.toastService.error(error.error.message);
        })
      });
    }
    else{
      this.HelpersFactoryService.toastService.warning("Önce giriş yapmalısınız");
      this.router.navigate(["login"]);
    }
  }

  getRecipeById(recipeId: any, recipeTypeId: any){
    this.HelpersFactoryService.spinnerService.show();
    let recipeType = recipeTypeId as RecipeTypeEnum;
    if(recipeType == RecipeTypeEnum.UserRecipe){
      this.userRecipeService.getUserRecipeDetailById(recipeId).subscribe({
        next: (response => {
          this.HelpersFactoryService.pageTitleService.setTitleWithCanonical(response.data.recipeName);
          this.recipe = response.data;
          this.userRecipe = response.data;
          this.getIndicatorStyle("0");
          this.getRecipeOwnerFollowers(response.data.user.id);
          this.HelpersFactoryService.spinnerService.hide();
          if(this.authenticatedUser){
            this.checkIfUserIsFollowing(this.authenticatedUser?.userBasicInformation?.id, this.recipe?.user?.id);
            this.checkIfUserIsAddRecipeBook(this.authenticatedUser?.userBasicInformation.id, this.recipe?.id);
          }
        }),
        error: (response => {
          this.HelpersFactoryService.spinnerService.hide();
          this.HelpersFactoryService.toastService.error(response?.error?.error?.message);
        })
      });
    }
    else if(recipeType == RecipeTypeEnum.SystemRecipe){
      this.systemRecipeService.getSystemRecipeDetailById(recipeId).subscribe({
        next: (response => {
          this.HelpersFactoryService.pageTitleService.setTitleWithCanonical(response.data.recipeName);
          this.recipe = response.data;
          this.systemRecipe = response.data;
          this.getIndicatorStyle(this.systemRecipe.calorie);
          this.getRecipeOwnerFollowers(response.data.user.id);
          this.checkIfUserIsFollowing(this.authenticatedUser?.userBasicInformation?.id, this.recipe?.user?.id);
          this.checkIfUserIsAddRecipeBook(this.authenticatedUser?.userBasicInformation.id, this.recipe?.id);
          this.HelpersFactoryService.spinnerService.hide();
        }),
        error: (response => {
          this.HelpersFactoryService.spinnerService.hide();
          this.HelpersFactoryService.toastService.error(response?.error?.error?.message);
        })
      });
    }
  }

  getAnotherRecipes(){
    this.recipeService.getRecipesBasicData(10).subscribe({
      next: (response => {
        this.anotherRecipes = response.data;
      })
    })
  }

  getRecipeOwnerFollowers(userId: any){
    this.userFollowerService.getUserFollowerStatisticByUserId(userId).subscribe({
      next: (response => {
        this.userFollowerStatistic = response.data;
      })
    })
  }

  getCommentLikeIcon(userId: any){
    if(this.authenticatedUser.userBasicInformation.id == userId){
      return "assets/icons/heart-outlined-pink-icon.svg";
    }
    else{
      return "assets/icons/heart-pink-icon.svg";
    }
  }

  sendComment(){
    if(this.newCommentForm.valid){
      let model = Object.assign({}, this.newCommentForm.value);
      let recipeComment = new RecipeComment();
      recipeComment.userId = this.authenticatedUser.userBasicInformation.id;
      recipeComment.recipeId = this.recipe.id;
      recipeComment.comment = model["comment"];
      recipeComment.date = new Date();
      recipeComment.state = false;
  
      this.HelpersFactoryService.spinnerService.show();
      this.recipeCommentService.add(recipeComment).subscribe({
        next: (response => {
          this.HelpersFactoryService.spinnerService.hide();
          this.HelpersFactoryService.toastService.success("Yorum onaylanmak üzere sistem yönetisine gönderildi");
        }),
        error: (error => {
          this.HelpersFactoryService.spinnerService.hide();
          this.HelpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
        })
      });

      this.newCommentForm.reset();
    }
  }

  likeComment(recipeCommentId: any){
    if(this.authenticatedUser){
      this.HelpersFactoryService.spinnerService.show();
    let recipeCommentLike = new RecipeCommentLike();
    recipeCommentLike.userId = this.authenticatedUser.userBasicInformation.id;
    recipeCommentLike.recipeId = this.recipe.id;
    recipeCommentLike.recipeCommentId = recipeCommentId;
    recipeCommentLike.date = new Date();

    this.recipeCommentLikeService.like(recipeCommentLike).subscribe({
      next: (response => {
        this.HelpersFactoryService.spinnerService.hide();
        window.location.reload();
      }),
      error: (error => {
        this.HelpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
        this.HelpersFactoryService.spinnerService.hide();
      })
    })
    }
    else{
      this.HelpersFactoryService.loginBefore();
    }
  }

  followToUser(){
    if(this.authenticatedUser){
      this.HelpersFactoryService.spinnerService.show();
      let userFolloser = new UserFollower(0, this.authenticatedUser.userBasicInformation.id, this.recipe.user.id);
      this.userFollowerService.follow(userFolloser).subscribe({
        next: (response => {
          this.HelpersFactoryService.spinnerService.hide();
          this.HelpersFactoryService.toastService.success(response.message);
          this.recipeOwnerIsFollowing = Promise.resolve(true);
        }),
        error: (error => {
          this.HelpersFactoryService.spinnerService.hide();
          this.HelpersFactoryService.toastService.error(error.error.message);
        })
      })
    }
    else{
      this.HelpersFactoryService.toastService.warning("Önce giriş yapmalısınız");
      this.router.navigate(["/login"]);
    }
  }

  unFollowToUser(){
    if(this.authenticatedUser){
      this.HelpersFactoryService.spinnerService.show();
      let userFolloser = new UserFollower(0, this.authenticatedUser.userBasicInformation.id, this.recipe.user.id);
      this.userFollowerService.unFollow(userFolloser).subscribe({
        next: (response => {
          this.HelpersFactoryService.spinnerService.hide();
          this.HelpersFactoryService.toastService.success(response.message);
          this.recipeOwnerIsFollowing = Promise.resolve(false);
        }),
        error: (error => {
          this.HelpersFactoryService.spinnerService.hide();
          this.HelpersFactoryService.toastService.error(error.error.message);
        })
      })
    }
    else{
      this.HelpersFactoryService.toastService.warning("Önce giriş yapmalısınız");
      this.router.navigate(["/login"]);
    }
  }

  checkIfUserIsFollowing(userId: any, followingUserId: any){
    this.userFollowerService.checkIfUserIsFollowing(userId, followingUserId).subscribe({
      next: (response => {
        this.recipeOwnerIsFollowing = Promise.resolve(response.success);
      })
    })
  }

  checkIfUserIsAddRecipeBook(userId: any, recipeId: any){
    this.recipeBookService.checkIfRecipeBookIsAdded(userId, recipeId).subscribe({
      next: (response => {
        this.recipeBookIsAddedd = Promise.resolve(response.success);
      })
    })
  }

  getRecipeCommentHeartIcon(recipeCommentLikes: RecipeCommentLike[]){
    if(this.checkUserIsLikedComment(recipeCommentLikes)){
      return "assets/icons/heart-pink-icon.svg";
    }
    else{
      return "assets/icons/heart-outlined-pink-icon.svg";
    }
  }

  checkUserIsLikedComment(recipeCommentLikes: RecipeCommentLike[]): boolean{
    for (let i = 0; i < recipeCommentLikes.length; i++) {
      if(this.authenticatedUser && recipeCommentLikes[i].userId === this.authenticatedUser.userBasicInformation.id){
        return true;
      }
    }
    return false;
  }

  setCurrentTab(tab: TabEnums){
    this.currentTab = tab;
  }

  getCurrentTabClass(tab: TabEnums){
    if(tab == this.currentTab){
      return 'tab active-tab'
    }
    return 'tab passive-tab'
  }

  getIndicatorStyle(calorie: string){
    let value = parseInt(calorie);
    if(value < 250){
      this.calorieIndicatorIcon = "low";
      this.calorieIndicatorClass = "calorie-value-text calorie-value-low"
    }
    else if(value >= 250 && value < 500){
      this.calorieIndicatorIcon = "medium";
      this.calorieIndicatorClass = "calorie-value-text calorie-value-medium"
    }
    else if(value >= 500 && value < 750){
      this.calorieIndicatorIcon = "high";
      this.calorieIndicatorClass = "calorie-value-text calorie-value-high"
    }
    else{
      this.calorieIndicatorIcon = "very-high";
      this.calorieIndicatorClass = "calorie-value-text calorie-value-very-high"
    }
  }

  createNewCommentForm(){
    this.newCommentForm = this.formBuilder.group({
      comment: ["", Validators.required]
    });
  }

  counter(count: number): any{
    let array: number[] = [];
    for(let i = 1; i <= count; i++){
      array.push(i);
    }
    return array;
  }
}
