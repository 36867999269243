import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private localStorageService: LocalStorageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token = this.localStorageService.getAuthenticatedUserInformations()?.accessToken.token;
    let newRequest: HttpRequest<any>;
    newRequest = request.clone({
      headers: request.headers.set("Authorization", `Bearer ${token}`)
      .set("x-api-key", "aEgRwSkAMmGoNLhLhZfKiaxxoxOnJXiaHGSPuLWLGWaHfyzyLx")
      
    })
    
    return next.handle(newRequest);
  }
}
