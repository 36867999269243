import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/responseModel';
import { SingleResponseModel } from '../models/singleResponseModel';
import { PasswordResetLink } from '../models/passwordResetLink/passwordResetLink';
import { PasswordResetNewPasswordDto } from '../models/passwordReset/passwordResetNewPasswordDto';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetLinkService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(
    private httpClient: HttpClient) { }

  sendPasswordResetLink(email: any): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}PasswordResetLinks/SendPasswordResetLink?email=${email}`;
    return this.httpClient.get<ResponseModel>(newPath);
  }

  checkPasswordResetLink(linkId: any): Observable<SingleResponseModel<PasswordResetLink>>{
    let newPath = `${this.apiUrl}PasswordResetLinks/CheckPasswordResetLink?linkId=${linkId}`;
    return this.httpClient.get<SingleResponseModel<PasswordResetLink>>(newPath);
  }

  updatePassword(passwordResetNewPasswordDto: PasswordResetNewPasswordDto): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}PasswordResetLinks/UpdatePassword`;
    return this.httpClient.post<ResponseModel>(newPath, passwordResetNewPasswordDto);
  }
}
