import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/responseModel';
import { ListResponseModel } from '../models/listResponseModel';
import { UserRecipeDetailDto } from '../models/userRecipeDetailDto';
import { RecipeBasicDto } from '../models/recipeBasicDto';
import { SingleResponseModel } from '../models/singleResponseModel';

@Injectable({
  providedIn: 'root'
})
export class UserRecipeService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  add(recipe: FormData): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}UserRecipes/Add`;
    return this.httpClient.post<ResponseModel>(newPath, recipe);
  }

  getUserRecipeDetailById(recipeId: any): Observable<SingleResponseModel<UserRecipeDetailDto>>{
    let newPath = `${this.apiUrl}UserRecipes/GetUserRecipeDetailById?recipeId=${recipeId}`;
    return this.httpClient.get<SingleResponseModel<UserRecipeDetailDto>>(newPath);
  }

  getUserRecipesBasicData(): Observable<ListResponseModel<RecipeBasicDto>>{
    let newPath = `${this.apiUrl}UserRecipes/GetUserRecipesBasicData`;
    return this.httpClient.get<ListResponseModel<RecipeBasicDto>>(newPath);
  }

  getUserRecipesBasicDataByUserId(userId: number): Observable<ListResponseModel<RecipeBasicDto>>{
    let newPath = `${this.apiUrl}UserRecipes/GetUserRecipesBasicDataByUserId?userId=${userId}`;
    return this.httpClient.get<ListResponseModel<RecipeBasicDto>>(newPath);
  }
  
}
