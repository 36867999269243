<div class="blog-detail">
    <!-- Banner (Start) -->
    <div class="banner">
        <div class="banner-content">
            <div class="banner-content-left">
                <h1>{{blog?.title}}</h1>
            </div>
            <div class="banner-content-right">
                <div class="track-content">
                    <p class="title">{{'Blogs.SubscriberForm.FormTitle' | translate}}</p>
                    <div class="form">
                        <form [formGroup]="subscriberForm" *ngIf="subscriberForm">
                            <div class="form-group">
                                <input type="text" id="subscriberFormName" placeholder="{{'Blogs.SubscriberForm.Name' | translate}}" autocomplete="off" formControlName="name">
                            </div>
                            <div class="form-group">
                                <input type="text" id="subscriberForEmail" placeholder="{{'Blogs.SubscriberForm.Email' | translate}}" autocomplete="off" formControlName="email">
                            </div>
                            <button class="pb-btn-default w-100 mt-2" (click)="addSubscriber()"><img class="icon-left" src="../../../assets/icons/arrow-right-white-circle-icon.svg" draggable="false" alt="right">{{'Blogs.SubscriberForm.Subscribe' | translate}}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner (End) -->

    <div class="blog-content">
        <div class="blog">
            <p class="blog-content-text">{{'BlogDetail.Title' | translate}}</p>
            <div class="content">
                <div class="blog-explanation">
                    <app-ng-rich-text-viewer [content]="blogExplanation"></app-ng-rich-text-viewer>
                </div>
                <div class="btn-generate-recipe">
                    <button class="pb-btn-default" routerLink="/recipe-engine">{{'Global.TryNow' | translate}}</button>
                </div>
                <div class="blog-footer">
                    <div class="read-and-view-count">
                        <div class="view-count">
                            <img src="../../../assets/icons/eye-dark-icon.svg" draggable="false" alt="eye-icon">
                            <span>{{blog?.numberOfReads}} {{'Global.View' | translate}}</span>
                        </div>
                        <div class="read">
                            <span>{{blog?.date | date:'dd.MM.yyyy'}} . {{'Blogs.AverageReadingTime' | translate}} {{blog?.durationOfReads}} {{'Global.MinuteShort' | translate}}</span>
                        </div>
                    </div>
                    <div class="like">
                        <div (click)="blogLike(true)" class="like-container">
                            <img src="../../../assets/icons/like-outline-pink-icon.svg" draggable="false" alt="like-icon">
                            <span>{{blog?.numberOfLikes}}</span>
                        </div>
                        <div (click)="blogLike(false)" class="dislike-container">
                            <img src="../../../assets/icons/dislike-outline-pink-icon.svg" draggable="false" alt="dislike-icon">
                            <span>{{blog?.numberOfDislikes}}</span>
                        </div>
                    </div>
                    <div class="share-social">
                        <span>{{'Global.Share' | translate}}.</span>
                        <div class="socail-links">
                            <div *ngFor="let socialLink of socialLinks" (click)="shareBlogToSocial(socialLink)" class="link">
                                <img [src]="socialLink.icon" draggable="false" alt="{{socialLink.name}}-icon">
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

        <div class="comments-container">
            <h2 class="comments-container-title-text">{{'BlogDetail.Comments.Title' | translate}} <span>({{blogComments.length}})</span></h2>
            <div *ngIf="blogComments.length <= 0" class="not-found-comments">
                <p>{{'BlogDetail.Comments.NotSharedCommentYet' | translate}}</p>
            </div>
            <div *ngIf="blogComments.length > 0" class="comments">
                <div *ngFor="let blogComment of blogComments" class="comment">
                    <div class="user-info">
                        <img src="../../../assets/icons/user.png" alt="user-icon" draggable="false">
                        <div class="user-name-and-date">
                            <p class="user-name">{{blogComment.firstName}} {{blogComment.lastName | uppercase}}</p>
                            <p class="date">{{blogComment.date | date:'dd.MM.yyyy HH:mm'}}</p>
                        </div>
                    </div>
                    <p class="comment-text">{{blogComment.comment}}</p>
                </div>
            </div>
            <div class="new-comment-form">
                <form [formGroup]="newBlogCommentForm" *ngIf="newBlogCommentForm">
                    <div class="form-group comment-area">
                        <textarea placeholder="{{'BlogDetail.Comments.WriteYourComment' | translate}}" id="newBlogCommentFormComment" formControlName="comment" required></textarea>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <input type="text" class="input" id="newBlogCommentFormFirstName" placeholder="{{'Global.FormItems.FirstName' | translate}}" autocomplete="off" formControlName="firstName" required> <!--   placeholder="{{'Footer.Form.Name' | translate}}"  -->
                        </div>
                        <div class="col-6 form-group">
                            <input type="text" class="input" id="newBlogCommentFormLastName" placeholder="{{'Global.FormItems.LastName' | translate}}" autocomplete="off" formControlName="lastName" required> <!-- formControlName="name"  placeholder="{{'Footer.Form.Name' | translate}}"  -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <input type="email" class="input" id="newBlogCommentFormMail" placeholder="{{'Global.FormItems.Email' | translate}}" autocomplete="off" formControlName="mail" required> <!-- formControlName="name"  placeholder="{{'Footer.Form.Name' | translate}}"  -->
                        </div>
                        <div class="col-6 form-group">
                            <input type="text" class="input" id="newBlogCommentFormPhoneNumber" placeholder="{{'Global.FormItems.PhoneNumber' | translate}}" maxlength="15" autocomplete="off" formControlName="phoneNumber" required> <!-- formControlName="name"  placeholder="{{'Footer.Form.Name' | translate}}"  -->
                        </div>
                    </div>
                    <button (click)="sendNewComment()" type="submit" class="btn-send-comment">{{'Global.Send' | translate}}</button>
                </form>
                
            </div>
        </div>
    </div>
    
    <div class="partials">
        <app-frequently-asked-questions></app-frequently-asked-questions>
        <app-contact-us class="contact-us"></app-contact-us>
    </div>

</div>

<!-- <app-start-your-recipe (startYourRecipeModalIsShowingOutput)="startYourRecipeModalIsShowingOutput($event)" [startYourRecipeModalIsShowing]="startYourRecipeModalIsShowing"></app-start-your-recipe> -->