<div class="home">
    <div class="hamburger-menu-icons">
        <img (click)="toogleTopContent()" src="../../../assets/icons/hamburger-menu-icon.svg" draggable="false" alt="oven-icon">
    </div>
    <div id="topContent" class="top-content">
        <div class="content">
            <div *ngFor="let topContentItem of topContentItems" class="item">
                <img src="../../../assets/{{topContentItem.iconPath}}" draggable="false" alt="icon">
                <div class="custom-drop-down" clickOutside (clickOutside)="topContentItem.dropDownIsShowing= false">
                    <div (click)="setCurrentTopContentItems(topContentItem)" [ngStyle]="{'background-color': getSelectedItemsCount(topContentItem.items) > 0 ? '#FE0045':'#FFFFFF', 'color': getSelectedItemsCount(topContentItem.items) > 0 ? '#FFFFFF':'#2C2C2C', 'border-color': getSelectedItemsCount(topContentItem.items) > 0 ? '#FE0045':'#B3B8C6'}" class="input">
                        <p>{{getNavbarItemsNameForTranslate(topContentItem.name) | translate}} <span *ngIf="getSelectedItemsCount(topContentItem.items) > 0">({{getSelectedItemsCount(topContentItem.items)}})</span> <img *ngIf="getSelectedItemsCount(topContentItem.items) <= 0" class="arrow-down-icon" src="../../../assets/icons/arrow-down-black-icon.svg" draggable="false" alt="arrow-down-icon"></p>
                    </div>
                    <div *ngIf="topContentItem.dropDownIsShowing" class="drop-down-menu hide-scroll">
                        <div *ngFor="let item of topContentItem.items; let i = index" class="row">
                            <div class="form-check">
                                <input (change)="selectedTopContentItemsCheckBoxOnChange(topContentItem.id, item)" [(ngModel)]="item.isChecked" class="form-check-input" type="checkbox" name="{{topContentItem.type}}CheckBoxs" value="{{item.name}}" id="{{topContentItem.type}}CheckBoxs{{item.name}}">
                                <label *ngIf="item.isDefaultItem" for="{{topContentItem.type}}CheckBoxs{{item.name}}">{{getNavbarItemsItemNameForTranslate(topContentItem.name, i) | translate}}</label>
                                <label *ngIf="!item.isDefaultItem" for="{{topContentItem.type}}CheckBoxs{{item.name}}">{{item.name}}</label>
                            </div>
                        </div>
                        <p (click)="topContentItem.dropDownAddAnotherFormIsShowing = !topContentItem.dropDownAddAnotherFormIsShowing" class="btn-add-another"> {{topContentItem.dropDownAddAnotherFormIsShowing ? ('Home.Navbar.AddAnotherCancel' | translate) : ('Home.Navbar.AddAnother' | translate)}}</p>
                        <div *ngIf="topContentItem.dropDownAddAnotherFormIsShowing" class="add-another-form">
                            <input [(ngModel)]="newItemTopContentItems" type="text" placeholder="{{'Home.Navbar.AddAnotherInputPlaceHolder' | translate}}" (keyup.enter)="addNewItemToTopContentItems(topContentItem)">
                            <img (click)="addNewItemToTopContentItems(topContentItem)" class="enter" src="../../../assets/icons/arrow-right-pink-circle-icon.svg" draggable="false" alt="enter">
                        </div>
                        <div class="splite"></div>
                        <div class="proccess-buttons">
                            <button (click)="topContentItem.dropDownIsShowing = false" class="btn-cancel">{{'Home.Navbar.Cancel' | translate}}</button>
                            <button (click)="topContentItem.dropDownIsShowing = false" class="btn-complete">{{'Home.Navbar.GetResult' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item advanced-search">
                <img src="../../../assets/icons/filter-icon.svg" draggable="false" alt="filter-icon">
                <div class="custom-drop-down" clickOutside (clickOutside)="advancedSearchDropDownIsShowing= false">
                    <div (click)="toggleAdvancedSearchDropDownState()" class="input">
                        <p>{{getNavbarItemsNameForTranslate('AdvancedSearch') | translate}}<img class="arrow-down-icon" src="../../../assets/icons/arrow-down-black-icon.svg" draggable="false" alt="arrow-down-icon"></p>
                    </div>
                    <div *ngIf="advancedSearchDropDownIsShowing" class="drop-down-menu hide-scroll">
                        <div class="advanced-search-items">
                            <div class="advanced-search-item">
                                <div class="advanced-search-item-top">
                                    <p class="title">{{'Home.Navbar.AdvancedSearch.Items.Item-0.Name' | translate}}</p>
                                    <div class="counter">
                                        <span (click)="porisonSizeValueEvent(-1)" class="down">-</span>
                                        <input [(ngModel)]="portionNumber" type="number" min="1" value="1" max="999" oninput="validity.valid||(value='');">
                                        <span (click)="porisonSizeValueEvent(1)" class="up">+</span>
                                    </div>
                                </div>
                                <div class="advanced-search-item-content">
                                    <div *ngFor="let item of portionNumbers; let i = index" (click)="setPortionNumberItem(item)" [ngStyle]="{'background-color': currentPortionNumber == item ? '#FE0045':'#FDEDF1'}"><p [ngStyle]="{'color': currentPortionNumber == item ? '#FFFFFF':'#616571'}">{{getAdvancedSearchItemsItemNameForTranslate('Item-0', i) | translate}}</p></div>
                                </div>
                            </div>
                            <div class="advanced-search-item">
                                <div class="advanced-search-item-top">
                                    <p class="title">{{'Home.Navbar.AdvancedSearch.Items.Item-1.Name' | translate}}</p>
                                    <div class="counter">
                                        <span (click)="recipeMinuteValueEvent(-1)" class="down">-</span>
                                        <input [(ngModel)]="recipeMinute" type="number" min="1" value="1" max="999" oninput="validity.valid||(value='');">
                                        <span (click)="recipeMinuteValueEvent(1)" class="up">+</span>
                                    </div>
                                </div>
                                <div class="advanced-search-item-content">
                                    <div *ngFor="let item of recipeMinutes; let i = index" (click)="setRecipeMinuteItem(item)" [ngStyle]="{'background-color': currentRecipeMinute == item ? '#FE0045':'#FDEDF1'}"><p [ngStyle]="{'color': currentRecipeMinute == item ? '#FFFFFF':'#616571'}">{{getAdvancedSearchItemsItemNameForTranslate('Item-1', i) | translate}}</p></div>
                                </div>
                            </div>
                            <div class="advanced-search-item">
                                <div class="advanced-search-item-top">
                                    <p class="title">{{'Home.Navbar.AdvancedSearch.Items.Item-2.Name' | translate}}</p>
                                </div>
                                <div class="advanced-search-item-content">
                                    <div *ngFor="let item of portionSizes; let i = index" (click)="setPortionSizeItem(item)" [ngStyle]="{'background-color': currentPortionSize == item ? '#FE0045':'#FDEDF1'}"><p [ngStyle]="{'color': currentPortionSize == item ? '#FFFFFF':'#616571'}">{{getAdvancedSearchItemsItemNameForTranslate('Item-2', i) | translate}}</p></div>
                                </div>
                            </div>
                            <div class="advanced-search-item">
                                <div class="advanced-search-item-top">
                                    <p class="title">{{'Home.Navbar.AdvancedSearch.Items.Item-3.Name' | translate}}</p>
                                    <div class="add-another-item">
                                        <div (click)="addAnotherUnWantedIsShowing = true" *ngIf="!addAnotherUnWantedIsShowing" class="add">
                                            <span>{{'Global.Add' | translate}}</span>
                                            <img src="../../../assets/icons/plus-pink-icon.svg" draggable="false" alt="plus-icon">
                                        </div>
                                        <div (click)="addAnotherUnWantedIsShowing = false" *ngIf="addAnotherUnWantedIsShowing" class="cancel">
                                            <span>{{'Global.Cancel' | translate}}</span>
                                            <img src="../../../assets/icons/close-circle-pink-icon.svg" draggable="false" alt="close-icon">
                                        </div>
                                    </div>
                                    <div *ngIf="addAnotherUnWantedIsShowing" class="add-another-form">
                                        <input [(ngModel)]="newAnotherUnWanted" type="text" placeholder="{{'Home.Navbar.AdvancedSearch.Items.Item-3.AddUnwanted' | translate}}" (keyup.enter)="addAnotherUnwanted()">
                                        <img (click)="addAnotherUnwanted()" class="enter" src="../../../assets/icons/arrow-right-pink-circle-icon.svg" draggable="false" alt="enter">
                                    </div>
                                </div>
                                <div class="advanced-search-item-content">
                                    <div *ngFor="let item of unwanteds; let i = index" (click)="selectUnwantedItem(item)" [ngStyle]="{'background-color': item.isChecked ? '#FE0045':'#FDEDF1'}">
                                        <p *ngIf="item.isDefaultItem" [ngStyle]="{'color': item.isChecked ? '#FFFFFF':'#616571'}">{{getAdvancedSearchItemsItemNameForTranslate('Item-3', i) | translate}}</p>
                                        <p *ngIf="!item.isDefaultItem" [ngStyle]="{'color': item.isChecked ? '#FFFFFF':'#616571'}">{{item.name}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="advanced-search-item">
                                <div class="advanced-search-item-top">
                                    <p class="title">{{'Home.Navbar.AdvancedSearch.Items.Item-4.Name' | translate}}</p>
                                    <div class="add-another-item">
                                        <div (click)="addAnotherHealthConditionIsShowing = true" *ngIf="!addAnotherHealthConditionIsShowing">
                                            <span>{{'Global.Add' | translate}}</span>
                                            <img src="../../../assets/icons/plus-pink-icon.svg" draggable="false" alt="plus-icon">
                                        </div>
                                        <div (click)="addAnotherHealthConditionIsShowing = false" *ngIf="addAnotherHealthConditionIsShowing">
                                            <span>{{'Global.Cancel' | translate}}</span>
                                            <img src="../../../assets/icons/close-circle-pink-icon.svg" draggable="false" alt="close-icon">
                                        </div>
                                    </div>
                                    <div *ngIf="addAnotherHealthConditionIsShowing" class="add-another-form">
                                        <input [(ngModel)]="newAnotherHealthCondition" type="text" placeholder="{{'Home.Navbar.AdvancedSearch.Items.Item-4.AddHealthSituation' | translate}}" (keyup.enter)="addAnotherHealthCondition()">
                                        <img (click)="addAnotherHealthCondition()" class="enter" src="../../../assets/icons/arrow-right-pink-circle-icon.svg" draggable="false" alt="enter">
                                    </div>
                                </div>
                                <div class="advanced-search-item-content">
                                    <div *ngFor="let item of healthConditions; let i = index" (click)="selectHealthConditionItem(item)" [ngStyle]="{'background-color': item.isChecked ? '#FE0045':'#FDEDF1'}">
                                        <p *ngIf="item.isDefaultItem" [ngStyle]="{'color': item.isChecked ? '#FFFFFF':'#616571'}">{{getAdvancedSearchItemsItemNameForTranslate('Item-4', i) | translate}}</p>
                                        <p *ngIf="!item.isDefaultItem" [ngStyle]="{'color': item.isChecked ? '#FFFFFF':'#616571'}">{{item.name}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="splite"></div>
                        <div class="proccess-buttons">
                            <button (click)="advancedSearchDropDownIsShowing = false" class="btn-cancel">{{'Home.Navbar.Cancel' | translate}}</button>
                            <button (click)="advancedSearchDropDownIsShowing = false" class="btn-complete">{{'Home.Navbar.GetResult' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="mainContent" class="main-content">
        <div class="content">
            <div class="side ingridents">
                <h2 class="title">{{'Home.Ingredients.Title' | translate}}</h2>
                <div class="content">
                    <!-- <div class="search">
                        <input type="text" placeholder="Yemek ara">
                        <img src="../../../assets/icons/search-gray-icon.svg" draggable="false" alt="search-icon">
                    </div> -->
                    <div class="items"> 
                        <div *ngFor="let ingrident of ingredients; let i = index" class="item">
                            <div class="ingredient-item-top" (click)="ingiridentDropDownToggle(ingrident);">
                                <img class="item-icon" src="../../../assets/icons/{{ingrident.icon}}" draggable="false" alt="tomatoes-icon">
                                <div class="item-info">
                                    <h3 class="ingrident-name">{{getIngridentsItemsNameForTranslate(i) | translate}}</h3>
                                    <p [ngStyle]="{'color': getSelectedIngridentItemsCount(ingrident.items) > 0 ? '#FE0045' : '#616571'}" class="ingrident-count">{{getSelectedIngridentItemsCount(ingrident.items)}}/{{ingrident.items.length}} {{'Home.Ingredients.Ingredient' | translate}}</p>
                                </div>
                                <img class="arrow-icon" [src]="getIngredientMenuItemDropDownIcon(ingrident.dropDownIsShowing)" draggable="false" alt="arrow-icon">
                            </div>
                            <div *ngIf="ingrident.dropDownIsShowing"> <!-- [@dropDownExpandAnimation] -->
                                <div class="ingrident-items-top">
                                    <div class="item-search-and-add-new-item">
                                        <div>
                                            <input *ngIf="!ingrident.addNewItemIsActive" [(ngModel)]="ingrident.searchInput" type="text" placeholder="{{'Home.Ingredients.Search' | translate}}">
                                        </div>
                                        <div class="ingredient-add-new-item-container">
                                            <input *ngIf="ingrident.addNewItemIsActive" [(ngModel)]="ingrident.newItemInput" (keyup.enter)="addNewIngridentItem(ingrident)" type="text" placeholder="{{'Home.Ingredients.AddNewItem' | translate}}">
                                            <img (click)="addNewIngridentItem(ingrident)" class="enter" src="../../../assets/icons/arrow-right-pink-circle-icon.svg" draggable="false" alt="enter">
                                        </div>
                                    </div>
                                    <div class="add-new-item">
                                        <div (click)="setAddNewIngridentItem(ingrident, true)" *ngIf="!ingrident.addNewItemIsActive" class="add">
                                            <span>{{'Home.Ingredients.Add' | translate}}</span>
                                            <img src="../../../assets/icons/plus-pink-icon.svg" draggable="false" alt="plus-icon">
                                        </div>
                                        <div (click)="setAddNewIngridentItem(ingrident, false)" *ngIf="ingrident.addNewItemIsActive" class="cancel">
                                            <span>{{'Home.Ingredients.Cancel' | translate}}</span>
                                            <img src="../../../assets/icons/close-circle-pink-icon.svg" draggable="false" alt="close-icon">
                                        </div>
                                    </div>
                                </div>
                                <div class="ingrident-items hide-scroll">
                                    <div *ngFor="let item of ingrident.items | genericSearch:'name':ingrident.searchInput;" (click)="selectIngridentItem(item)" [ngStyle]="{'background-color': item.isChecked ? '#FE0045':'#F6F6F9'}" class="ingrident-items-item">
                                        <h4 class="ingrident-items-item-name" *ngIf="item.isDefaultItem" [ngStyle]="{'color': item.isChecked ? '#FFFFFF':'#616571'}">{{getIngridentsItemsItemNameForTranslate(i, item.id - 1) | translate}}</h4>
                                        <h4 class="ingrident-items-item-name" *ngIf="!item.isDefaultItem" [ngStyle]="{'color': item.isChecked ? '#FFFFFF':'#616571'}">{{item.name}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="recipeContainer" class="side recipe">
                <h2 *ngIf="currentRecipeTab != RecipeTabsEnum.Init" class="title">{{'Home.Recipe.Title' | translate}}</h2>
                <div class="content">
                    <!-- Initialize Content (Start) -->
                    <div *ngIf="currentRecipeTab == RecipeTabsEnum.Init" class="init">
                        <p class="init-text">{{'Home.Recipe.Init.LetsStartAddingMaterial' | translate}}</p>
                        <div class="guide-container">
                            <img class="vertical-dotted-icon" src="../../../assets/icons/guide-dotted-line-vertical-gray-icon.svg" draggable="false" alt="dotted-line-icon">
                            <div class="step step-one">
                                <div class="step-number">
                                    <p>1</p>
                                </div>
                                <div class="dotted-line">
                                    <img src="../../../assets/icons/guide-dotted-line-horizontal-gray-icon.svg" draggable="false" alt="dotted-line-icon">
                                </div>
                                <div class="step-icon">
                                    <img src="../../../assets/icons/guide-step-one-icon.svg" draggable="false" alt="guide-step-one-icon">
                                </div>
                                <div class="step-description">
                                    <p>{{'Home.Recipe.Init.StepOne' | translate}}</p>
                                </div>
                            </div>
                            
                            <div class="step step-two">
                                <div class="step-number">
                                    <p>2</p>
                                </div>
                                <div class="dotted-line">
                                    <img src="../../../assets/icons/guide-dotted-line-horizontal-gray-icon.svg" draggable="false" alt="dotted-line-icon">
                                </div>
                                <div class="step-icon">
                                    <img src="../../../assets/icons/guide-step-two-icon.svg" draggable="false" alt="guide-step-two-icon">
                                </div>
                                <div class="step-description">
                                    <p>{{'Home.Recipe.Init.StepTwo' | translate}}</p>
                                </div>
                            </div>

                            <div class="step step-theree">
                                <div class="step-number">
                                    <p>3</p>
                                </div>
                                <div class="dotted-line">
                                    <img src="../../../assets/icons/guide-dotted-line-horizontal-gray-icon.svg" draggable="false" alt="dotted-line-icon">
                                </div>
                                <div class="step-icon">
                                    <img src="../../../assets/icons/guide-step-theree-icon.svg" draggable="false" alt="guide-step-theree-icon">
                                </div>
                                <div class="step-description">
                                    <p>{{'Home.Recipe.Init.StepTheree' | translate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Initialize Content (End) -->

                    <!-- Added Ingridents Content (Start) -->
                    <div *ngIf="currentRecipeTab == RecipeTabsEnum.AddedIngridents" class="added-ingridents-content">
                        <!-- Meat Type Container (Start) -->
                        <div class="meat-type">
                            <p class="title">{{'Home.Recipe.AddedIngredients.FoodTypes.Title' | translate}}</p>
                            <div *ngFor="let meatType of meatTypes; let i = index" class="form-check">
                                <input [(ngModel)]="currentMeatType" class="form-check-input" type="radio" name="meatTypeRadio" value="{{meatType}}" id="meatTypeRadio{{meatType}}">
                                <label for="meatTypeRadio{{meatType}}">{{'Home.Recipe.AddedIngredients.FoodTypes.Items.Item-' + i.toString() | translate}}</label>
                            </div>
                        </div>
                        <!-- Meat Type Container (End) -->

                        <!-- Added Ingridents (Start) -->
                        <div class="hide-scroll added-ingridents">
                            <p class="title">{{'Home.Recipe.AddedIngredients.AddedIngredientsTitle' | translate}}</p>
                            <div *ngFor="let ingredient of ingredients">
                                <div *ngIf="getSelectedIngridentItemsCount(ingredient.items) > 0" class="added-ingridents-items">
                                    <p class="ingrident-name" >{{'Home.Ingredients.Items.Item-' + (ingredient.id - 1).toString() + '.Name'  | translate}}</p>
                                    <div *ngFor="let item of ingredient.items">
                                        <div *ngIf="item.isChecked" class="added-ingridents-item">
                                            <span *ngIf="item.isDefaultItem">{{getIngridentsItemsItemNameForTranslate(ingredient.id - 1, item.id - 1) | translate}}</span>
                                            <span *ngIf="!item.isDefaultItem">{{item.name}}</span>
                                            <img (click)="selectIngridentItem(item)" src="../../../assets/icons/close-square-gray-icon.svg" draggable="false" alt="close-icon">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Added Ingridents (End) -->
                        <button (click)="sendRecipeRequest();" class="pb-btn-default w-100 mt-3">{{'Home.Recipe.SeeRecipe' | translate}}</button>
                    </div>
                    <!-- Added Ingridents Content (End) -->

                    <!-- Recipe Loading Container (Start) -->
                    <div *ngIf="currentRecipeTab == RecipeTabsEnum.RecipeLoading" class="recipe-loading">
                        <p>{{'Global.PleaseWait' | translate}}</p>
                        <img src="../../../assets/gifs/recipe-loading.gif" draggable="false" alt="recipe-loading">
                    </div>
                    <!-- Recipe Loading Container (End) -->

                    <!-- Recipe Container (Start) -->
                    <div *ngIf="currentRecipeTab == RecipeTabsEnum.Recipe" class="recipe-content">
                        <div class="recipe-name">
                            <h2>{{recipeRequest?.eatName}}</h2>
                            <p>{{'Home.Recipe.RecipeResult.YouWillBeTheFirstToTryThisRecipe' | translate}}</p>
                        </div>
                        <div class="action-buttons mt-4">
                            <!-- <button class="pb-btn-default mr-3"><img class="icon-left" src="../../../assets/icons/arrow-right-white-circle-icon.svg" draggable="false" alt="icon"> Tarifi Yap</button> -->
                            <button (click)="addRecipeToBook()" class="pb-btn-outlined"><img class="icon-left" src="../../../assets/icons/document-pink-icon.svg" draggable="false" alt="icon"> Deftere Ekle</button>
                        </div>

                        <div class="recipe-values">
                            <div class="calorie-value">
                                <h3 class="recipe-value-title">{{'RecipesDetail.Recipe.FoodRecipes.CalorieAmount' | translate}}</h3>
                                <div class="recipe-value-content">
                                    <div class="indicator">
                                        <img src="../../../assets/icons/calorie-indicator-{{calorieIndicatorIcon}}.svg" draggable="false" alt="indicator">
                                        <p [class]="calorieIndicatorClass">{{recipeRequest?.calorie}} <span>KCAL</span></p>
                                    </div>
                                    <p class="info-text">*{{'RecipesDetail.Recipe.FoodRecipes.ApproximateValuesPerServing' | translate}}</p>
                                </div>
                            </div>
                            <div class="nutritive-value">
                                <h3 class="recipe-value-title">{{'RecipesDetail.Recipe.FoodRecipes.NutritiveValue' | translate}}</h3>
                                <div class="recipe-value-content">
                                    <p class="property">{{'RecipesDetail.Recipe.FoodRecipes.Protein' | translate}}: <span>{{recipeRequest?.protein || '--'}} Gram</span></p>
                                    <p class="property">{{'RecipesDetail.Recipe.FoodRecipes.Oil' | translate}}: <span>{{recipeRequest?.oil || '--'}} Gram</span></p>
                                    <p class="property">{{'RecipesDetail.Recipe.FoodRecipes.Carbohydrate' | translate}}: <span>{{recipeRequest?.carbohydrate || '--'}} Gram</span></p>
                                    <p class="info-text">*{{'RecipesDetail.Recipe.FoodRecipes.ApproximateValuesPerServing' | translate}}</p>
                                </div>
                            </div>
                        </div>
                        

                        <div class="mt-4 recipe-ingredients">
                            <span style="white-space: pre-line">
                                {{recipeRequest?.content}}
                            </span>
                        </div>

                    </div>
                    <!-- Recipe Container (End) -->
                </div>
            </div>
        </div>

        <div class="what-would-you-like-container">
            <h2 class="title">{{'Home.WhatEatToday.Title' | translate}}</h2>
            <div class="items">
                <div (click)="simpleRecipeRequest('Kahvaltı')" class="item">
                    <img src="../../../assets/icons/breakfast-icon.png" draggable="false" alt="breakfast-icon">
                    <h3 class="item-name">{{'Home.WhatEatToday.Breakfast' | translate}}</h3>
                </div>
                <div (click)="simpleRecipeRequest('Çorba')" class="item">
                    <img src="../../../assets/icons/soup-icon.png" draggable="false" alt="soup-icon">
                    <h3 class="item-name">{{'Home.WhatEatToday.Soup' | translate}}</h3>
                </div>
                <div (click)="simpleRecipeRequest('Ana yemek')" class="item">
                    <img src="../../../assets/icons/main-eat-icon.png" draggable="false" alt="main-eat-icon">
                    <h3 class="item-name">{{'Home.WhatEatToday.MainMeat' | translate}}</h3>
                </div>
                <div (click)="simpleRecipeRequest('Tatlı')" class="item">
                    <img src="../../../assets/icons/sweet-icon.png" draggable="false" alt="sweet-icon">
                    <h3 class="item-name">{{'Home.WhatEatToday.Dessert' | translate}}</h3>
                </div>
            </div>
        </div>

        <!-- Frequently Asked Questions Component (Start) -->
        <app-frequently-asked-questions class="app-frequently-asked-questions"></app-frequently-asked-questions>
        <!-- Frequently Asked Questions Component (End) -->

        <!-- Contact Us Component (Start) -->
        <app-contact-us class="app-contact-us"></app-contact-us>
        <!-- Contact Us Component (End) -->

    </div>
</div>