import { HelpersFactoryService } from './../../services/helpers-factory.service';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Messages } from 'src/app/constants/messages';
import { DomainServer } from 'src/app/constants/serverConstant';
import { BlogComment } from 'src/app/models/blogComment';
import { BlogDetailDto } from 'src/app/models/blogDetailDto';
import { SocialLink } from 'src/app/models/socialLink';
import { BlogCommentService } from 'src/app/services/blog-comment.service';
import { BlogLikeService } from 'src/app/services/blog-like.service';
import { BlogReadingTimeService } from 'src/app/services/blog-reading-time.service';
import { BlogService } from 'src/app/services/blog.service';
import { SubscriberService } from 'src/app/services/subscriber.service';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BlogDetailComponent implements OnInit, OnDestroy, AfterViewInit {

  blogLinkBase: string = `${DomainServer.domain}blogs/detail/`

  blog: BlogDetailDto;
  blogComments: BlogComment[] = [];
  blogExplanation: string;

  subscriberForm: FormGroup;
  newBlogCommentForm: FormGroup;
  blogLikeForm: FormGroup;

  socialLinks: SocialLink[] = [];

  clientEnterPageTime: any = "";
  clientLeavePageTime: any = ""; 
  
  startYourRecipeModalIsShowing: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private subscriberService: SubscriberService,
    private helpersFactoryService: HelpersFactoryService,
    private blogService: BlogService,
    private blogCommentService: BlogCommentService,
    private activatedRoute: ActivatedRoute,
    private blogReadingTimeService: BlogReadingTimeService,
    private blogLikeService: BlogLikeService) { }
    
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.startYourRecipeModalIsShowingOutput(true);
    }, 15000)
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe((params) => {
      if (params['urlName']){
        this.getBlog(params["urlName"]);
      }
    });

    this.createSubscriberForm();
    this.getSocialLinks();

    this.clientEnterPageTime = new Date().getTime(); 
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.clientLeavePageTime = new Date().getTime();
    let second = this.userTimeSpendIntoPage(this.clientEnterPageTime, this.clientLeavePageTime);
    this.blogReadingTimeService.increaseBlogDurationOfReads(this.blog.id, second).subscribe({})
  }

  getBlog(urlName: string){
    this.helpersFactoryService.spinnerService.show();
    this.blogService.getByUrlName(urlName).subscribe({
      next: (response => {
        this.getBlogComments(response.data.id);
        this.helpersFactoryService.pageTitleService.setTitleWithCanonical(response.data.title);
        this.blog = response.data;
        this.blogExplanation = response.data.explanation;
        this.crateNewBlogCommentForm();
        this.createBlogLikeForm();
        this.blogReadingTimeService.increaseBlogNumberOfReads(response.data.id).subscribe({});
        this.helpersFactoryService.spinnerService.hide();
      }),
      error: (error => {
        this.helpersFactoryService.spinnerService.hide();
        this.helpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
      })
    });
  }

  getBlogComments(blogId: number){
    this.blogCommentService.getByBlogId(blogId).subscribe({
      next: (response => {
        this.blogComments = response.data;
      })
    });
  }

  getSocialLinks(){
    let socailLinkNames: string[] = ["Facebook", "Twitter", "Pinterest", "Whatsapp"]
    let socailLinkIcons: string[] = ["facebook", "twitter", "pinterest", "whatsapp"]
    for(let i = 0; i < socailLinkNames.length; i++){
      let socialLink = new SocialLink();
      socialLink.name = socailLinkNames[i];
      socialLink.icon = `../../../assets/icons/${socailLinkIcons[i]}-active-circle-icon.svg`;
      this.socialLinks.push(socialLink);
    }
  }

  addSubscriber(){
    if(this.subscriberForm.valid){
      this.helpersFactoryService.spinnerService.show();
      let model = Object.assign({}, this.subscriberForm.value);
      this.subscriberService.add(model).subscribe({
        next: (response => {
          this.helpersFactoryService.spinnerService.hide();
          this.helpersFactoryService.toastService.success(response.message);
          this.subscriberForm.reset();
        }),
        error: (error => {
          this.helpersFactoryService.spinnerService.hide();
          this.helpersFactoryService.toastService.error(error.error.message);
        })
      });
    }
  }

  blogLike(isLiked: boolean){
    if(this.blogLikeForm.valid){
      this.helpersFactoryService.spinnerService.show();
      let model = Object.assign({}, this.blogLikeForm.value);
      model['isLiked'] = isLiked;
      model['ipAddress'] = this.helpersFactoryService.localStorageService.getClientIp();
      this.blogLikeService.add(model).subscribe({
        next: (response => {
          this.helpersFactoryService.toastService.success(response.message);
          this.helpersFactoryService.spinnerService.hide();
          window.location.reload();
        }),
        error: (error => {
          this.helpersFactoryService.spinnerService.hide();
          this.helpersFactoryService.toastService.error(error.error.message);
        })
      });
    }
  }

  sendNewComment(){
    if(this.newBlogCommentForm.valid){
      this.helpersFactoryService.spinnerService.show();
      let model = Object.assign({}, this.newBlogCommentForm.value);
      this.blogCommentService.add(model).subscribe({
        next: (response => {
          this.helpersFactoryService.spinnerService.hide();
          this.helpersFactoryService.toastService.success(response.message);
          this.newBlogCommentForm.reset();
        }),
        error: (error => {
          this.helpersFactoryService.spinnerService.hide();
          this.helpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
        })
      });
    }
  }

  createSubscriberForm(){
    this.subscriberForm = this.formBuilder.group({
      id: [0, Validators.required],
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]]
    });
  }

  crateNewBlogCommentForm(){
    this.newBlogCommentForm = this.formBuilder.group({
      id: [0, Validators.required],
      blogId: [this.blog.id],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      mail: ["", [Validators.required, Validators.email]],
      phoneNumber: ["", Validators.required],
      comment: ["", Validators.required],
      date: [new Date()],
      state: [false],
    });
  }

  createBlogLikeForm(){
    this.blogLikeForm = this.formBuilder.group({
      id: [0, Validators.required],
      blogId: [this.blog.id, Validators.required],
      isLiked: [false, Validators.required],
      ipAddress: [""],
      date: [new Date()],
    });
  }


  shareBlogToSocial(socialLink: SocialLink){
    let recipeLink = `${this.blogLinkBase}${this.blog.urlName}`
    console.log(recipeLink);
    let link = null;
    if(socialLink.name == "Facebook"){
      link = `https://www.facebook.com/sharer.php?u=${recipeLink}`
    }
    else if(socialLink.name == "Twitter"){
      link = `https://twitter.com/share?url=${recipeLink}%0A%0A&text=${this.blog.title}%0A%0A&hashtags=Nepisir`
    }
    else if(socialLink.name == "Pinterest"){
      let imageUrl = "https://i.pinimg.com/564x/9f/44/44/9f4444a1069c5f38537bfea5c4407295.jpg";
      link = `https://pinterest.com/pin/create/bookmarklet/?media=${imageUrl}&url=${recipeLink}&description=${this.blog.title}%0A%0ANepisir`
    }
    else if(socialLink.name == "Whatsapp"){
      link = `https://api.whatsapp.com/send?text=${this.blog.title}%0A${recipeLink}`
    }
    this.openLink(link);
  }

  openLink(url: string){
    window.open(url, "_blank");
  }

  convertBlogUrlFormat(title: string): string{
    let url = "";
    let splitedTitle = title.split("-");
    splitedTitle.forEach((element, index) => {
      if(index !== splitedTitle.length - 1){
        url += `${element} `;
      }
      else{
        url += element;
      }
    });
    return url;
  }

  userTimeSpendIntoPage(start: any, end: any): any{
    const seconds = Math.round(Math.abs((start - end) / 1000)); 
    return seconds;
  }

  startYourRecipeModalIsShowingOutput(state: boolean){
    this.startYourRecipeModalIsShowing = state;
  }
}
