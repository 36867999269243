import { Injectable } from '@angular/core';
import { ChartOptions } from '../models/apexChart/chartOptions';
import { ChartColors } from '../constants/chartColors';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  public areaBasicChartOption: Partial<ChartOptions>;

  constructor() {}

  getAreaBasicChartOption(dataSeries: any[], dateSeries: any[]) {
    this.areaBasicChartOption = {
      series: [
        {
          name: 'Count',
          data: dataSeries,
        },
      ],
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      labels: dateSeries,
      xaxis: { type: 'datetime' },
      yaxis: { opposite: true },
      legend: { horizontalAlign: 'left' },
      fill: { type: 'gradient' },
      colors: ['#FFAEAE'],
    };
    return this.areaBasicChartOption;
  }
}
