import { PointSystem } from '../../constants/pointSystem';
import { RecipeCommentService } from 'src/app/services/recipe-comment.service';
import { RecipeCommentDetailForProfile } from './../../models/dtos/recipeComment/recipeCommentDetailForProfile';
import { RecipeBookService } from 'src/app/services/recipe-book.service';
import { MediaServerUrl } from './../../constants/serverConstant';
import { UserRecipeService } from 'src/app/services/user-recipe.service';
import { UserBasicInformation } from 'src/app/models/user/userBasicInformation';
import { UserFollowerService } from './../../services/user-follower.service';
import { HelpersFactoryService } from './../../services/helpers-factory.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Messages } from 'src/app/constants/messages';
import { UserRecipeStatisticDto } from 'src/app/models/dtos/userStatistic/userRecipeStatisticDto';
import { UserService } from 'src/app/services/user.service';
import { UserFollowerStatisticDto } from 'src/app/models/dtos/userFollower/userFollowerStatisticDto';
import { RecipeBasicDto } from 'src/app/models/recipeBasicDto';
import { RecipeBookBasicDto } from 'src/app/models/dtos/recipeBook/recipeBookBasicDto';
import { RecipeService } from 'src/app/services/recipe.service';
import { PageTitle } from 'src/app/constants/pageTitle';

class ProfileMenuItem{
  constructor(name?: string, iconName?: string, enumItem?: ProfileMenuItemEnums, languageName?: string) {
    this.name = name;
    this.iconName = iconName;
    this.enumItem = enumItem;
    this.languageName = languageName;
  }

  name: string;
  iconName: string;
  enumItem: ProfileMenuItemEnums;
  languageName: string;
}

enum ProfileMenuItemEnums{
  Profile = "profile",
  MakedRecipes = "maked-recipes",
  SentRecipes = "sent-recipes",
  RecipeBook = "recipe-book",
  Comments = "comments",
  Drafts = "drafts",
  PointSystem = "point-system",
  AccountSettings = "account-settings"
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  MediaServerUrl = MediaServerUrl;
  PointSystem = PointSystem;

  ProfileMenuItemEnums = ProfileMenuItemEnums;

  currentProfileMenuItem: ProfileMenuItemEnums = ProfileMenuItemEnums.AccountSettings;

  profileMenuItems: ProfileMenuItem[] = [];

  userBasicInformation: UserBasicInformation;

  userInformationForm: FormGroup;

  userRecipeStatistics: UserRecipeStatisticDto;
  userFollowersStatistic: UserFollowerStatisticDto;

  userSentRecipes: RecipeBasicDto[] = [];
  userRecipeBooks: RecipeBookBasicDto[] = [];
  userRecipeComments: RecipeCommentDetailForProfile[] = [];

  updateBasiInformationButtonAction: boolean;

  constructor(private userService: UserService,
    private userFollowerService: UserFollowerService,
    private userRecipeService: UserRecipeService,
    private recipeBookService: RecipeBookService,
    private recipeCommentService: RecipeCommentService,
    private recipeService: RecipeService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    public HelpersFactoryService: HelpersFactoryService) { }

  ngOnInit(): void {
    this.HelpersFactoryService.pageTitleService.setTitleWithCanonical(PageTitle.layouts.mainLayout.profile);
    this.userBasicInformation = this.HelpersFactoryService.localStorageService.getAuthenticatedUserInformations().userBasicInformation;
    this.getProfileMenuItems();
    this.getUserRecipeStatisticsByUserId(this.userBasicInformation.id);
    this.getUserFollowersByUserId(this.userBasicInformation.id);
    this.getUserSentRecipes(this.userBasicInformation.id);
    this.getRecipeBooksBasicByUserId(this.userBasicInformation.id);
    this.getRecipeCommentsDetailByUserId(this.userBasicInformation.id);
    this.createUserInformationForm();
    this.activatedRoute.params.subscribe((params) => {
      if (params['tab']) {
        this.currentProfileMenuItem = params['tab'] as ProfileMenuItemEnums;
      }
    });
  }

  getUserRecipeStatisticsByUserId(userId: any){
    this.recipeService.getUserRecipeStatisticsByUserId(userId).subscribe({
      next: (response => {
        this.userRecipeStatistics = response.data;
      })
    })
  }

  getUserFollowersByUserId(userId: any){
    this.userFollowerService.getUserFollowerStatisticByUserId(userId).subscribe({
      next: (response => {
        this.userFollowersStatistic = response.data;
      })
    })
  }

  getUserSentRecipes(userId: number){
    this.userRecipeService.getUserRecipesBasicDataByUserId(userId).subscribe({
      next: (response => {
        this.userSentRecipes = response.data;
      })
    })
  }

  getRecipeBooksBasicByUserId(userId: number){
    this.recipeBookService.getRecipeBooksBasicByUserId(userId).subscribe({
      next: (response => {
        this.userRecipeBooks = response.data;
      })
    })
  }

  getRecipeCommentsDetailByUserId(userId: number){
    this.recipeCommentService.getRecipeCommentsDetailByUserId(userId).subscribe({
      next: (response => {
        this.userRecipeComments = response.data;
      })
    })
  }

  getProfileMenuItems(){
    let iconNames = ["profile", "paper-upload", "document", "chat", "star"];
    let names = ["Profilim", "Gönderdiğim Tarifler", "Tarif Defterim", "Yorumlarım", "Puan Sistemi"];
    let enumItems = [ProfileMenuItemEnums.Profile, ProfileMenuItemEnums.SentRecipes, ProfileMenuItemEnums.RecipeBook, ProfileMenuItemEnums.Comments, ProfileMenuItemEnums.PointSystem];
    let languageNames = ["Profile", "RecipesISent", "RecipeBook", "MyComments", "PointSystem"];
    for(let i = 0; i < names.length; i++){
      let item = new ProfileMenuItem(names[i], iconNames[i], enumItems[i], languageNames[i]);
      this.profileMenuItems.push(item);
    }
  }

  getProfileMenuItemIcon(iconName: string, menuItemEnum: ProfileMenuItemEnums){
    return `${iconName}-${this.currentProfileMenuItem == menuItemEnum ? 'pink': 'gray'}-icon.svg`;
  }

  // getProfileMenuItems(){
  //   let iconPaths = ["profile-gray-icon.svg", "paper-plus-gray-icon.svg", "paper-upload-gray-icon.svg", "document-gray-icon.svg", "chat-gray-icon.svg", "edit-gray-icon.svg", "star-gray-icon.svg"];
  //   let names = ["Profilim", "Yaptığım Tarifler", "Gönderdiğim Tarifler", "Tarif Defterim", "Yorumlarım", "Taslaklarım", "Puan Sistemi"];
  //   let enumItems = [ProfileMenuItemEnums.Profile, ProfileMenuItemEnums.MakedRecipes, ProfileMenuItemEnums.SentRecipes, ProfileMenuItemEnums.RecipeBook, ProfileMenuItemEnums.Comments, ProfileMenuItemEnums.Drafts, ProfileMenuItemEnums.PointSystem];
  //   let languageNames = ["Profile", "RecipesIMade", "RecipesISent", "RecipeBook", "MyComments", "Drafts", "PointSystem", "AccountSettings", "Exit"];
  //   for(let i = 0; i < names.length; i++){
  //     let item = new ProfileMenuItem(names[i], iconPaths[i], enumItems[i], languageNames[i]);
  //     this.profileMenuItems.push(item);
  //   }
  // }

  updateBasicInformation(){
    if(this.userInformationForm.valid){
      this.updateBasiInformationButtonAction = true;
      let model = Object.assign({}, this.userInformationForm.value);
      let user = this.userBasicInformation;
      user.firstName =  model["firstName"];
      user.lastName = model["lastName"];
      user.fullName = `${model["firstName"]} ${model["lastName"]}`
      user.email = model["email"];
      this.userService.updateBasicInformation(user).subscribe({
        next: (response => {
          this.updateBasiInformationButtonAction = false;
          this.HelpersFactoryService.localStorageService.updateAuthenticatedUserInformations(user);
          this.HelpersFactoryService.toastService.success(response.message)
          this.HelpersFactoryService.refreshPage();
        }),
        error: (error => {
          this.updateBasiInformationButtonAction = false;
          this.HelpersFactoryService.toastService.error(Messages.unexpectedErrorOccurred);
        })
      });
    }
  }

  setCurrentProfileMenuItem(item: ProfileMenuItemEnums){
    this.currentProfileMenuItem = item;
    this.router.navigate([`/profile/${item}`]);
  }

  generateArrayForMoqData(count: number): number[]{
    let array = [];
    for(let i = 1; i <= count; i++){
      array.push(i);
    }
    return array;
  }

  createUserInformationForm(){
    this.userInformationForm = this.formBuilder.group({
      firstName: [this.userBasicInformation?.firstName, Validators.required],
      lastName: [this.userBasicInformation?.lastName, Validators.required],
      email: [this.userBasicInformation?.email, [Validators.email, Validators.required]],
    });
  }

  redirectRecipeDetail(recipe: RecipeBasicDto){
    this.HelpersFactoryService.routerNavigate(this.getRedirectRecipeUrl(recipe.recipeUrl, recipe.recipeId, recipe.recipeType.id));
  }

  getRedirectRecipeUrl(recipeUrl: string, recipeId: number, recipeTypeId: number): string{
    return `/recipes/detail/${recipeUrl}-${recipeId}-${recipeTypeId}`;
  }
  

}
