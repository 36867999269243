export class PageTitle{
    static layouts = {
        mainLayout: {
            home: "Yapay Zeka Destekli Lezzet Dünyası: Sağlıklı Tarifler, Diyetler ve İlham Verici Bloglar - Nepişir",
            recipeEngine: "Nepişir Tarif Motoru",
            cookiesPolicy: "Çerez Politikası ve Çerezlere İlişkin Aydınlatma Metni",
            blogs: "Bloglar",
            recipes: "Nepişir Yemek Tarifleri",
            sendRecipe: "Yemek Tarifi Gönder",
            howToUse: "Nepişir Nasıl Çalışıyor",
            profile: "Profilim"
        },
        dashboardLoginLayout: {
            adminLoginPanel: "Admin Giriş Paneli",
            passwordReset: "Parola Sıfırlama",
            newPassword: "Yeni Parola"
        },
        dashboardAdminLayout: {
            dashboard: "Dashboard",
        },
        emptyLayout: {
            blogTextEditor: "Blog Editörü",
            login: "Üye Girişi"
        },
        globalLayout: {
            pageNotFound: "Sayfa Bulunamadı"
        }
    }
}