export class RouterName{
    static dashboard = {
        login: {
            adminLogin: "dashboard/login/admin-login"
        },
        passwordReset:{
            passwordResetRequest: "dashboard/password-reset/request",
            newPassword: "dashboard/password-reset/new-password/:linkId"
        },
        home: "dashboard/home"
    }

    static layout = {
        mainLayout: {
            home: "home",
            recipeEngine: "recipe-engine",
            cookiesPolicy: "cookies-policy",
            blogs: "blogs",
            blogsWithPage: "blogs/:page",
            blogDetail: "blogs/detail/:urlName",
            recipes: "recipes",
            recipeDetail: "recipes/detail/:recipe",
            sendRecipe: "send-recipe",
            howToUse: "how-to-use",
            profile: "profile/:tab"
        },
        emptyLayout: {
            blogTextEditorUpdate: "blog-text-editor/:blogId",
            blogTextEditorNew: "blog-text-editor/add-new-blog/789act08-a25e-88en-a05b-8587ne150014",
            login: "login"
        },
    }
}