import { Component, OnInit, ViewChild } from '@angular/core';
import { DirectionEnum } from 'src/app/enums/global/directionEnum';
import { Feedback } from 'src/app/models/entities/feedback';
import { PageRequest } from 'src/app/models/paginates/pageRequest';
import { Paginate } from 'src/app/models/paginates/paginate';
import { FeedbackService } from 'src/app/services/feedback.service';
import { HelpersFactoryService } from 'src/app/services/helpers-factory.service';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { UserDashboarStatisticDto } from 'src/app/models/dtos/userStatistic/userDashboarStatisticDto';
import { UserService } from 'src/app/services/user.service';
import { UserLoginLogDailyLoginCountDto } from 'src/app/models/dtos/userLoginLog/userLoginLogDailyLoginCountDto';
import { UserLoginLogService } from 'src/app/services/user-login-log.service';
import { ChartOptions } from 'src/app/models/apexChart/chartOptions';
import { ChartService } from 'src/app/services/chart.service';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.css'],
})
export class DashboardHomeComponent implements OnInit{

  
  userStatistics: Paginate<UserDashboarStatisticDto>;
  feedbacks: Paginate<Feedback>;
  userLoginLogDailyLoginCounts: UserLoginLogDailyLoginCountDto[] = [];

  userStatisticsDataIsLoaded: Promise<boolean>;

  DirectionEnum = DirectionEnum;

  testDonutChartOption: Partial<ChartOptions>;

  constructor(private helpersFactoryService: HelpersFactoryService,
    private feedbackService: FeedbackService, 
    private userService: UserService, 
    private userLoginLogService: UserLoginLogService,
    private chartService: ChartService){
      this.feedbacks = new Paginate<Feedback>(0, 3);
      this.userStatistics = new Paginate<UserDashboarStatisticDto>();
  }

  displayedColumns: string[] = ['userBasicInformation-fullName', 'lastLoginDate', 'totalLoginCount', 'totalGeneratedRecipeCount', 'totalMakedRecipeCount'];
  dataSource: any = null;;

  @ViewChild(MatSort) sort: MatSort;


  ngOnInit(): void {
    this.getUserStatistics();
    this.getFeedbackList();
    this.getUserLoginLogDailyLoginCountsDto();
  }

  getUserStatistics(){
    this.helpersFactoryService.spinnerService.show();
    let pageRequest = new PageRequest(
      this.userStatistics.index,
      this.userStatistics.size
    );
    this.userService.getUserStatisticsForDashboard(pageRequest).subscribe({
      next: (response => {
        this.userStatistics = response.data;
        this.helpersFactoryService.spinnerService.hide();
        this.dataSource = new MatTableDataSource(this.userStatistics.items);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item: any, property: any) => {
          switch (property) {
             case 'userBasicInformation-fullName': return  item.userBasicInformation.fullName;
             default: return item[property];
          }
        };
        this.userStatisticsDataIsLoaded = Promise.resolve(true);
      }),
      error: (response => {
        this.helpersFactoryService.toastService.error(response.error.error.message);
        this.helpersFactoryService.spinnerService.hide();
      })
    })
  }

  getFeedbackList(){
    this.helpersFactoryService.spinnerService.show();
    let pageRequest = new PageRequest(
      this.feedbacks.index,
      this.feedbacks.size
    );
    this.feedbackService.getList(pageRequest).subscribe({
      next: (response => {
        this.feedbacks = response.data;
        this.helpersFactoryService.spinnerService.hide();
      }),
      error: (response => {
        this.helpersFactoryService.toastService.error(response.error.error.message);
        this.helpersFactoryService.spinnerService.hide();
      })
    })
  }

  getUserLoginLogDailyLoginCountsDto(){
    this.helpersFactoryService.spinnerService.show();
    this.userLoginLogService.getUserLoginLogDailyLoginCountsDto().subscribe({
      next: (response => {
        this.userLoginLogDailyLoginCounts = response.data;
        this.helpersFactoryService.spinnerService.hide();
        let dataSeries: any = []; let dateSeries: any = [];
        this.userLoginLogDailyLoginCounts.forEach(item => {
          dataSeries.push(item.loginCount)
          dateSeries.push(item.date);
        })
        this.testDonutChartOption = this.chartService.getAreaBasicChartOption(dataSeries, dateSeries);
      }),
      error: (error => {
        this.helpersFactoryService.spinnerService.hide();
        this.helpersFactoryService.toastService.error(error.error.message);
      })
    })
  }

  feedbacksListPageEvent(direction: DirectionEnum){
    if(direction == DirectionEnum.Up && this.feedbacks.hasNext){
      this.feedbacks.index += 1;
    }
    else if(direction == DirectionEnum.Down && this.feedbacks.hasPrevious){
      this.feedbacks.index -= 1;
    }
    this.getFeedbackList();
  }
}
