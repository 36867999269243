import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Visitor } from '../models/visitor';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/responseModel';
import { WebAPIConnectionUrl } from '../constants/serverConstant';

@Injectable({
  providedIn: 'root'
})
export class VisitorService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  addAsync(visitor: Visitor): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}Visitors/AddAsync`;
    return this.httpClient.post<ResponseModel>(newPath, visitor);
  }

}
