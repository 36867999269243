import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SingleResponseModel } from '../models/singleResponseModel';
import { UserFollowerStatisticDto } from '../models/dtos/userFollower/userFollowerStatisticDto';
import { UserFollower } from '../models/userFollower';
import { ResponseModel } from '../models/responseModel';

@Injectable({
  providedIn: 'root'
})
export class UserFollowerService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  getUserFollowerStatisticByUserId(userId: any): Observable<SingleResponseModel<UserFollowerStatisticDto>>{
    let newPath = `${this.apiUrl}UserFollowers/GetUserFollowerStatisticByUserId?userId=${userId}`;
    return this.httpClient.get<SingleResponseModel<UserFollowerStatisticDto>>(newPath);
  }

  checkIfUserIsFollowing(userId: any, followingUserId: any): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}UserFollowers/CheckIfUserIsFollowing?userId=${userId}&followingUserId=${followingUserId}`;
    return this.httpClient.get<ResponseModel>(newPath);
  }

  follow(userFollower: UserFollower): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}UserFollowers/Follow`;
    return this.httpClient.post<ResponseModel>(newPath, userFollower);
  }

  unFollow(userFollower: UserFollower): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}UserFollowers/UnFollow`;
    return this.httpClient.post<ResponseModel>(newPath, userFollower);
  }
}
