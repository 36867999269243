export class UserLoginLog {

    constructor(id?: any, userId?: any, geoLocationId?: number, date?: any){
        this.id = id;
        this.userId = userId;
        this.geoLocationId = geoLocationId;
        this.date = date;
    }

    id: number;
    userId: number;
    geoLocationId: number;
    date: string;
}