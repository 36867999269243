import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/responseModel';
import { SingleResponseModel } from '../models/singleResponseModel';
import { BlogImage } from '../models/blogImage';

@Injectable({
  providedIn: 'root'
})
export class BlogImageService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  add(blogImage: FormData): Observable<SingleResponseModel<BlogImage>>{
    let newPath = `${this.apiUrl}BlogImages/Add`;
    return this.httpClient.post<SingleResponseModel<BlogImage>>(newPath, blogImage);
  }

  delete(blogImage: BlogImage): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}BlogImages/delete`;
    return this.httpClient.post<ResponseModel>(newPath, blogImage);
  }
  
}
