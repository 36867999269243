import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  constructor(
    private titleService: Title,
    @Inject(DOCUMENT) private document: any
  ) {}

  changeTitle(text: string){
    this.titleService.setTitle(`${text}`);
  }

  getPageTitle() {
    return this.titleService.getTitle();
  }

  createLinkForCanonicalURL() {
    const head = this.document.getElementsByTagName('head')[0];
    const currentUrl = window.location.href;
    let canonicalLink: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`);

    if (!canonicalLink) {
        canonicalLink = this.document.createElement('link');
        head.appendChild(canonicalLink);
    }

    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', currentUrl);
  }

  setTitleWithCanonical(pageTitle: string){
    this.changeTitle(pageTitle);
    this.createLinkForCanonicalURL();
  }
}
