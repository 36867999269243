import { Component, OnInit } from '@angular/core';
import { PageTitle } from 'src/app/constants/pageTitle';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.css']
})
export class CookiesPolicyComponent implements OnInit {

  constructor(private pageTitleService: PageTitleService) { }

  ngOnInit(): void {
    this.pageTitleService.setTitleWithCanonical(PageTitle.layouts.mainLayout.cookiesPolicy);
  }
}
