import { HelpersFactoryService } from 'src/app/services/helpers-factory.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlogBasicDataDto } from 'src/app/models/blogBasicDataDto';
import { BlogCategory } from 'src/app/models/blogCategory';
import { Language } from 'src/app/models/language';
import { BlogCategoryService } from 'src/app/services/blog-category.service';
import { BlogService } from 'src/app/services/blog.service';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { TranslateModuleService } from 'src/app/services/translate-module.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  subscriberForm: FormGroup

  blogCategories: BlogCategory[] = [];
  blogsBasicData: BlogBasicDataDto[] = [];

  tourVideoIsShowing: boolean = false;

  language: Language;

  constructor(private formBuilder: FormBuilder,
    private subscriberService: SubscriberService,
    private blogService: BlogService,
    private blogCategoryService: BlogCategoryService,
    private helpersFactoryService: HelpersFactoryService) { }

  ngOnInit(): void {
    this.getBrowserLanuage();
    this.getTopFiveBlogsBasicData();
    this.getBlogCategories();
    this.createSubscriberForm();
  }

  getTopFiveBlogsBasicData(){
    this.blogService.getTopFiveBlogsBasicData(this.language).subscribe({
      next: (response => {
        this.blogsBasicData = response.data;
      })
    });
  }

  getBlogCategories(){
    this.blogCategoryService.getAll().subscribe({
      next: (response => {
        this.blogCategories = response.data;
      })
    });
  }

  addSubscriber(){
    if(this.subscriberForm.valid){
      this.helpersFactoryService.spinnerService.show();
      let model = Object.assign({}, this.subscriberForm.value);
      this.subscriberService.add(model).subscribe({
        next: (response => {
          this.helpersFactoryService.spinnerService.hide();
          this.helpersFactoryService.toastService.success(response.message);
          this.subscriberForm.reset();
        }),
        error: (error => {
          this.helpersFactoryService.spinnerService.hide();
          this.helpersFactoryService.toastService.error(error.error.message);
        })
      });
    }
  }

  createSubscriberForm(){
    this.subscriberForm = this.formBuilder.group({
      id: [0, Validators.required],
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      date: [new Date()]
    });
  }

  tourVideoIsShowingOutput(state: boolean){
    this.tourVideoIsShowing = state;
  }

  getBrowserLanuage(){
    this.language = TranslateModuleService.currentLanguage;
  }

}
