import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Language } from 'src/app/models/language';
import { TranslateModuleService } from 'src/app/services/translate-module.service';

class Question{

  constructor(title: string, answer: string, answerIsShowing: boolean = false){
    this.title = title;
    this.answer = answer;
    this.answerIsShowing = answerIsShowing;
  }

  title: string;
  answer: string;
  answerIsShowing: boolean;
}

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.css'],
  animations: [
    trigger("dropDownExpandAnimation", [
      transition(":enter", [
        style({ height: "0", overflow: "hidden" }),
        animate(200, style({ height: "*" }))
      ]),
      transition(":leave", [
        animate(200, style({ height: 0, overflow: "hidden" }))
      ])
    ])
  ]
})
export class FrequentlyAskedQuestionsComponent implements OnInit {

  questions: Question[] = [];
  currentQuestion: Question;
  currentLanguage: Language;

  constructor() { }

  ngOnInit(): void {
    this.getCurrentLanguage();
    this.getQuestions();
  }

  getQuestions(){
    let questionsTr = [
      ["Nepişir’i kullanmak ücretsiz mi?", 
      "Nepişir’de ne tür yemek tarifleri bulabilirim?",
      "Nepişir’i nasıl kullanabilirim?",
      "Hangi tür malzemeler seçebilirim?",
      "Tariflerde hangi bilgiler yer alır?",
      "Tariflerde besin değerleri hakkında bilgi veriyor musunuz?",
      "Tarifleri kaydedebilir miyim?",
      "Tarifleri paylaşabilir miyim?",
      "Platformunuzda reklamlar var mı?",
      "Platformunuzda hangi dillerde yemek tarifleri üretilebilir?",
      "Platformunuzda vegan yemek tarifleri bulabilir miyim?",
      "Tariflerde kaç kişilik porsiyonlar için öneriler veriyorsunuz?",
      "Tariflerde hangi tür yemek pişirme teknikleri yer alır?",
      "Tariflerde kullanılan malzemeleri değiştirebilir miyim?",
      "Tariflerde hangi tür beslenme tercihlerine uygun seçenekler yer alır?",
      "Site içerisinde mevcut olmayan malzeme varsa ne yapmalıyım?",
      "Hastalık tipine göre yemek tarifi alabilir miyim?",
      "Yemek tarifi gösterilme esnasında neden beklemeliyim?"],
      [
        "Evet, platformumuzu kullanmak tamamen ücretsizdir.",
        "Platformumuzda, yemek tariflerinde bir sınır olmamakla beraber dünya mutfaklarından birçok farklı yemek tarifi bulabilirsiniz. Ayrıca, malzemelerinizi seçerek ve tercihlerinizi belirterek kişiselleştirilmiş tarifler de alabilirsiniz.",
        "Platformumuzu kullanmak oldukça kolaydır. Ana sayfada malzemelerinizi seçin, tercihlerinizi belirtin ve 'tarif ara' butonuna tıklayın. Ardından, size en uygun yemek tariflerini sunacağız",
        "Platformumuzda birçok farklı malzeme seçeneği mevcuttur, malzemeler sınırsız kombinasyonlarla değiştirilebilir.",
        "Tariflerimizde, gereken malzemelerin listesi, hazırlama süresi, pişirme süresi, adım adım talimatlar ve toplam kalori değeri yer alır.",
        "Şu anda platformumuzda tariflerde besin değerleri hakkında bilgi vermiyoruz. Ancak, gelecekte bu özelliği de eklemeyi planlıyoruz.",
        "Evet, istediğiniz tarifi kaydedebilir ve daha sonra tekrar görüntüleyebilirsiniz.",
        "Evet, tarifleri paylaşabilir ve sosyal medya hesaplarınızda arkadaşlarınızla paylaşabilirsiniz.",
        "Şu anda platformumuzda reklam yer almamaktadır. Ancak, gelecekte bu durum değişebilir.",
        "Şu anda platformumuzda yalnızca Türkçe ve İngilizce yemek tarifleri üretilmektedir. Ancak, gelecekte başka dillerde de geliştirmeleri planlıyoruz.",
        "Evet, platformumuzda vegan yemek tarifleri de bulabilirsiniz. Ayrıca, et, süt ürünleri gibi belirli malzemeleri de filtreleyerek, kişiselleştirilmiş vegan tarifler alabilirsiniz.",
        "Tariflerimiz genellikle 4-6 kişilik porsiyonlar için öneriler içermektedir. Ancak, kullanıcının tercihlerine göre bir limit bulunmamaktadır",
        "Tariflerimizde farklı pişirme teknikleri kullanılmaktadır. Örneğin, kızartma, fırınlama, haşlama, buğulama gibi fakat herhangi bir sınırlama bulunmamaktadır.",
        "Evet, tariflerimizde kullanılan malzemeleri değiştirebilirsiniz. Ancak, değiştirdiğiniz malzemeler yemeğin sonucunu etkileyebilir.",
        "Mevcut tüm beslenme çeşitlerine göre tarif sonucu alabilir veya seçeneklerde bulunmayan yeni beslenme tercihleri ekleyerek sonucu iyileştirebilirsiniz.",
        "Eğer eklemek istediğiniz malzemeyi bulamadıysanız ‘Malzeme ara / Mazeleme ekle’ bölümünden istediğiniz malzemeyi ekleyebilirsiniz",
        "Tabi ki evet! Nepişir, bütün sağlık durumlarına göre tarif üretebilir. Sadece ‘Gelişmiş Arama’ kısmından ‘Sağlık Durumu’ seçeneğine hastalık durumunuzu eklemeniz yeterlidir, fakat herhangi bir sağlık risk durumunu kabul etmemektedir. Oluşturduğunuz tarifi sağlığınız açısından güvenilir sağlık kuruluşları veya hekiminiz onayı ile denemelisiniz. ",
        "Nepişir, tamamen özel, kişileştirilmiş ve özgün yemek tarifi ürettiğinden dolayı tarif üretilme esnasında bir dakikadan az bekleme süresi mevcuttur."]
    ];

    let questionsEn = [
      ["Is Nepişir free to use?", 
      "What kind of recipes can I find on Nepişir?",
      "How can I use Nepişir?",
      "What kind of ingredients can I choose?",
      "What kind of information is included in the recipes?",
      "Do you provide information about nutritional values in the recipes?",
      "Can I save the recipes?",
      "Can I share the recipes?",
      "Are there adverts on your platform?",
      "Which languages can recipes be produced on your platform?",
      "Can I find vegan recipes on your platform?",
      "How many servings do you suggest for the recipes?",
      "What kind of cooking techniques are included in the recipes?",
      "Can I change the ingredients used in the recipes?",
      "What types of dietary options are included in the recipes?",
      "What should I do if there is ingredient that is not available on the site?",
      "Can I get a recipe according to the type of disease?",
      "Why should I wait while a recipe is shown? "],
      ["Yes, the platform is completely free to use.", 
        "On our platform, you can find many different recipes from world cuisines, although there is no limit to recipes. You can also get personalised recipes by selecting the ingredients and specifying your preferences.",
        "On the homepage, select your ingredients, indicate their preferences and click on the search recipe button. Then we will offer you the most suitable recipe.",
        "Many different material options are available on our platform, data is processed completely according to your preferences. Do not forget to prepare your ingredients while waiting for your recipe.",
        "Our recipes include a list of ingredients, preparation time, cooking time, step-by-step instructions and total calorie value.",
        "Currently, we cannot provide information about nutritional values on our platform, but we plan to add this feature in the future.",
        "Yes, you can save the recipes you want and view them again later.",
        "You can share the recipes with your friends on your social media.",
        "We are currently unable to place adverts on our platform, but this may change over time.",
        "Currently, only English and Turkish recipes are produced on our platform. However, we plan to develop in other languages.",
        "Yes, you can also find vegan recipes on our platform. You can also get personalised vegan recipes by filtering specific ingredients such as meat, dairy products.",
        "Our recipes usually contain recommendations for portions for 4-6 people. However, there is no limit according to the user's preferences.",
        "Different cooking methods are used in our recipes. For example, there are no restrictions such as frying, baking, boiling, steaming.",
        "Yes, you can change your ingredients. But, the ingredients you change can affect the outcome of the meal.",
        "You can get results according to all available nutrition types, or you can improve the result by adding new nutrition types that are not available in the options.",
        "If you cannot find the material you are looking for, you can add the material you want from the Search material / Add material section.",
        "Of course, yes! Nepişir can produce recipes regarding to all health conditions. You just need to add your disease status to the 'Health Status' option in the 'Advanced Search' section, however we are not in charge for any inconvinient results. You should try the recipe you have created with the approval of trusted health institutions or your physician for your health.",
        "Because Nepişir produces completely custom, personalized, and unique recipes, there is less than a minute of waiting time."]
      ];

    
    let questions;
      
    if(this.currentLanguage.code == 'tr'){
      questions = questionsTr;
    }
    else{
      questions = questionsEn;
    }

    for(let i = 0; i < questions[0].length; i++){
      let question = new Question(questions[0][i], questions[1][i]);
      this.questions.push(question);
    }
  }

  questionVisibleHandle(question: Question){
    if(this.currentQuestion != question && this.currentQuestion != null){
      this.currentQuestion.answerIsShowing = false;
      question.answerIsShowing = true;
    }
    else{
      if(question.answerIsShowing){
        question.answerIsShowing = false;
      }
      else{
        question.answerIsShowing = true;
      }
    }
    this.currentQuestion = question;
  }


  toggleArrowIcon(state: boolean): string{
    if(state){
      return "../../../assets/icons/arrow-up-pink-icon.svg";
    }
    return "../../../assets/icons/arrow-down-pink-icon.svg";
  }

  getCurrentLanguage(){
    this.currentLanguage = TranslateModuleService.currentLanguage;
  }

}
