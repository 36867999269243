import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-admin-layout',
  templateUrl: './dashboard-admin-layout.component.html',
  styleUrls: ['./dashboard-admin-layout.component.css']
})
export class DashboardAdminLayoutComponent {

}
