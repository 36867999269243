import { HelpersFactoryService } from 'src/app/services/helpers-factory.service';
import { Component, OnInit } from '@angular/core';
import { Language } from 'src/app/models/language';
import { TranslateModuleService } from 'src/app/services/translate-module.service';
import { PageTitle } from 'src/app/constants/pageTitle';

class AssistansPromitionCardItem{
  constructor(icon: string, title: string, subTitle: string, text: string) {
    this.icon = icon;
    this.title = title;
    this.subTitle = subTitle;
    this.text = text;
  }

  icon: string;
  title: string;
  subTitle: string;
  text: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  tourVideoIsShowing: boolean = false;

  assistanPromotionCardItems: AssistansPromitionCardItem[] = [];

  currentLanguage: Language;

  constructor(private helpersFactoryService: HelpersFactoryService) {}

  ngOnInit(): void {
    this.helpersFactoryService.pageTitleService.setTitleWithCanonical(PageTitle.layouts.mainLayout.home);
    this.getBrowserLanuage();
    this.getAssistantCardItems();
  }

  getAssistantCardItems(){
    let icons = ["free-ticket-pink-icon.svg" ,"infinity-pink-cion.svg", "star-shine-pink-icon.svg", "bookmark-star-pink-icon.svg"]
    let titles = ["ÜCRETSİZ", "SINIRSIZ", "ÖZGÜN", "KİŞİSELLEŞTİRİLMİŞ"];
    let subTitles = ["Lezzetli Yemek Tarifleri", "Enfes Yemek Tarifleri", "Nefis Yemek Tarifleri", "Pratik Yemek Tarifleri"];
    let texts = [
      "Yemek yapmak için mükemmel tarifleri ÜCRETSİZ olarak bulun.",
      "Tarifi beğenmediniz mi? HEMEN yenisini isteyin.",
      "Deneysel EŞSİZ yemek tariflerini deneyin.",
      "Kişiselleştirilmiş tercihleriniz doğrultusunda sadece size ÖZEL yemek tarifleri alın."
    ]

    for(let i = 0; i < titles.length; i++){
      let item = new AssistansPromitionCardItem(icons[i], titles[i], subTitles[i], texts[i]);
      this.assistanPromotionCardItems.push(item);
    }
  }

  tourVideoIsShowingOutput(state: boolean){
    this.tourVideoIsShowing = state;
  }

  getSectionImage(section: string){
    return `../../../assets/homeSectionImages/home-section-${section}-icon-${this.currentLanguage.code}.png`
  }
  
  getBrowserLanuage(){
    this.currentLanguage = TranslateModuleService.currentLanguage;
  }
}