import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Paginate } from 'src/app/models/paginates/paginate';
import { ScrollPosition } from 'src/app/utilities/scrollPosition';

@Component({
  selector: 'app-pagination-partial',
  templateUrl: './pagination-partial.component.html',
  styleUrls: ['./pagination-partial.component.css']
})
export class PaginationPartialComponent {

  
  @Input() data: Paginate<any>;
  @Output() paginationOnChange = new EventEmitter<Paginate<any>>();

  numberOfDataPerPage: number = 0;
  currentPage: number = 0;
  numOfPages: number = 0;
  pageItem: { start: number; end: number };
  numOfButtons: any[] = [];
  arrOfCurrButtons: any[] = [];

  numberOfDataPerPageItems: number[] = [10, 25, 50, 100];
  numberOfDataPerPageItemsDropDownState: boolean = false;

  ngOnInit() {
    this.numberOfDataPerPage = this.data.size;
    this.currentPage = this.data.index + 1;
    this.numOfPages = this.data.pages;

    this.pageItem = {
      start: 0,
      end: this.numberOfDataPerPage,
    };
    this.calculatePageButtons();
  }

  calculatePageButtons() {
    this.numOfButtons = [];

    for (let i = 1; i <= this.numOfPages; i++) {
      this.numOfButtons.push(i);
    }

    const dotsInitial = '...';
    const dotsLeft = '... ';
    const dotsRight = ' ...';

    if (this.numOfButtons.length < 6) {
      this.arrOfCurrButtons = this.numOfButtons;
    } 
    else if (this.currentPage >= 1 && this.currentPage <= 3) {
      this.arrOfCurrButtons = [ 1, 2, 3, 4, dotsInitial, this.numOfButtons.length];
    } 
    else if (this.currentPage === 4) {
      const sliced = this.numOfButtons.slice(0, 5);
      this.arrOfCurrButtons = [...sliced, dotsInitial, this.numOfButtons.length];
    } 
    else if (this.currentPage > 4 && this.currentPage < this.numOfButtons.length - 2) {
      const sliced1 = this.numOfButtons.slice(this.currentPage - 2, this.currentPage);
      const sliced2 = this.numOfButtons.slice(this.currentPage, this.currentPage + 1);
      this.arrOfCurrButtons = [1, dotsLeft, ...sliced1, ...sliced2, dotsRight, this.numOfButtons.length];
    } 
    else if (this.currentPage > this.numOfButtons.length - 3) {
      const sliced = this.numOfButtons.slice(this.numOfButtons.length - 4);
      this.arrOfCurrButtons = [1, dotsLeft, ...sliced];
    }
    else if (this.currentPage.toString() === dotsInitial) {
      this.setCurrentPage(this.arrOfCurrButtons[this.arrOfCurrButtons.length - 3] + 1);
    } 
    else if (this.currentPage.toString() === dotsRight) {
      this.setCurrentPage(this.arrOfCurrButtons[3] + 2);
    } 
    else if (this.currentPage.toString() === dotsLeft) {
      this.setCurrentPage(this.arrOfCurrButtons[3] - 2);
    }

    const value = this.currentPage * this.numberOfDataPerPage;
    this.onPageChangeEvent(value - this.numberOfDataPerPage, value);
  }

  prevPageClick() {
    if (this.currentPage === 1) {
      this.setCurrentPage(this.currentPage);
    } else {
      this.setCurrentPage(this.currentPage - 1);
    }
  }

  nextPageClick() {
    if (this.currentPage === this.numOfButtons.length) {
      this.setCurrentPage(this.currentPage);
    } else {
      this.setCurrentPage(this.currentPage + 1);
    }
  }

  onPageChangeEvent(start: number, end: number) {
    this.pageItem = {
      start: start,
      end: end,
    };
  }

  onPerPostChangeEvent(event: any) {
    this.numberOfDataPerPage = event.target.value;
    this.currentPage = 1;
    this.calculatePageButtons();
  }

  setCurrentPage(page: number) {
    if(this.currentPage != page){
      this.currentPage = page;
      this.calculatePageButtons();
      this.data.index = this.currentPage - 1;
      this.paginationOnChanged();
      ScrollPosition.top();
    }
  }

  setNumberOfDataPerPageItem(item: number){
    this.numberOfDataPerPage = item;
    this.data.size = item;
    this.data.index = 0;
    this.currentPage = this.data.index + 1;
    this.numberOfDataPerPageItemsDropDownState = false;
    this.paginationOnChanged();
  }

  paginationOnChanged(){
    this.paginationOnChange.emit(this.data);
  }
}
