import { LocalStorageService } from './local-storage.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../models/language';

@Injectable({
  providedIn: 'root'
})
export class TranslateModuleService {

  static currentLanguage: Language;
  static languages: Language[] = [{id: 1, name: "Türkçe", code: "tr"}, {id: 2, name: "English", code: "en"}]

  constructor(public translate: TranslateService, private localStorageService: LocalStorageService) {    
    translate.addLangs(["en", "tr"]); 
  }

  getBrowserLanguage(){
    let browserLanguage = this.localStorageService.getObjectData("language");
    if(browserLanguage != null){
      TranslateModuleService.currentLanguage = browserLanguage as Language;
      this.translate.setDefaultLang(TranslateModuleService.currentLanguage.code);
    }
    else{
      this.setBrowserLanuage();
    }
  }

  setBrowserLanuage(){
    let language = navigator.language.substring(0, 2);
    let languageData;
    if(language == "tr"){
      this.translate.setDefaultLang("tr");
      languageData = TranslateModuleService.languages[0];
    }
    else{
      this.translate.setDefaultLang("en");
      languageData = TranslateModuleService.languages[1];
    }
    this.localStorageService.setObjectData("language", languageData);
    TranslateModuleService.currentLanguage = languageData;
  }

  changeLanguage(language: Language){
    if(TranslateModuleService.currentLanguage.code != language.code){
      this.switchLang(language.code);
      TranslateModuleService.currentLanguage = language;
      this.localStorageService.setObjectData("language", language)
      window.location.reload();
    }
  }

  switchLang(language: string){
    this.translate.use(language);
  }
}
