import { RecipeCommentDetailForProfile } from './../models/dtos/recipeComment/recipeCommentDetailForProfile';
import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { RecipeComment } from '../models/recipeComment';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/responseModel';
import { ListResponseModel } from '../models/listResponseModel';

@Injectable({
  providedIn: 'root'
})
export class RecipeCommentService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  add(recipeComment: RecipeComment): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}RecipeComments/Add`;
    return this.httpClient.post<ResponseModel>(newPath, recipeComment);
  }
  
  getRecipeCommentsDetailByUserId(userId: any): Observable<ListResponseModel<RecipeCommentDetailForProfile>>{
    let newPath = `${this.apiUrl}RecipeComments/GetRecipeCommentsDetailByUserId?userId=${userId}`;
    return this.httpClient.get<ListResponseModel<RecipeCommentDetailForProfile>>(newPath);
  }
}
