<div class="title m-t-70">
    <p class="title-text">NEPİŞİR DASHBOARD’A GİRİŞ YAP</p>
</div>
<div class="dashboard-login-container m-t-50">
    <form [formGroup]='loginForm' *ngIf="loginForm">
        <div class="login-form w-100">
            <div class="form-group">
                <div class="pb-input-box">
                    <input type="email" id="email" autocomplete="off" formControlName="email" required="required" [class.input-error]="loginForm.get('email').invalid && loginForm.get('email').touched">
                    <span>E-posta adresiniz</span>
                </div>
                <div class="form-group-validation-container" *ngIf="loginForm.get('email')?.invalid && loginForm?.get('email')?.errors && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched)">
                    <small class="text-danger" *ngIf="loginForm.get('email')?.hasError('required')">
                        * Lütfen bu alanı doldurun
                    </small>
                    <small class="text-danger" *ngIf="loginForm.get('email')?.hasError('email') && loginForm.get('email').touched">
                        * Lütfen geçerli bir email adresi girin
                    </small>
                </div>
            </div>
            <div class="form-group m-t-30">
                <div class="pb-input-box">
                    <input type="password" id="password" formControlName="password" required="required" [class.input-error]="loginForm.get('password').invalid && loginForm.get('password').touched">
                    <span>Parola</span>
                </div>
                <div class="form-group-validation-container" *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.errors && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
                    <small class="text-danger" *ngIf="loginForm.get('password')?.hasError('required')">
                        * Lütfen bu alanı doldurun
                    </small>
                </div>
            </div>
            <button (click)="login()" class="pb-btn-default w-100 m-t-45">Giriş Yap</button>
        </div>
    </form>
    <p routerLink="/{{RouterName.dashboard.passwordReset.passwordResetRequest}}" class="forgot-password-text">ŞİFRENİZİ Mİ UNUTTUNUZ?</p>
</div>