import { Injectable } from '@angular/core';
import { WebAPIConnectionUrl } from '../constants/serverConstant';
import { HttpClient } from '@angular/common/http';
import { RecipeCommentLike } from '../models/recipeCommentLike';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/responseModel';

@Injectable({
  providedIn: 'root'
})
export class RecipeCommentLikeService {

  apiUrl = WebAPIConnectionUrl.url;

  constructor(private httpClient: HttpClient) { }

  like(recipeLike: RecipeCommentLike): Observable<ResponseModel>{
    let newPath = `${this.apiUrl}RecipeCommentLikes/Like`;
    return this.httpClient.post<ResponseModel>(newPath, recipeLike);
  }
}
