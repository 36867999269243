import { SendRecipeAddedIngredientDto } from "./sendRecipeAddedIngredientDto";

export class UserRecipeAddDto {
    userId: number;
    recipeTypeId: number;
    recipeName: string;
    ingredients: SendRecipeAddedIngredientDto[];
    steps: string[];
    trickText: string | null;
    categorizationText: string | null;
    averageCookingTime: string;
}